import * as React from 'react';
import { useToaster } from '..';
import { copyToClipboard } from '../../../models/UiUtils';

export const useCopyToClipboard = () => {
	const toaster = useToaster();
	const handleCopy =
		(
			customUrl?: string,
			message?: {
				error?: string;
				success?: string;
			}
		) =>
		(event: React.MouseEvent<HTMLSpanElement>) => {
			event.stopPropagation();
			const { hash, origin, pathname } = location;
			const url = customUrl || `${origin + pathname}${hash}`;
			if (copyToClipboard(url)) {
				toaster.push({
					message: message?.success || `Copied to clipboard`,
					type: 'successMessage',
				});
			} else {
				toaster.push({
					message: message?.error || `Failed to copy to clipboard`,
					type: 'errorMessage',
				});
			}
		};
	return handleCopy;
};
