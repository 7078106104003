import { css } from 'aphrodite';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DisclosureIcon } from '../../../../web/components/svgs/icons/DisclosureIcon';
import { TransparentButton } from '../../../components/TransparentButton';
import { brandPrimaryLight } from '../../../styles/colors';
import { styleSheet } from '../styles';

interface IProps {
	onChange: (dateStringValue: string) => void;
}

let persistedDate: Moment = null;
let persistedOffset = 0;

const onChangeDateFormatter = (value: Moment) => moment(value).format('YYYY-MM-DD');

export const DailySlider: React.FC<IProps> = ({ onChange }) => {
	const [dateConfig, setConfig] = useState({
		date: persistedDate || moment(),
		offset: persistedOffset,
	});

	useEffect(() => {
		persistedDate = null;
		persistedOffset = 0;
	}, []);

	const dateFormat = 'dddd, MMMM Do, YYYY';
	const today = moment();

	const handleDateChange = (offset: number) => () => {
		const date = moment(today).add(offset, 'days');
		const formatted = onChangeDateFormatter(date);

		persistedDate = date;
		persistedOffset = offset;

		setConfig({ ...dateConfig, date, offset });
		onChange(formatted);
	};

	const renderDate = dateConfig.date?.format(dateFormat)?.toString();

	return (
		<div className={css(styleSheet.daySliderWrapper)}>
			<TransparentButton className={css(styleSheet.daySliderButton)} onClick={handleDateChange(dateConfig.offset - 1)}>
				<DisclosureIcon
					className={css(styleSheet.daySliderIcon, styleSheet.daySliderIconPrev)}
					fillColor={brandPrimaryLight}
				/>
			</TransparentButton>

			<div className={css(styleSheet.daySliderDate)}>{renderDate}</div>

			<TransparentButton className={css(styleSheet.daySliderButton)} onClick={handleDateChange(dateConfig.offset + 1)}>
				<DisclosureIcon
					className={css(styleSheet.daySliderIcon, styleSheet.daySliderIconNext)}
					fillColor={brandPrimaryLight}
				/>
			</TransparentButton>
		</div>
	);
};
