import * as Api from '@ViewModels';
import { computed, observable } from 'mobx';
import { ILeadSearchView, IPhoneCallLogView } from './leads/interfaces';

/**
 * The view models in this file are just skins over the interfaces. They are used for various views like the Call Log
 * screen, Follow Ups, etc.
 */

export class RecentCallViewModel extends Api.ViewModel {
	@observable public phoneCallView: IPhoneCallLogView;
	constructor(userSession: Api.UserSessionContext, view: IPhoneCallLogView) {
		super(userSession);
		this.phoneCallView = view;
	}

	@computed
	get phoneCall() {
		return this.phoneCallView.phoneCall;
	}

	@computed
	get company() {
		return this.phoneCallView.company;
	}

	@computed
	public get id() {
		return this.phoneCallView.phoneCall.id;
	}
}

export class FollowUpViewModel extends Api.ViewModel {
	@observable public followUp: ILeadSearchView;
	constructor(userSession: Api.UserSessionContext, view: IPhoneCallLogView) {
		super(userSession);
		this.followUp = view;
	}

	@computed
	get followUpDate() {
		return this.followUp.followUp.followUpDate;
	}

	@computed
	public get id() {
		return this.followUp.company.id;
	}
}

export class FollowUpsViewModel extends Api.ViewModel {
	@observable public followUps: Api.BaseObservablePageCollectionController<ILeadSearchView, FollowUpViewModel>;
	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.followUps = new Api.BaseObservablePageCollectionController<ILeadSearchView, FollowUpViewModel>({
			apiPath: this.composeApiUrl({ urlPath: 'lead/followUps' }),
			client: this.userSession.webServiceHelper,
			httpMethod: 'GET',
			transformer: x => new FollowUpViewModel(this.userSession, x),
		});
	}

	@computed
	get controller() {
		return this.followUps;
	}
}
