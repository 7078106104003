import { css } from 'aphrodite';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ViewportContext } from '../../hooks/useViewport';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

enum MobileHeaderPathnames {
	CallLog = 'call-log',
	Leaderboard = 'leaderboard',
}

export const MobileHeader: React.FC<IProps> = () => {
	const { isBelowSmall } = useContext(ViewportContext);
	const history = useHistory();

	const pathname = history?.location?.pathname;

	const getPageTitle = () => {
		switch (pathname.replace(/\//g, '')) {
			case MobileHeaderPathnames.CallLog: {
				return 'Call Logs';
			}
			case MobileHeaderPathnames.Leaderboard: {
				const search = history?.location?.search;
				if (search.match('demos')) {
					return 'Demos';
				}
				if (search.match('prizes')) {
					return 'Prizes';
				}
				return 'Leaderboard';
			}
			default: {
				return '';
			}
		}
	};

	useEffect(() => {
		if (isBelowSmall && !getPageTitle()) {
			history.push(`/${MobileHeaderPathnames.CallLog}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isBelowSmall]);

	const renderTitle = (
		<div className={css(styleSheet.mobileMenuWrapper)}>
			<span className={css(styleSheet.mobileNavLabel)}>{getPageTitle()}</span>
		</div>
	);

	return renderTitle;
};
