import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { IToasterComponentProps, ToasterViewModelKey } from '../../../models/AppState';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { CustomerImpersonationWarning } from '../../../web/components/CustomerImpersonationWarning';
import { Header } from '../../components/Header';
import { IncomingCalls } from '../../components/IncomingCalls';
import { MemeModal } from '../../components/MemeModal';
import { IMrryMessage, MrryDirection, MrryMessageType, MrryModal } from '../../components/MrryModal';
import { SalesAi } from '../../components/ai/SalesAi';
import { SlotMachineModalProvider } from '../../components/slotMachines/SlotMachineModalProvider';
import { AudioPlayerProvider } from '../../contexts/audioPlayer';
import { useSearch } from '../../contexts/search';
import { useTelephony } from '../../contexts/telephony';
import { BodyContainer } from './components/BodyContainer';
import { styleSheet } from './styles';

export type HeaderSize = 'short' | 'tall';

const MainContainerBase: React.FC<IToasterComponentProps> = ({ children, toaster }) => {
	const location = useLocation();
	const { phoneCalls } = useTelephony();
	const search = useSearch();
	const userSession = useUserSession();

	const [mrryMessage, setMrryMessage] = useState<IMrryMessage>(null);
	const [showIncomingCalls, setShowIncomingCalls] = useState(false);
	const [headerHeight, setHeaderHeight] = useState<HeaderSize>('tall');

	const onGlobalKeydown = (e: KeyboardEvent) => {
		if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
			e.stopPropagation();
			e.preventDefault();
			search.setShowSearch(!search.showSearch);
		}
	};

	useEffect(() => {
		if (toaster.currentToastMessage) {
			const { customContent, message, type } = toaster.currentToastMessage;
			setMrryMessage({
				content: message || customContent,
				type:
					type === 'successMessage'
						? MrryMessageType.Success
						: type === 'errorMessage'
							? MrryMessageType.Error
							: MrryMessageType.Normal,
			});
		} else {
			setMrryMessage(null);
		}
	}, [toaster.currentToastMessage]);

	useEffect(() => {
		window.removeEventListener('keydown', onGlobalKeydown);
		window.addEventListener('keydown', onGlobalKeydown);
		return () => {
			window.removeEventListener('keydown', onGlobalKeydown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search.showSearch]);

	useEffect(() => {
		if (phoneCalls.incomingCalls.length) {
			setShowIncomingCalls(true);
		} else {
			setShowIncomingCalls(false);
			phoneCalls.clearIncomingCalls();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCalls.incomingCalls.length]);

	useEffect(() => {
		setHeaderHeight(
			!!location?.pathname &&
				!location?.pathname.includes('settings') &&
				!location?.pathname.includes('reporting') &&
				!location?.pathname.includes('leaderboard')
				? 'tall'
				: 'short'
		);
	}, [location?.pathname]);

	const onIncomingCallsClose = () => {
		setShowIncomingCalls(false);
		phoneCalls.clearIncomingCalls();
	};

	return (
		<AudioPlayerProvider>
			<SlotMachineModalProvider>
				<div className={css(styleSheet.container)}>
					<Header />
					<BodyContainer headerHeight={headerHeight}>
						<CustomerImpersonationWarning styles={[styleSheet.impersonating]} userSession={userSession} />
						{children}
					</BodyContainer>
					{!!mrryMessage && <MrryModal fromDirection={MrryDirection.FromTop} message={mrryMessage} />}
					{!!showIncomingCalls && (
						<MrryModal
							fromDirection={MrryDirection.FromTop}
							message={{
								content: <IncomingCalls />,
								type: MrryMessageType.Success,
							}}
							onRequestClose={onIncomingCallsClose}
						/>
					)}
					<MemeModal />
					{userSession.account?.features?.aida?.enabled !== true && (
						<div className={css(styleSheet.noCoffee)}>
							This account does not have Coffee enabled. Please double check you are logged into the correct account.
						</div>
					)}

					{userSession.account?.features?.aida?.coachingEnabled ? <SalesAi /> : null}
				</div>
			</SlotMachineModalProvider>
		</AudioPlayerProvider>
	);
};

const MainContainerAsObserver = observer(MainContainerBase);
export const MainContainer = inject(ToasterViewModelKey)(MainContainerAsObserver);
