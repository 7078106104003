import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { useSearch } from '../../contexts/search';
import { useTelephony } from '../../contexts/telephony';
import { useErrorMessages } from '../../hooks';
import { LeadSearchResultsViewModel } from '../../viewModels/leads/leads';
import { PhoneCallViewModel } from '../../viewModels/phonecall';
import { LeadServedSource } from '../../viewModels/queue';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

const IncomingCallsBase: React.FC<IProps> = ({ className = '' }) => {
	const history = useHistory();
	const search = useSearch();
	const userSession = useUserSession();
	const leadSearch = useRef(new LeadSearchResultsViewModel(userSession)).current;
	const { phoneCalls } = useTelephony();
	const errorMessages = useErrorMessages();
	const [noResults, setNoResults] = useState(false);

	const onPhoneNumberClick = (incomingCall: PhoneCallViewModel) => () => {
		leadSearch

			.search(incomingCall.phoneNumber?.standard)
			.then(() => {
				if (leadSearch.leads.length < 1) {
					setNoResults(true);
				} else if (leadSearch.leads.length === 1) {
					history.push({
						pathname: '/queue',

						search: `?from=${LeadServedSource.IncomingCall}&companyId=${leadSearch.leads[0].company.id}`,
					});
				} else {
					search.setSearchQuery(incomingCall.phoneNumber?.standard);
				}
			})
			.catch(err => {
				errorMessages.pushApiError(err);
			});
	};

	const renderIncomingCalls = () => {
		return phoneCalls.incomingCalls.map((incomingCall, i, arr) => {
			return (
				<>
					<div key={incomingCall.id}>
						<div className={css(styleSheet.label)}>Incoming Call From</div>

						<TransparentButton onClick={noResults ? null : onPhoneNumberClick(incomingCall)}>
							<div>{incomingCall.phoneNumber?.standard ?? <LoadingSpinner type='small' />}</div>
							{noResults ? (
								<div className={css(styleSheet.unknownNumber)}>
									This number doesn&apos;t appear to be associated with a lead.
								</div>
							) : (
								<div className={css(styleSheet.whoIsThis)}>Who is this?</div>
							)}
						</TransparentButton>
					</div>
					{i < arr.length - 1 && <div className={css(styleSheet.sep)} />}
				</>
			);
		});
	};

	return <div className={`${css(styleSheet.incomingCallsContainer)} ${className}`}>{renderIncomingCalls()}</div>;
};

export const IncomingCalls = observer(IncomingCallsBase);
