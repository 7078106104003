import { IBaseApiTypeModel, UserSessionContext, ViewModel, asApiError } from '@ViewModels';
import { action, observable } from 'mobx';
import { LightningType } from '../components/svgs/LightningGraphic';
import { IFile, IMeme, IMemeTheme, MemePointsPlacement } from './gamification';

export interface IActivityView extends IBaseApiTypeModel {
	points: number;
	label: string;
	id: string;
	/** Number of memes for this activity */
	memes: number;
}

export class ActivitiesViewModel extends ViewModel {
	@observable public activities: ActivityViewModel[] = [];
	constructor(userSession: UserSessionContext) {
		super(userSession);
	}

	@action
	public load = async () => {
		this.busy = true;
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IActivityView[]>(
			'meme/activities',
			'GET'
		);
		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.activities = result.value.map(x => new ActivityViewModel(this.userSession, x));
		this.busy = false;
		this.loaded = true;
	};

	@action
	public createMeme = async (activityId: string, image: Blob) => {
		const newMeme: IMeme = {
			activities: [activityId],
			id: 'new',
			image: { mimeType: '', url: '' },
			memeType: LightningType.LightningDouble,
			phrase: '',
			theme: {
				backgroundColor: '#0061A6',
				pointsBackgroundColor: '#00AAE8',
				pointsColor: '#FFFFFF',
				pointsPlacement: MemePointsPlacement.TopRight,
				primaryColor: '#00F0C9',
				secondaryColor: '#0081DC',
			},
			value: 0,
		};

		// const placeholder = new File(null, 'placeholder.png');

		const body = new FormData();
		body.append('meme', JSON.stringify(newMeme));
		body.append('files', image);

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IMeme>('meme', 'POST', body);
		if (!result.success) {
			throw asApiError(result);
		}

		this.activities.find(x => x.id === activityId).memeVms.push(new MemeViewModel(this.userSession, result.value));
		this.loaded = true;
	};
}

export class ActivityViewModel extends ViewModel implements IActivityView {
	@observable public points: number;
	@observable public label: string;
	@observable public id: string;
	@observable public memes: number;
	@observable public memeVms: MemeViewModel[] = [];

	constructor(userSession: UserSessionContext, view: IActivityView) {
		super(userSession);
		this.points = view.points;
		this.label = view.label;
		this.id = view.id;
		this.memes = view.memes;
	}

	@action
	public loadMemes = async () => {
		this.busy = true;
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IMeme[]>(
			`meme/byActivity/${this.id}`,
			'GET'
		);
		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.memeVms = result.value.map(x => new MemeViewModel(this.userSession, x));
		this.busy = false;
		this.loaded = true;
	};
}

export class MemeViewModel extends ViewModel implements IMeme {
	@observable public id: string;

	@observable public activities: string[];

	@observable public image: IFile;

	@observable public value: number;

	@observable public phrase: string;

	@observable public memeType: LightningType;

	@observable public theme: IMemeTheme;

	@observable public sound: IFile;

	constructor(userSession: UserSessionContext, meme: IMeme) {
		super(userSession);
		this.set(meme);
	}

	@action
	private set = (meme: IMeme) => {
		this.id = meme.id;
		this.activities = meme.activities;
		this.image = meme.image;

		this.value = meme.value;
		this.phrase = meme.phrase;
		this.memeType = meme.memeType;
		this.theme = meme.theme;

		this.sound = meme.sound;
	};

	@action
	public load = async () => {
		this.busy = true;
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IMeme>(`meme/${this.id}`, 'GET');
		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.set(result.value);

		this.busy = false;
		this.loaded = true;
	};

	@action
	public updateImage = async (file: Blob) => {
		this.busy = true;

		const body = new FormData();
		body.append('image', file);

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IMeme>(
			`meme/${this.id}/image`,
			'PUT',
			body
		);
		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.set(result.value);

		this.busy = false;
		this.loaded = true;
	};

	@action
	public updateSound = async (file: Blob) => {
		this.busy = true;

		const body = new FormData();
		body.append('sound', file);

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IMeme>(
			`meme/${this.id}/sound`,
			'PUT',
			body
		);
		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.set(result.value);

		this.busy = false;
		this.loaded = true;
	};

	@action
	public updatePhrase = async (phrase: string) => {
		this.busy = true;

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IMeme>(
			encodeURI(`meme/${this.id}/phrase?phrase=${phrase}`),
			'PUT'
		);
		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.set(result.value);

		this.busy = false;
		this.loaded = true;
	};

	@action
	public updateActivities = async (activities: string[]) => {
		this.busy = true;

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IMeme>(`meme/${this.id}/phrase`, 'PUT', {
			activities,
		});
		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.set(result.value);

		this.busy = false;
		this.loaded = true;
	};

	@action
	public delete = async () => {
		this.busy = true;

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IMeme>(`meme/${this.id}`, 'DELETE');
		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.busy = false;
		this.loaded = true;
	};
}
