import * as Api from '@ViewModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useErrorMessages } from '../../hooks';
import { ApiClient } from '../../services/api';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';

export const useLeadProperties = <T>() => {
	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<T>();

	const userSession = useUserSession();
	const errorMessages = useErrorMessages();

	const client = new ApiClient(userSession);

	const getConfig = async () => {
		handleWorking();
		try {
			const response = await client.getLeadConfig();
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			errorMessages.pushApiError(error);
		}
	};

	const updateConfig = async (body: T) => {
		handleWorking();
		try {
			const response = await client.updateLeadConfig(body);
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			errorMessages.pushApiError(error);
		}
	};

	return {
		...state,
		empty: !state?.data,
		getConfig,
		reset,
		status: state.status,
		updateConfig,
	};
};
