import * as React from 'react';

export const CombinedProviders = <TProps extends object = any>(providers: React.ComponentType<any>[]) => {
	const Providers = providers.reduce<React.ComponentType<TProps & { children?: React.ReactNode }>>(
		(AccumulatedProvider: React.ComponentType<any>, Current: React.ComponentType<any>) => {
			return !AccumulatedProvider
				? Current
				: ((({ children }) => {
						return (
							<AccumulatedProvider>
								<Current>{children}</Current>
							</AccumulatedProvider>
						);
					}) as React.FC);
		},

		null
	);
	return Providers;
};
