import { css } from 'aphrodite';
import { IEmojiData } from 'emoji-picker-react';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import Waypoint from 'react-waypoint';
import { INote } from '../../../../extViewmodels';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { TextInput } from '../../../../web/components/TextInput';
import { PaperPlaneIcon } from '../../../../web/components/svgs/icons/PaperPlaneIcon';
import { EmojiPickerButton } from '../../../../web/components/texting/EmojiPickerButton';
import { usePhoneCallNotes } from '../../../entities/PhoneCall/usePhoneCallNotes';
import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { TransparentButton } from '../../TransparentButton';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';

interface IProps {
	phoneCallId: string;
}

interface IResponseState {
	isEndOfTheList?: boolean;
	pageToken?: string;
	values: INote[];
}

export const CallDetailsNotes: React.FC<IProps> = ({ phoneCallId }) => {
	const [newComment, setComment] = useState('');

	const [response, setResponse] = useState<IResponseState>({
		isEndOfTheList: false,

		pageToken: null,
		values: [],
	});

	const list = usePhoneCallNotes<INote[]>();

	const update = usePhoneCallNotes();

	const handleCommenting = (event: React.ChangeEvent<HTMLInputElement>) => {
		setComment(event?.target?.value);
	};

	const handleSaveComment = () => {
		const createComment: FormData = new FormData();
		createComment.append(
			'value',
			JSON.stringify({
				content: {
					document: `<lev-content>${newComment}</lev-content>`,
					documentVersion: 1,
				},
				isForCoaching: true,
				isForOutcome: false,
				referencedEntities: {},
			})
		);

		update.create(phoneCallId, createComment).then(({ value }) => {
			const incremented = response.values;
			incremented.push(value);
			setResponse({ ...response, values: incremented });
		});
		setComment('');
	};

	const handleEmoji = (_: React.MouseEvent<Element, MouseEvent>, emojiData: IEmojiData) => {
		setComment(`${newComment.trim()} ${emojiData.emoji}`);
	};

	const handleEnterDetection = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' || event.keyCode === 13) {
			handleSaveComment();
		}
	};

	const handleScrolling = () => {
		if (!response.isEndOfTheList && phoneCallId) {
			list.get(phoneCallId, { pageToken: response.pageToken }).then(({ value }) => {
				const incremented = [...response.values, ...value.values];
				setResponse({
					isEndOfTheList: value.pageToken ? false : true,
					pageToken: value.pageToken || null,
					values: incremented,
				});
			});
		}
	};

	const isLoadingList = list.status === FetchStatus.Working;
	const isLoadingUpdate = update.status === FetchStatus.Working;

	const isEmpty = (list.empty || response?.values?.length === 0) && !isLoadingList;

	return (
		<div className={css(styleSheet.sectionWrapper)}>
			<div className={css(styleSheet.sectionTitle)}>Comments</div>
			<div className={css(styleSheet.commentsContainer)}>
				<div className={css(styleSheet.commentsWrapper)}>
					<ul>
						{response?.values?.map((item, index) => {
							const formattedCreationDate = moment(item?.creationDate).format('MM/DD/YYYY - h:mm A');
							const now = moment();
							const diff = now.diff(moment(item?.creationDate));

							const duration = moment.duration(diff);

							const hours = duration.asHours().toString();
							const minutes = duration.asMinutes().toString();

							const formattedHours = parseInt(hours, 10);
							const formattedMinutes = parseInt(minutes, 10);

							const isLowerThan24Hours = formattedHours <= 24;
							const isLowerThan1Hour = formattedHours < 1;
							const isLowerThan10Minutes = formattedMinutes < 10;

							let date = formattedCreationDate;

							if (isLowerThan24Hours) {
								date = `${formattedHours}h`;
							}

							if (isLowerThan1Hour) {
								date = 'Recently';
							}

							if (isLowerThan10Minutes) {
								date = 'Now';
							}

							return (
								<li key={index}>
									<article className={css(styleSheet.commentItem)}>
										<Group>
											<div className={css(styleSheet.commentItemMedia)}>
												<img src={item.creator?.profilePic} alt={`Picture of user ${item.creator?.firstName}`} />
											</div>
											<div className={css(styleSheet.commentItemContent)}>
												<p className={css(styleSheet.commentItemContentName)}>
													{item.creator?.firstName}&nbsp;
													<span className={css(styleSheet.commentItemTime)}>{date}</span>
												</p>
												<div
													className={css(styleSheet.commentItemContentNote)}
													dangerouslySetInnerHTML={{
														__html: item.content.document,
													}}
												/>
											</div>
										</Group>
									</article>
								</li>
							);
						})}
					</ul>
				</div>
				{isEmpty && <small>No comments yet.</small>}
				{isLoadingList && <LoadingSpinner type='extra-small' />}
				<footer className={css(styleSheet.commentsFooter)}>
					<EmojiPickerButton onEmojiClick={handleEmoji} />
					<TextInput
						className={css(styleSheet.commentsInput)}
						disabled={isLoadingUpdate}
						inputId='comments-input'
						onChange={handleCommenting}
						placeholder='Leave a comment'
						onKeyUp={handleEnterDetection}
						rightAccessory={
							<TransparentButton onClick={handleSaveComment} className={css(styleSheet.commentsInputSendButton)}>
								<PaperPlaneIcon />
							</TransparentButton>
						}
						type='text'
						value={newComment}
					/>
				</footer>
			</div>
			<Waypoint bottomOffset='-200px' onEnter={handleScrolling} />
		</div>
	);
};
