import { useEffect, useState } from 'react';
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	DropResult,
	Droppable,
	DroppableProvided,
	DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { useTimeout } from '../../../hooks/useTimeout';

interface IProps<T> {
	children: (params: {
		draggableProvided: DraggableProvided;
		droppableProvided: DroppableProvided;
		isDragging: boolean;
		isDraggingOver: boolean;
		item: T;
	}) => JSX.Element;
	className?: string;
	getKey?: (item: T) => string;
	items: T[];
	onChange?: (items: T[]) => void;
}

export function DraggableList<T>({ children, className, getKey, items, onChange }: IProps<T>) {
	const [innerItems, setItems] = useState<T[]>(null);

	useEffect(() => {
		setItems(items);
	}, [items]);

	const timeout = useTimeout();

	const reorder = (list: T[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		const reorderedItems = reorder(innerItems, result.source.index, result.destination.index);
		setItems(reorderedItems);
		if (onChange) {
			timeout(() => onChange(reorderedItems), 200);
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId='droppable'>
				{(droppableProvided: DroppableProvided, droppableStateSnapshot: DroppableStateSnapshot) => (
					<div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} className='droppable'>
						{innerItems?.map((item: T, index: number) => {
							const key = getKey?.(item) + index.toString() || index.toString();
							return (
								<Draggable key={key} draggableId={key} index={index}>
									{(draggableProvided: DraggableProvided, draggableStateSnapshot: DraggableStateSnapshot) => {
										const isDragging = draggableStateSnapshot.isDragging;
										const isDraggingOver = droppableStateSnapshot.isDraggingOver;
										const renderChildren = children({
											draggableProvided,
											droppableProvided,
											isDragging,
											isDraggingOver,
											item,
										});
										return (
											<div
												ref={draggableProvided.innerRef}
												{...draggableProvided.draggableProps}
												{...draggableProvided.dragHandleProps}
												className={`draggable ${className}${isDragging ? ' is-dragging' : ''}${
													isDraggingOver ? ' is-dragging-over' : ''
												}`}
											>
												{renderChildren}
											</div>
										);
									}}
								</Draggable>
							);
						})}
						{droppableProvided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}
