import * as Api from '@ViewModels';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useAudioPlayer } from '../../hooks/useSound';
import * as SharedAppState from '../../models/AppState';
import { getSlotMachineName } from '../../models/slotMachines';
import { IWebsocketSubscription } from '../../viewModels/websockets';
import { AchievementToast } from './WsNotificationComponents';
import NotificationSoundFileUrl from './slotMachineAchievementSound.mp3';

export enum WsSubscriptionName {
	Achievements = 'Achievements',
}

export interface IAchievementWsEvent {
	description: string;
	reward: Api.IGameTokenAchievementReward;
}

interface ISlotMachineTokenToastMessage {
	count: number;
	description: string;
	gameId: string;
	name: string;
}

export const useAchievementSubscription = () => {
	const history = useHistory();
	const websockets = SharedAppState.AppState[SharedAppState.WebsocketsViewModelKey];
	const userSession = useUserSession();
	const dismissTimeoutRef = React.useRef<any>(null);
	const sound = useAudioPlayer(NotificationSoundFileUrl);
	sound.setVolume(0.25);

	const resetNotificationState = React.useRef(() => {
		toast.dismiss();
		sound.stop();
	}).current;
	const handleClick = React.useRef(() => {
		resetNotificationState();
		history.push('/slotmachine/prizes');
	}).current;

	const achievementSubscription = React.useRef<IWebsocketSubscription<IAchievementWsEvent>>({
		callback: payload => {
			resetNotificationState();
			const toastMessages: Record<string, ISlotMachineTokenToastMessage> = {};
			payload.forEach(event => {
				const { value } = event || {};

				let message = toastMessages[value.reward?.gameId];
				if (message) {
					// combine
					message.count++;
				} else {
					// create new
					message = {
						count: 1,

						description: value.description,

						gameId: value.reward?.gameId,

						name: getSlotMachineName(value?.reward?.additionalDetails?.slotMachineType)?.replace(
							/([a-z])([A-Z])/g,
							'$1 $2'
						),
					};
					toastMessages[message.gameId] = message;
				}
			});

			Object.values(toastMessages).forEach(message => {
				toast(
					<AchievementToast
						action='Spin'
						description={message.description}
						indicator={message.count}
						name={message.name}
						onClick={handleClick}
					/>
				);
			});

			sound.play();
			dismissTimeoutRef.current = setTimeout(() => {
				dismissTimeoutRef.current = null;
				resetNotificationState();
			}, 6000);
		},
		events: ['UserAchievementEvent'],
		name: WsSubscriptionName.Achievements,
	}).current;

	React.useEffect(() => {
		if (userSession?.account?.features?.aida?.achievementsEnabled) {
			websockets.subscribe(achievementSubscription);
		}
		return () => {
			websockets.unsubscribe(WsSubscriptionName.Achievements);
			if (dismissTimeoutRef.current) {
				clearTimeout(dismissTimeoutRef.current);
				dismissTimeoutRef.current = null;
			}
		};
	}, [achievementSubscription, userSession?.account?.features?.aida?.achievementsEnabled, websockets]);

	return { achievementSubscription, sound } as const;
};
