import { css } from 'aphrodite';
import * as React from 'react';
import { useContext } from 'react';
import { IUser } from '../../../../extViewmodels';
import { Group } from '../../../components/leadView/components/Group';
import { ManagerSelect } from '../../../components/leadView/components/ManagerSelect';
import { RoleSelect } from '../../../components/leadView/components/RoleSelect';
import { TeamSelect } from '../../../components/leadView/components/TeamSelect';
import { UserSelect } from '../../../components/leadView/components/UserSelect';
import { VerticalSelect } from '../../../components/leadView/components/VerticalSelect';
import { ILeaderboardFilter } from '../../../entities/Leaderboard/useLeaderboard';
import { ViewportContext } from '../../../hooks/useViewport';
import { LeaderboardFilterCriteriaProperty } from '../../../viewModels/gamification';
import { styleSheet } from '../styles';

interface ILeaderboardFilterProps {
	filter?: ILeaderboardFilter;
	onChange: (filter: ILeaderboardFilter) => void;
}

export const Filters: React.FC<ILeaderboardFilterProps> = ({ filter, onChange }) => {
	const { isAboveSmall, isBelowSmall } = useContext(ViewportContext);

	function handleChange<T>(key: LeaderboardFilterCriteriaProperty) {
		return (item: T) => {
			onChange({ ...filter, [key]: item });
		};
	}

	const filterItemClassName = [styleSheet.filterWrapperItem, isBelowSmall && styleSheet.filterWrapperItemMobile];

	return (
		<Group vertical={true} hCentered={true} className={css(styleSheet.filterContainer)}>
			<Group
				noWrap={false}
				hCentered={true}
				spacingSize={0}
				className={css(styleSheet.filterWrapper, isBelowSmall && styleSheet.filterWrapperMobile)}
			>
				<Group fullWidth={false} className={css(filterItemClassName)}>
					{isAboveSmall && (
						<UserSelect user={filter?.User} onChange={handleChange<IUser>(LeaderboardFilterCriteriaProperty.User)} />
					)}
					<ManagerSelect onChange={handleChange<IUser>(LeaderboardFilterCriteriaProperty.Manager)} />
					{isAboveSmall && <RoleSelect onChange={handleChange<string>(LeaderboardFilterCriteriaProperty.UserRole)} />}
				</Group>
				<Group fullWidth={false} className={css(filterItemClassName)}>
					{isAboveSmall && <TeamSelect onChange={handleChange<string>(LeaderboardFilterCriteriaProperty.Team)} />}
					{isAboveSmall && (
						<VerticalSelect onChange={handleChange<string>(LeaderboardFilterCriteriaProperty.Vertical)} />
					)}
				</Group>
			</Group>
		</Group>
	);
};
