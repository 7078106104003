import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { PhoneCallDirection } from '../../../extViewmodels';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../web/styles/styles';
import { useQueueLeadNote } from '../../contexts/queueLeadNote';
import { SearchKeyOptions, useSearch } from '../../contexts/search';
import { DialerPhoneCallStatus, useTelephony } from '../../contexts/telephony';
import { useQueue } from '../../hooks/queue';
import { CallingCard } from '../CallingCard';
import { WhoYaGonnaCall } from '../WhoYaGonnaCall';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

export const QueueBase: React.FC<IProps> = ({ className = '' }) => {
	const queue = useQueue();
	const notes = useQueueLeadNote();
	const { phoneCalls, phoneCall, phoneCallStatus } = useTelephony();
	const { setShowSearch, setPayload } = useSearch();
	const [isLeadLocked, lockLead] = useState<boolean>(false);

	const onGlobalKeydown = (e: KeyboardEvent) => {
		if (e.key === '/' && (e.ctrlKey || e.metaKey)) {
			e.stopPropagation();
			e.preventDefault();
			notes.setShowQueueLeadNote(!notes.showQueueLeadNote);
		}
	};

	useEffect(() => {
		window.removeEventListener('keydown', onGlobalKeydown);
		window.addEventListener('keydown', onGlobalKeydown);
		return () => {
			window.removeEventListener('keydown', onGlobalKeydown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notes.showQueueLeadNote]);

	useEffect(() => {
		lockLead(false);

		phoneCalls.clearCurrentCall();
		if (queue.lead?.pendingOutcome?.phoneCall) {
			phoneCalls.setExistingCall(queue.lead.pendingOutcome.phoneCall, queue.companyId);
		}

		if (queue.companyId) {
			queue.loadPhoneCallOutcomes(queue.companyId);
			queue.loadSkipReasons(queue.companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.companyId]);

	useEffect(() => {
		if (phoneCall?.direction === PhoneCallDirection.Outbound) {
			queue.hasCalled = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCall?.id]);

	useEffect(() => {
		if (
			phoneCall?.direction === PhoneCallDirection.Inbound &&
			!phoneCall?.companyId &&
			phoneCallStatus === DialerPhoneCallStatus.Connected
		) {
			console.log('accepted call from unknown person');
			lockLead(true);
			setPayload({ key: SearchKeyOptions.CallFromUnknown });
			setShowSearch(true);
		} else {
			lockLead(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCall?.id, phoneCallStatus]);

	const sunShade = (
		<div className={css(styleSheet.sunShade)}>
			<p>You are in a call with an unrecognized contact/company.</p>
			<p>If you know who you are talking to, you can search for the lead and load it.</p>
		</div>
	);

	const renderLayer1 = () => {
		if (!queue || queue.isBusy) {
			return <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />;
		}

		if (isLeadLocked) {
			// you are in a call with a different company than the one loaded
			return sunShade;
		}

		if (!queue.lead) {
			return <WhoYaGonnaCall />;
		}

		return <CallingCard />;
	};

	return (
		<div className={`${css(styleSheet.queueContainer)} ${className}`}>
			<div className={css(styleSheet.layer, styleSheet.transparentLayer, styleSheet.layer3)} />
			<div className={css(styleSheet.layer, styleSheet.transparentLayer, styleSheet.layer2)} />
			<div className={css(styleSheet.layer, styleSheet.layer1)}>{renderLayer1()}</div>
		</div>
	);
};

export const Queue = observer(QueueBase);
