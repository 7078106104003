import { Checkbox } from '../../../../../web/components/Checkbox';
import { TextInput } from '../../../../../web/components/TextInput';
// import { BarSymbol } from '../../../svgs/slotMachines/BarSymbol';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { SlotMachineSymbolGroup } from '../../../svgs/slotMachines/SlotMachineSymbolGroup';
import { styleSheet } from './styles';

export interface IPrizeConfigurationTableRowProps {
	count: number;
	onPayLineChanged?(payline: Api.ISlotMachinePayLine): Promise<any>;
	payLine: Api.ISlotMachinePayLine;
}

export const PrizeConfigurationTableRow: React.FC<IPrizeConfigurationTableRowProps> = props => {
	const { payLine, onPayLineChanged, count = 3 } = props;
	const [enabled, setEnabled] = React.useState<boolean>(payLine.enabled);

	const prizeTextInputRef = React.useRef<HTMLInputElement>(null);
	const onPrizeTextInputRef = React.useCallback((ref?: HTMLInputElement) => {
		prizeTextInputRef.current = ref;
	}, []);
	const updatePayLine = React.useCallback(
		async (nextPayLine: Api.ISlotMachinePayLine) => {
			if (onPayLineChanged) {
				try {
					await onPayLineChanged?.(nextPayLine);
				} catch {
					// eat this error... handled bu vm
					setEnabled(payLine.enabled);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onPayLineChanged, payLine?.prize, payLine?.enabled]
	);
	const onPrizeTextInputBlur = React.useCallback(
		async (e: React.FocusEvent<HTMLInputElement>) => {
			updatePayLine({
				...payLine,
				prize: e.target.value,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updatePayLine, onPayLineChanged]
	);

	React.useEffect(() => {
		if (prizeTextInputRef.current) {
			prizeTextInputRef.current.value = payLine.prize || '';
		}
	}, [payLine.prize]);

	const toggleEnabled = React.useCallback(() => {
		setEnabled(x => !x);
		updatePayLine({
			...payLine,
			enabled: !enabled,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payLine, enabled]);

	return (
		<div className={css(styleSheet.row)}>
			<SlotMachineSymbolGroup
				styles={[styleSheet.symbolCol, styleSheet.symbols, count === 2 ? styleSheet.symbolCol2Matching : null]}
				symbols={Array.from<Api.SlotMachineSymbol>({ length: count }).fill(payLine.symbol)}
				symbolStyles={[styleSheet.symbol]}
			/>
			<div className={css(styleSheet.prizeCol)}>
				<div className={css(styleSheet.prizeColInputContainer)}>
					<TextInput
						className={css(!enabled ? styleSheet.prizeColInputDisabled : null)}
						disabled={!enabled}
						inputId={`prize-configuration-table-row-input-${payLine.symbol}-${count}`}
						inputRef={onPrizeTextInputRef}
						onBlur={onPrizeTextInputBlur}
						type='text'
					/>
				</div>
				<div className={css(styleSheet.prizeColCheckboxContainer)}>
					<Checkbox
						backgroundFillColor='#fff'
						checked={!enabled}
						checkedBackgroundFillColor='#fff'
						id={`prize-configuration-table-row-checkbox-${payLine.symbol}-${count}`}
						onChange={toggleEnabled}
						type='small'
					>
						<span className={css(styleSheet.prizeColCheckboxText)}>No Prize</span>
					</Checkbox>
				</div>
			</div>
		</div>
	);
};
