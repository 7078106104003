import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useModal } from '../../../../models/hooks/useModal';
import { DefaultDeleteConfirmationOptions } from '../../../../web/components/ConfirmationDialog';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { WarningIcon } from '../../../../web/components/svgs/icons/WarningIcon';
import { BlueConfirmationDialog } from '../../../components/BlueConfirmationDialog';
import { Activity } from '../../../components/gamification/Activity';
import { Meme } from '../../../components/gamification/Meme';
import { CircleAddIcon } from '../../../components/svgs/icons/CircleAddIcon';
import { useResponseHandler } from '../../../hooks/error';
import { ActivitiesViewModel } from '../../../viewModels/memes';
import { BasicPage } from '../../BasicPage';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

export const Gamification: React.FC<IProps> = observer(({ className = '' }) => {
	const userSession = useUserSession();
	const activities = useRef(new ActivitiesViewModel(userSession)).current;

	const [selectedActivityId, setSelectedActivityId] = useState<string>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const [memeToDelete, setMemeToDelete] = useState<string>(null);
	const [activitiesErrorHandler] = useResponseHandler('LoadActivities-Error', 'Could not load activities');
	const [memesResponseHandler] = useResponseHandler('LoadMemes-Error', 'Could not load memes');
	const [memeCreateResponseHandler] = useResponseHandler('CreateMeme-Error', 'Could not create meme');
	const [memeDeleteResponseHandler] = useResponseHandler('DeleteMeme-Error', 'Could not delete meme');

	useEffect(() => {
		activities.load().catch(activitiesErrorHandler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (activities.activities.length && !selectedActivityId) {
			setSelectedActivityId(activities.activities[0]?.id);

			setMemeToDelete(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activities.activities?.length]);

	const onActivityClick = (activityId: string) => () => {
		setSelectedActivityId(activityId);

		setMemeToDelete(null);
	};

	const marginTop = useMemo(() => {
		const idx = activities.activities.findIndex(x => x.id === selectedActivityId);
		const indexSafe = Math.max(idx, 0);
		return indexSafe * 140 + 10;
	}, [selectedActivityId, activities]);

	const selectedActivity = useMemo(
		() => activities.activities.find(x => x.id === selectedActivityId),
		[selectedActivityId, activities]
	);

	useEffect(() => {
		if (selectedActivity) {
			selectedActivity.memeVms = [];
			selectedActivity.loadMemes().catch(memesResponseHandler);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedActivityId]);

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.currentTarget.files[0];
		activities.createMeme(selectedActivityId, file).catch(memeCreateResponseHandler);
	};

	const onUploadClick = () => {
		inputRef.current?.click();
	};
	const deleteMeme = React.useCallback(() => {
		selectedActivity?.memeVms
			.find(x => x.id === memeToDelete)
			.delete()
			.then(() => {
				selectedActivity.memeVms = selectedActivity.memeVms.filter(x => x.id !== memeToDelete);
				selectedActivity.memes -= 1;
			})
			.catch(memeDeleteResponseHandler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memeToDelete]);

	const deleteModal = useModal(
		false,
		() => {
			deleteMeme();
			return () => {
				setMemeToDelete(null);
			};
		},
		[deleteMeme]
	);

	const onDeleteClick = React.useCallback(
		(id: string) => () => {
			deleteModal.setIsOpen(true)();
			setMemeToDelete(id);
		},
		[deleteModal]
	);

	if (activities.isBusy) {
		return (
			<BasicPage className={`${css(styleSheet.gamificationContainer)} ${className}`} title='gamification'>
				<LoadingSpinner type='login' />
			</BasicPage>
		);
	}

	return (
		<BasicPage className={`${css(styleSheet.gamificationContainer)} ${className}`} title='gamification'>
			<div className={css(styleSheet.gamification)}>
				<div>
					{activities.activities.map(x => {
						return (
							<Activity
								key={x.id}
								activity={x}
								onClick={onActivityClick(x.id)}
								selected={x.id === selectedActivityId}
							/>
						);
					})}
				</div>
				<div className={css(styleSheet.sayinsContainer)} style={{ marginTop }}>
					{!!selectedActivity && (
						<div>
							{selectedActivity.isBusy ? (
								<LoadingSpinner type='large' />
							) : (
								<>
									{selectedActivity.memeVms.map(x => {
										return <Meme key={x.id} meme={x} onDeleteClick={onDeleteClick} />;
									})}
									<div className={css(styleSheet.addButton)} onClick={onUploadClick}>
										<CircleAddIcon />
										<span className={css(styleSheet.addText)}>Add a meme</span>
									</div>
								</>
							)}
						</div>
					)}
				</div>
			</div>
			<input
				type='file'
				className={css(styleSheet.fileInput)}
				onChange={onFileChange}
				ref={inputRef}
				accept='image/png, image/jpeg, image/gif, video/mp4'
			/>
			<BlueConfirmationDialog
				icon={<WarningIcon />}
				modalProps={deleteModal}
				options={DefaultDeleteConfirmationOptions}
				title='Are you sure you want to delete this meme?'
			/>
		</BasicPage>
	);
});
