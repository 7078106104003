import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { DeprecatedPopover, PopoverType } from '../../../../web/components/DeprecatedPopover';
import { SuccessCheckIcon } from '../../../../web/components/svgs/icons/SuccessCheckIcon';
import { nameCircles } from '../../../../web/styles/colors';
import { useTwilio } from '../../../components/ai/context';
import { useWebsockets } from '../../../hooks';
import { styleSheet } from './styles';

export const GoalsList = ({
	topicProgressions,
	scorecard,
	complete = false,
}: {
	topicProgressions: Api.IScorecardTopicProgress[];
	scorecard: Api.IScorecard;
	complete?: boolean;
}) => {
	const [hoveredTopics, setHoveredTopics] = React.useState<Api.IScorecardTopicProgress[]>([]);

	const getLabel = (topicProgression: Api.IScorecardTopicProgress) => {
		const { topics } = scorecard.scorecardTemplateVersion;
		const matchingTopic = topics.find(x => x.identifier === topicProgression.topicIdentifier);
		return matchingTopic?.label ?? topicProgression.topicIdentifier;
	};

	const getSummary = (topicProgression: Api.IScorecardTopicProgress) => {
		const { topics } = scorecard.scorecardTemplateVersion;
		const matchingTopic = topics.find(x => x.identifier === topicProgression.topicIdentifier);
		return matchingTopic?.summary ?? '';
	};

	const onHover = (topicProgression: Api.IScorecardTopicProgress) => {
		if (!hoveredTopics.includes(topicProgression)) {
			setHoveredTopics([...hoveredTopics, topicProgression]);
		}
	};

	const onLeave = (topicProgression: Api.IScorecardTopicProgress) => {
		setHoveredTopics(hoveredTopics.filter(x => x !== topicProgression));
	};

	return (
		<>
			{topicProgressions.map(topicProgression => {
				const isHovering = hoveredTopics.includes(topicProgression);
				return (
					<DeprecatedPopover
						key={`topic-progression-${topicProgression.topicIdentifier}`}
						anchor={
							<div
								className={css(styleSheet.progression)}
								key={topicProgression.topicIdentifier}
								onMouseOver={() => onHover(topicProgression)}
								onMouseLeave={() => onLeave(topicProgression)}
							>
								<SuccessCheckIcon fillColor={complete ? null : nameCircles} ringColor={complete ? null : '#F9F9F9'} />
								<span className={isHovering ? css(styleSheet.isHovering) : null}>{getLabel(topicProgression)}</span>
							</div>
						}
						isOpen={isHovering}
						type={PopoverType.white}
						preferredPlacement='above'
					>
						<div className={css(styleSheet.topicPopover)}>{getSummary(topicProgression)}</div>
					</DeprecatedPopover>
				);
			})}
		</>
	);
};

export const LiveScorecard = ({
	scorecard,
	toggle,
}: {
	scorecard: Api.IScorecard;
	toggle?: React.ReactNode;
}) => {
	const {
		completeTopicIdentifiers,
		setCompleteTopicIdentifiers,
		incompleteTopicIdentifiers,
		setIncompleteTopicIdentifiers,
	} = useTwilio();

	const onHover = () => {
		setLiveScoreHover(true);
	};

	const onLeave = () => {
		setLiveScoreHover(false);
	};
	const [liveScoreHover, setLiveScoreHover] = React.useState<boolean>(null);
	const webSockets = useWebsockets();
	const webSocketsConnected =
		(webSockets?.connectionStatus ?? Api.RemoteEventsConnectionStatus.Disconnected) ===
		Api.RemoteEventsConnectionStatus.Connected;

	const hasNecessarySubscriptions =
		webSockets.hasSubscription('callSuggestionHintEvent') && webSockets.hasSubscription('scorecardEvent');
	const webSocketsFullyConnected = webSocketsConnected && hasNecessarySubscriptions;

	const completedPercent = Math.floor(
		(completeTopicIdentifiers.length / (completeTopicIdentifiers.length + incompleteTopicIdentifiers.length)) * 100
	);

	const completeProgressions = scorecard.topicProgressions.filter(
		x => completeTopicIdentifiers.indexOf(x.topicIdentifier) > -1
	);
	const incompleteProgressions = scorecard.topicProgressions.filter(
		x => incompleteTopicIdentifiers.indexOf(x.topicIdentifier) > -1
	);
	React.useEffect(() => {
		if (!scorecard?.topicProgressions?.length) {
			return;
		}

		setCompleteTopicIdentifiers(scorecard.topicProgressions.filter(x => x.isCompleted).map(x => x.topicIdentifier));
		setIncompleteTopicIdentifiers(scorecard.topicProgressions.filter(x => !x.isCompleted).map(x => x.topicIdentifier));
	}, [scorecard, setCompleteTopicIdentifiers, setIncompleteTopicIdentifiers]);

	return (
		<div className={css(styleSheet.container)}>
			<header className={css(styleSheet.header)}>
				<div className={css(styleSheet.oneQuarter)}>
					<div className={css(styleSheet.currentScore)}>
						<span>Score: </span>
						<span className={css(completedPercent > 79 ? styleSheet.green : styleSheet.red)}>{completedPercent}%</span>
					</div>
				</div>

				<DeprecatedPopover
					key='live-score-card-popover'
					anchor={
						<div className={css(styleSheet.oneHalf)} onMouseEnter={() => onHover()}>
							<div className={css(webSocketsFullyConnected ? styleSheet.greenCircle : styleSheet.redCircle)}>⬤</div>
							<h5>Live Score Card</h5>
						</div>
					}
					isOpen={liveScoreHover}
					dismissOnClickOutside={true}
					onRequestClose={onLeave}
					type={PopoverType.white}
					preferredPlacement='above'
				>
					<div className={css(styleSheet.topicPopover)}>
						Websockets: {webSocketsConnected ? 'Connected' : 'Disconnected'}
						<br />
						Subscriptions: {hasNecessarySubscriptions ? 'Subscribed' : 'Not Subscribed'}
						<br />
						Last Message Received: {moment(webSockets.lastMessageReceivedTime).format('h:mm:ss a')}
						<br />
						Last Suggestion Message Received:{' '}
						{webSockets.lastMessageReceivedTimeBySubscriptionName('callSuggestionHintEvent')
							? moment(webSockets.lastMessageReceivedTimeBySubscriptionName('callSuggestionHintEvent')).format(
									'h:mm:ss a'
								)
							: 'Never'}
						<br />
						Last Scorecard Message Received:{' '}
						{webSockets.lastMessageReceivedTimeBySubscriptionName('scorecardEvent')
							? moment(webSockets.lastMessageReceivedTimeBySubscriptionName('scorecardEvent')).format('h:mm:ss a')
							: 'Never'}
					</div>
				</DeprecatedPopover>

				<div className={css(styleSheet.oneQuarter)}>{toggle}</div>
			</header>

			<div className={css(styleSheet.progressions)}>
				<GoalsList topicProgressions={completeProgressions} complete scorecard={scorecard} />
				<GoalsList topicProgressions={incompleteProgressions} scorecard={scorecard} />
			</div>
		</div>
	);
};
