import { ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { ViewModelTypeAutocomplete } from '../../../../containers/Settings/PhoneNumbers/components/ViewModelTypeAutocomplete';
import { styleSheet } from '../styles';

interface IProps {
	onChange: (item: string) => void;
}

export const VerticalSelect: React.FC<IProps> = ({ onChange }) => {
	const [selectedItem, selectItem] = useState(null);
	const handleSelection = (value: string) => {
		onChange(value);

		selectItem(value);
	};
	return (
		<ViewModelTypeAutocomplete<string>
			className={`vertical-select ${css(styleSheet.autocomplete)}`}
			placeholder={selectedItem ? 'Vertical: ' + selectedItem : 'Filter by Vertical'}
			model={ResourceAutoCompleteViewModelType.VerticalV2}
			onChange={handleSelection}
		/>
	);
};
