import { css } from 'aphrodite';
import * as React from 'react';
import { useContext } from 'react';
import { IPrincipal } from '../../../../extViewmodels';
import { Avatar } from '../../../../web/components/Avatar';
import { ViewportContext } from '../../../hooks/useViewport';
import { CrownGraphic } from '../../svgs/CrownGraphic';
import { styleSheet } from '../styles';

interface IProps {
	isAsc?: boolean;
	position: 'first' | 'second' | 'third';
	userReference: IPrincipal;
}

export const Top3Avatar: React.FC<IProps> = ({ isAsc, position, userReference }) => {
	const { isBelowSmall } = useContext(ViewportContext);
	const isFirst = position === 'first';
	const isSecond = position === 'second';
	const positionAsNumber = isFirst ? 1 : isSecond ? 2 : 3;

	const smallClassName = isBelowSmall && isFirst ? styleSheet.smallFirst : isBelowSmall ? styleSheet.small : null;

	return (
		<div
			className={`${css(styleSheet.top3Ind, styleSheet[position], !isFirst && styleSheet.notFirst, smallClassName)}`}
		>
			{isFirst && <CrownGraphic className={`${css(styleSheet.crown, isAsc && styleSheet.crownUpsideDown)} crown`} />}
			<div className={css(styleSheet.avatarContainer)}>
				<Avatar user={userReference} />
			</div>
			<div className='num'>
				<span>{positionAsNumber}</span>
			</div>
		</div>
	);
};

export const Top3AvatarGhost: React.FC = () => {
	const { isBelowSmall } = useContext(ViewportContext);
	return (
		<div
			className={`${css(
				styleSheet.top3Ind,
				styleSheet.notFirst,
				styleSheet.invisible,
				isBelowSmall && styleSheet.small
			)}`}
		/>
	);
};

export const Top3AvatarWrapper: React.FC = ({ children }) => {
	const { isBelowSmall } = useContext(ViewportContext);
	return <div className={css(styleSheet.top3, isBelowSmall && styleSheet.smallWrapper)}>{children}</div>;
};
