import { css } from 'aphrodite';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { IPrizeLog } from '../../../extViewmodels';
import { getDisplayName } from '../../../extViewmodels/Utils';
import { ViewportContext } from '../../hooks/useViewport';
import { Group } from '../leadView/components/Group';
import { PrizesList } from './components/PrizesList';
import { styleSheet } from './styles';

export interface ILeaderBoardProps {
	data: IPrizeLog[];
	renderEmptyState?: string | JSX.Element;
	renderFilter: JSX.Element;
}

export const PrizesScore: React.FC<ILeaderBoardProps> = ({ data, renderEmptyState, renderFilter }) => {
	const [innerData, setData] = useState<IPrizeLog[]>(null);

	const { isBelowSmall } = useContext(ViewportContext);

	useEffect(() => {
		if (data) {
			setData(data);
		}
	}, [data]);

	if (!innerData) {
		return null;
	}

	return (
		<div className={css(styleSheet.leaderBoardContainer)}>
			<Group className={css(styleSheet.demosTotalDialsDisplayContainer)} spacingSize={20} vertical={true}>
				{renderFilter}
				<div
					className={css(
						styleSheet.leaderBoardContainerTitle,
						isBelowSmall ? styleSheet.leaderBoardContainerTitleMobile : null
					)}
				>
					Winner, winner, chicken dinner!
				</div>
			</Group>
			{renderEmptyState ? (
				renderEmptyState
			) : (
				<Group vertical={true} spacingSize={5}>
					{innerData.map(item => {
						return (
							<PrizesList
								key={item.gameToken.id}
								date={item.gameToken.redemptionDate}
								name={getDisplayName(item.gameToken.creator)}
								prize={item.gameToken.gameOutcome.prize}
							/>
						);
					})}
				</Group>
			)}
		</div>
	);
};
