import { History } from 'history';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useEventLogging } from '../../../models/Logging';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { PrivateRoute, RouteWithLoggedPageView } from '../../../web/components/PrivateRoute';
import { ErrorMessagesProvider } from '../../../web/components/errorMessages/ErrorMessagesProvider';
import { CustomerSessionStart } from '../../../web/containers/CustomerSessionStart';
import { LazyLoader } from '../../../web/containers/LazyLoader';
import { Logout } from '../../../web/containers/Logout';
import { MainContainerErrorBoundary } from '../../components/MainContainerErrorBoundary';
import { WsNotificationProvider } from '../../components/WsNotification';
import { CombinedProviders } from '../../contexts/CombineProviders';
import { TelephonyContextProvider } from '../../contexts/telephony';
import { TranscriptionEventsProvider } from '../../entities/PhoneCall/TranscriptionEventsProvider';
import { useWebsockets } from '../../hooks';
import { AppState } from '../../models/AppState';
import { CallLogContainer } from '../CallLog';
import { DemosContainer } from '../Demos';
import { FollowUpsContainer } from '../FollowUps';
import { HelpContainer } from '../Help';
import { IntegrationContainer } from '../Integration';
import { LeaderboardContainer } from '../Leaderboard';
import { Login } from '../Login';
import { MainContainer } from '../MainContainer';
import { PageNotFound } from '../PageNotFound';
import { QueueContainer } from '../Queue';
import { ReportingContainer } from '../Reporting';
import { SettingsContainer } from '../Settings';
import { SlotMachinePrizes } from '../Settings/SlotMachinePrizes';
import { VoicemailContainer } from '../Voicemail';

const LazyUserSetup = React.lazy(() => import(/* webpackChunkName: "user-setup" */ '../user/UserSetup'));

interface IProps {
	history: History<any>;
}

const AllProviders = CombinedProviders([TelephonyContextProvider, TranscriptionEventsProvider, WsNotificationProvider]);

export const AidaRouterBase: React.FC<IProps> = () => {
	const logger = useEventLogging();
	const userSession = useUserSession();
	const webSockets = useWebsockets();

	const onRender404 = (props: RouteComponentProps<any>) => {
		return renderInMainContainer(props, <PageNotFound />);
	};

	const onRenderLogout = (props: RouteComponentProps<any>) => {
		logger.logPageView('logout');
		webSockets?.permananentlyDisconnect();
		return <Logout {...props} isAida={true} userSession={userSession} />;
	};

	const onRenderCustomerSessionStart = (props: RouteComponentProps<any, any, any>) => {
		logger.logPageView('customerSessionStart');
		return <CustomerSessionStart {...props} userSession={userSession} />;
	};

	const renderInMainContainer = (props: RouteComponentProps<any>, component: React.ReactElement<any>) => {
		return (
			<MainContainerErrorBoundary>
				<AllProviders>
					<MainContainer {...props}>{component}</MainContainer>
				</AllProviders>
			</MainContainerErrorBoundary>
		);
	};

	const onRenderRoute = (Component: React.JSXElementConstructor<any>) => (props: RouteComponentProps<any>) => {
		logger.logPageView(props.location.pathname);
		return renderInMainContainer(props, <Component />);
	};

	return (
		<>
			{!!AppState.errorMessages && <ErrorMessagesProvider errorMessageViewModel={AppState.errorMessages} />}
			<Switch>
				<Redirect exact={true} from='/' to='/login' />

				<Route path='/customerSessionStart' render={onRenderCustomerSessionStart} />

				<RouteWithLoggedPageView path='/login'>
					<Login />
				</RouteWithLoggedPageView>

				<PrivateRoute
					exact={true}
					path='/user/voicemail'
					render={onRenderRoute(VoicemailContainer)}
					userSession={userSession}
				/>

				<Route path='/user'>
					<LazyLoader>
						<LazyUserSetup />
					</LazyLoader>
				</Route>

				<RouteWithLoggedPageView path='/customerSessionStart'>
					<CustomerSessionStart redirection='/call-log' userSession={userSession} />
				</RouteWithLoggedPageView>

				<PrivateRoute path='/help-faqs' render={onRenderRoute(HelpContainer)} userSession={userSession} />
				<PrivateRoute path='/queue' render={onRenderRoute(QueueContainer)} userSession={userSession} />
				<PrivateRoute
					path='/call-log/:recentCallId?'
					render={onRenderRoute(CallLogContainer)}
					userSession={userSession}
				/>

				<PrivateRoute
					path='/demos-to-confirm'
					redirectPath='/demos'
					render={onRenderRoute(DemosContainer)}
					userSession={userSession}
				/>
				<PrivateRoute path='/demos' render={onRenderRoute(DemosContainer)} userSession={userSession} />

				<PrivateRoute path='/follow-ups' render={onRenderRoute(FollowUpsContainer)} userSession={userSession} />
				<PrivateRoute path='/leaderboard' render={onRenderRoute(LeaderboardContainer)} userSession={userSession} />
				<PrivateRoute path='/settings/:setting?' render={onRenderRoute(SettingsContainer)} userSession={userSession} />
				<PrivateRoute path='/reporting' render={onRenderRoute(ReportingContainer)} userSession={userSession} />
				<PrivateRoute
					path='/integration/:option?/:adding?'
					render={onRenderRoute(IntegrationContainer)}
					userSession={userSession}
				/>
				<PrivateRoute path='/logout' render={onRenderLogout} userSession={userSession} />

				{userSession?.account?.features?.aida.achievementsEnabled ? (
					<PrivateRoute
						path='/slotmachine/prizes'
						render={onRenderRoute(SlotMachinePrizes)}
						userSession={userSession}
					/>
				) : null}
				<PrivateRoute render={onRender404} userSession={userSession} />
			</Switch>
		</>
	);
};

export const AidaRouter = observer(AidaRouterBase);
