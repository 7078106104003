import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { DemosScore } from '../../../components/LeaderboardComponents/DemosScore';
import { ILeaderboardDemosFilter, useLeaderboardDemos } from '../../../entities/Leaderboard/useDemos';
import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { useInterval } from '../../../hooks/useInterval';
import { styleSheet } from '../styles';
import { DailySlider } from './DailySlider';

let isFirstLoading = true;

export const Demos: React.FC = () => {
	const [filter, setFilter] = useState<ILeaderboardDemosFilter>({
		day: moment().format('YYYY-MM-DD'),
	});

	const { get, data, empty, status } = useLeaderboardDemos();

	useEffect(() => {
		get({ filter });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		if (status === FetchStatus.Done) {
			isFirstLoading = false;
		}
	}, [status]);

	useInterval(() => {
		get({ filter });
	}, 30000);

	const renderEmptyState = (
		<div className={css(styleSheet.noLeaders)}>
			<div className='no-leaders-header'>No data yet.</div>
		</div>
	);

	const handleFilterChange = (value: string) => {
		setFilter({ day: value });
	};

	const renderFilter = useMemo(() => <DailySlider onChange={handleFilterChange} />, []);

	const renderList = () => {
		const emptyState = empty ? renderEmptyState : null;
		return (
			<DemosScore renderEmptyState={isFirstLoading ? emptyState : null} renderFilter={renderFilter} scores={data} />
		);
	};

	const isLoading = status !== FetchStatus.Done;

	return (
		<div className={css(styleSheet.leaderboardContainer)}>
			<div className={css(styleSheet.boardsContainer)}>
				{isLoading && isFirstLoading ? <LoadingSpinner /> : renderList()}
			</div>
		</div>
	);
};
