import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IAccountTag, ResourceAutoCompleteViewModelType } from '../../../../../extViewmodels';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { AutoCompleteSearchField } from '../../../../../web/components/autocomplete/AutoCompleteSearchField';
import { SearchIcon } from '../../../../../web/components/svgs/icons/SearchIcon';
import { LeadRuleViewModel, LeadRulesViewModel, OwnershipEnforcement } from '../../../../viewModels/leadrules';
import { styleSheet } from '../styles';

interface IProps {
	leadRules: LeadRulesViewModel;
	onReadyToCreate: (rule: LeadRuleViewModel) => void;
}

export const BlitzesSearch: React.FC<IProps> = observer(({ onReadyToCreate }) => {
	const userSession = useUserSession();

	const handleSelection = (tag: IAccountTag) => {
		const newRule = new LeadRuleViewModel(userSession, {
			isEnabled: true,
			name: tag.tag,
			ownershipEnforcement: OwnershipEnforcement.LeadVertical,
			priority: 300,
		});

		newRule.setFilterFromArrays([], [tag.tag], [], []);
		onReadyToCreate(newRule);
	};

	return (
		<div className={css(styleSheet.inputContainer)}>
			<SearchIcon className={css(styleSheet.searchIcon)} />
			<AutoCompleteSearchField
				anchorClassName={css(styleSheet.input)}
				clearSearchFieldAfterSelectingItem={true}
				customQueryParams={{ excludeHidden: true }}
				dropdownContentClassName={css(styleSheet.resultsContainer)}
				initialSearchQuery=''
				inputId='blitz-tag-autcomplete'
				inputProps={{
					placeholder: 'Search for a tag to add it as a Blitz...',
				}}
				onItemSelected={handleSelection}
				resultsLimit={5}
				type={ResourceAutoCompleteViewModelType.AccountTag}
			/>
		</div>
	);
});
