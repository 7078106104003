import { css } from 'aphrodite';
import * as React from 'react';
import { IIntegrationPropertyItem } from '../../../../viewModels/leads/interfaces';
import { Group } from '../../../generics/Group';
import { SimpleCheckbox } from '../../../generics/SimpleCheckbox';
import { styleSheet } from './styles';

export interface ICheckboxListItem {
	isAdded?: boolean;
	content?: IIntegrationPropertyItem;
	id: string;
	label: string;
}

interface IProps {
	disabled?: boolean;
	getChecked: (item: IIntegrationPropertyItem) => boolean;
	isLoading: boolean;
	items: ICheckboxListItem[];
	onCheckboxChange?: (item: ICheckboxListItem, isChecked: boolean) => void;
}

export const CheckboxList: React.FC<IProps> = ({ disabled, getChecked, isLoading, items, onCheckboxChange }) => {
	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.header)}>
				<div className={css(styleSheet.col)}>Property name:</div>
				<div className={css(styleSheet.col)}>Status:</div>
			</div>
			<Group vertical={true} className={css(styleSheet.scrollablePropertyList)}>
				{isLoading && (
					<div className={css(styleSheet.header)}>
						<div className={css(styleSheet.col)}>
							<i>Loading list...</i>
						</div>
						<div className={css(styleSheet.col)} />
					</div>
				)}
				{items.map((item: ICheckboxListItem) => {
					const { content, id, isAdded, label } = item;
					const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
						onCheckboxChange?.(item, target.checked);
					};

					const isDisabled = disabled || getChecked(content);
					return (
						<div key={id} className={css(styleSheet.row)}>
							<div className={css(styleSheet.col, isDisabled && styleSheet.colDisabled)}>
								<SimpleCheckbox
									checked={isAdded || getChecked(content)}
									disabled={isDisabled}
									isSmall={true}
									label={label}
									onChange={handleChange}
								/>
							</div>

							<div className={css(styleSheet.col)}>{getChecked(content) && 'Added'}</div>
						</div>
					);
				})}
			</Group>
		</div>
	);
};
