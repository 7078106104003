import { ITelephonyConfiguration, IUser, UserViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getDisplayName } from '../../../../extViewmodels/Utils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { Button } from '../../../../web/components/Button';
import PlaceholderImageSource from '../../../assets/call-from-unknown-placeholder.svg';
import { useToaster } from '../../../hooks';
import { useResponseHandler } from '../../../hooks/error';
import { BasicPage } from '../../BasicPage';
import { NumbersList } from './components/NumbersList';
import { SearchField } from './components/SearchField';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

export const PhoneNumbers: React.FC<IProps> = observer(({ className = '' }) => {
	const userSession = useUserSession();
	const toaster = useToaster();
	const history = useHistory();
	const location = useLocation();

	const [selectedUser, setSelectedUser] = useState<IUser>(null);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [userLoadError] = useResponseHandler('LoadUser', 'Unable to load user');
	const [phoneNumbers, setPhoneNumbers] = useState<ITelephonyConfiguration[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const updatedSearch = parse(location.search) as { userId?: string };
		if (updatedSearch.userId) {
			setSelectedUserId(updatedSearch.userId);
		}
	}, [location.search]);

	useEffect(() => {
		loadNumbersForUser();
		if ((!selectedUser || selectedUser?.id !== selectedUserId) && selectedUserId) {
			const user = new UserViewModel(userSession, { id: selectedUserId });
			user
				.load()
				?.then(() => {
					setSelectedUser(user.toJs());
					history.push(`/settings/phoneNumbers?userId=${selectedUserId}`);
				})
				?.catch(userLoadError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUserId]);

	const loadNumbersForUser = async () => {
		if (!selectedUserId) {
			setLoading(false);
			return;
		}

		setLoading(true);
		setPhoneNumbers([]);
		const result = await userSession.webServiceHelper.callWebServiceAsync<ITelephonyConfiguration[]>(
			`aida/userPhoneNumbers/${selectedUserId}`,
			'GET'
		);
		if (!result) {
			toaster.push({
				message: `Could not load phone numbers for ${getDisplayName(selectedUser)}`,
				type: 'errorMessage',
			});
			setLoading(false);
			return;
		}

		setPhoneNumbers(result.value ?? []);
		setLoading(false);
	};

	const syncNumbersForUser = async () => {
		if (!selectedUserId) {
			return;
		}

		setLoading(true);
		setPhoneNumbers([]);
		const result = await userSession.webServiceHelper.callWebServiceAsync<ITelephonyConfiguration[]>(
			`twilio/requestNumbers?userId=${selectedUserId}`,
			'POST'
		);
		if (!result) {
			toaster.push({
				message: `Could not load phone numbers for ${getDisplayName(selectedUser)}`,
				type: 'errorMessage',
			});
			setLoading(false);
			return;
		}

		setPhoneNumbers(result.value ?? []);
		setLoading(false);
	};

	const onUserSelected = (user: IUser) => {
		if (!user?.id) {
			setSelectedUserId(null);

			setSelectedUser(null);

			setPhoneNumbers(null);
			history.push(`/settings/phoneNumbers`);
			return;
		}

		setSelectedUserId(user?.id);
	};

	const isEmpty = !phoneNumbers?.length || phoneNumbers?.length === 0;

	return (
		<BasicPage className={className} title='Phone Numbers'>
			<div className={css(styleSheet.container)}>
				<SearchField defaultSelected={selectedUser} onChange={onUserSelected} />

				{selectedUser && !loading && (
					<div className={css(styleSheet.actionsBar, isEmpty ? styleSheet.actionsBarCenter : null)}>
						<Button
							label={`Refill numbers for ${getDisplayName(selectedUser)}`}
							onClick={syncNumbersForUser}
							kind='secondary'
						/>
					</div>
				)}

				{isEmpty && !loading ? (
					<div className={css(styleSheet.placeholder)}>
						<img className={css(styleSheet.placeholderImage)} src={PlaceholderImageSource} />
					</div>
				) : (
					<NumbersList
						isLoading={loading}
						onClickToAddNumbers={syncNumbersForUser}
						phoneNumbersList={phoneNumbers}
						selectedUser={selectedUser}
					/>
				)}
			</div>
		</BasicPage>
	);
});
