import { IPhoneCallCategory, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useLambda } from '../../../models/hooks/useLambda';
import { AutoCompleteSearchField } from '../../../web/components/autocomplete/AutoCompleteSearchField';
import { TagAddIcon } from '../../../web/components/svgs/icons/TagAddIcon';
import { Tag } from '../../../web/components/tags/Tag';
import { category, gray, hotPink } from '../../styles/colors';
import { Group } from '../leadView/components/Group';
import { styleSheet } from './styles';

interface IProps {
	categories: string[];
	categoryObjects: IPhoneCallCategory[];
	onAdd(category: string): void;
	onRemove(category: string): void;
}

export const CategoryListBuilder: React.FC<IProps> = ({ categories, categoryObjects, onAdd, onRemove }) => {
	const [isAdding, setAdding, setAddingLambda] = useLambda(false);

	const handleRemove = (selectedCategory: string) => {
		const categoryToRemove = categories.find(x => x === selectedCategory);

		onRemove(categoryToRemove);
	};

	const handleSelected = (selectedCategory: IPhoneCallCategory) => {
		const categoryExists = categories.find(x => x === selectedCategory.name);
		if (!categoryExists) {
			onAdd(selectedCategory.name);
		}
		setAdding(false);
	};

	const categoryByName = (c: string): IPhoneCallCategory =>
		categoryObjects?.filter((i: IPhoneCallCategory) => c === i.name)?.[0];

	const hasCategories = categories?.length > 0;

	const addContainerExtraMargin = hasCategories ? styleSheet.addContainerExtraMargin : null;
	return (
		<div className={`${css(styleSheet.container)} cat-list-builder`}>
			{categories?.map(categoryName => {
				const color = categoryByName(categoryName)?.hexColor || gray;
				return (
					<Category
						key={categoryName}
						color={color}
						onRemoveButtonClicked={handleRemove}
						showRemoveButton={true}
						value={categoryName}
					/>
				);
			})}
			<div className={css(styleSheet.addContainer, addContainerExtraMargin)}>
				{isAdding ? (
					<Group>
						<AutoCompleteSearchField
							anchorClassName={css(styleSheet.searchBox)}
							clearSearchFieldAfterSelectingItem={true}
							initialSearchQuery=''
							inputId='category-search-box'
							inputProps={{ placeholder: 'Search' }}
							onItemSelected={handleSelected}
							resultsLimit={5}
							startSearchingWhenOpen={true}
							type={ResourceAutoCompleteViewModelType.PhoneCallCategory}
						/>
						<button onClick={setAddingLambda(false)}>
							<small>Cancel</small>
						</button>
					</Group>
				) : (
					<span className={css(styleSheet.addButton)} onClick={setAddingLambda(true)}>
						<TagAddIcon fill={category} />
						<span>Add Category</span>
					</span>
				)}
			</div>
		</div>
	);
};

interface ICategoryProps {
	color?: string;
	onRemoveButtonClicked?: (category: string) => void;
	showRemoveButton?: boolean;
	value: string;
}

export const Category: React.FC<ICategoryProps> = ({
	color = hotPink,
	onRemoveButtonClicked,
	showRemoveButton = false,
	value,
}) => {
	return (
		<span className={css(styleSheet.catWrapper)}>
			<svg width='14px' height='26px' viewBox='0 0 14 26' className={css(styleSheet.catSvg)}>
				<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
					<path
						d='M13.1606445,0 L13.1606445,26 L9.69577423,26 C8.27475581,26 6.96041692,25.2460921 6.24298936,24.0194743 L0.838516673,14.7792076 C0.302022756,13.8619401 0.290803919,12.7293027 0.809023188,11.8015883 L6.25662164,2.04931523 C6.96342482,0.783998493 8.29938747,-6.21938301e-16 9.74873117,0 L13.1606445,0 Z M9.58354424,11 C8.40400196,11 7.44779402,11.8954305 7.44779402,13 C7.44779402,14.1045695 8.40400196,15 9.58354424,15 C10.7630865,15 11.7192945,14.1045695 11.7192945,13 C11.7192945,11.8954305 10.7630865,11 9.58354424,11 Z'
						fill={color}
					/>
				</g>
			</svg>
			<Tag
				tagValue={value}
				onRemoveButtonClicked={onRemoveButtonClicked}
				showRemoveButton={showRemoveButton}
				className={css(styleSheet.cat)}
				style={{ background: color }}
			/>
		</span>
	);
};
