import { css } from 'aphrodite';
import { ISelectOption, Select } from '../../../../web/components/Select';
import { grayIconFill } from '../../../styles/colors';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';

interface IProps<T> {
	defaultSelected?: ISelectOption<T>;
	description: string;
	disabled?: boolean;
	onSelect: (option: ISelectOption<T>) => void;
	selectOptions: ISelectOption<T>[];
}

export function GeneralSettingsItem<T>({ defaultSelected, description, disabled, onSelect, selectOptions }: IProps<T>) {
	const handleTurnThisOn = (option: ISelectOption<T>) => {
		onSelect(option);
	};
	return (
		<Group vCentered={true}>
			<div className={css(styleSheet.regularText)} style={{ color: disabled ? grayIconFill : null }}>
				{description}
			</div>
			<Select
				disabled={disabled}
				onOptionClick={handleTurnThisOn}
				options={selectOptions}
				selectedOption={defaultSelected}
			/>
		</Group>
	);
}
