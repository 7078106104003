import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect } from 'react';
import { useEventLogging } from '../../../models/Logging';
import { useLambda } from '../../../models/hooks/useLambda';
import { TinyPopover } from '../../../web/components/TinyPopover';
import earthGraphic from '../../assets/earth_graphic.svg';
import { useQueue } from '../../hooks/queue';
import { TransparentButton } from '../TransparentButton';
import { Mrry } from '../svgs/Mrry';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

const ClientsNearMeBase: React.FC<IProps> = ({ className = '' }) => {
	const queue = useQueue();
	const [isOpen, , setIsOpenLambda] = useLambda(false);
	const { logApiError } = useEventLogging();

	const loadClientsNearMe = async () => {
		queue.lead?.getClientsNearMe().catch(err => logApiError('GetNearbyClients-Error', err));
	};

	useEffect(() => {
		loadClientsNearMe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.companyId]);

	const anchor = () => (
		<div className={css(styleSheet.globe)} onMouseEnter={setIsOpenLambda(true)}>
			<img src={earthGraphic} alt='small image of earth' />

			{queue.lead?.clientsNearMe?.total > 0 && (
				<div className={css(styleSheet.bubble)}> {queue.lead?.clientsNearMe?.total}</div>
			)}
		</div>
	);

	return (
		<TransparentButton className={`${css(styleSheet.clientsNearMeContainer)} ${className}`}>
			<div>Clients Near Me</div>
			<TinyPopover
				isOpen={isOpen && queue.lead?.clientsNearMe?.total > 0}
				anchor={anchor()}
				placement={['right']}
				dismissOnOutsideAction={true}
				onRequestClose={setIsOpenLambda(false)}
			>
				<div className={css(styleSheet.popoverContainer)}>
					<div className={css(styleSheet.clientsContainer)}>
						<div className={css(styleSheet.totalClients)}>{queue.lead?.clientsNearMe?.total} Total Clients</div>
						<div className={css(styleSheet.clientsList)}>
							{queue.lead?.clientsNearMe?.groupings?.map((x, i) => {
								return <div key={i}>{x.total + ' in ' + x.label}</div>;
							})}
						</div>
					</div>
					<div>
						<Mrry />
					</div>
				</div>
			</TinyPopover>
		</TransparentButton>
	);
};

export const ClientsNearMe = observer(ClientsNearMeBase);
