import { ITelephonyConfiguration, IUser } from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getDisplayName } from '../../../../../extViewmodels/Utils';
import { copyToClipboard } from '../../../../../models/UiUtils';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { Button } from '../../../../../web/components/Button';
import { LoadingSpinner } from '../../../../../web/components/LoadingSpinner';
import { Toggle } from '../../../../../web/components/Toggle';
import { MrryDirection, MrryMessageType, MrryModal } from '../../../../components/MrryModal';
import { Group } from '../../../../components/leadView/components/Group';
import { CopyIcon } from '../../../../components/svgs/icons/CopyIcon';
import { useToaster } from '../../../../hooks';
import { brandPrimary, grayIconFill } from '../../../../styles/colors';
import { aidaBaseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from '../styles';

interface IProps {
	isLoading: boolean;
	onClickToAddNumbers?: () => void;
	phoneNumbersList: ITelephonyConfiguration[];
	selectedUser: IUser;
}

export const NumbersList: React.FC<IProps> = ({ isLoading, onClickToAddNumbers, phoneNumbersList, selectedUser }) => {
	const userSession = useUserSession();
	const toaster = useToaster();
	const [phoneNumbers, setPhoneNumbers] = useState<ITelephonyConfiguration[]>(phoneNumbersList || []);

	const [loadingNumber, setLoadingNumber] = useState<string>(null);

	const [showMrry, setMrryState] = useState<boolean>(false);

	useEffect(() => {
		setPhoneNumbers(phoneNumbersList);
	}, [phoneNumbersList]);

	const toggleOutboundCalling = (phoneNumberId: string) => async () => {
		setMrryState(false);
		if (!phoneNumberId) {
			return;
		}
		const currentState = phoneNumbers.find(x => x.id === phoneNumberId)?.outboundCallingDisabled;
		setLoadingNumber(phoneNumberId);
		const result = await userSession.webServiceHelper.callWebServiceAsync<ITelephonyConfiguration>(
			`aida/userPhoneNumbers/${phoneNumberId}?outboundCallingDisabled=${!currentState}`,
			'PATCH'
		);
		if (!result) {
			toaster.push({
				message: `Could not load phone numbers for ${getDisplayName(selectedUser)}`,
				type: 'errorMessage',
			});

			setLoadingNumber(null);
			return;
		}

		const currentIndex = phoneNumbers.findIndex(x => x.id === phoneNumberId);
		const numbers = [...phoneNumbers];

		numbers.splice(currentIndex, 1, result.value);
		setPhoneNumbers(numbers);

		setLoadingNumber(null);
		setMrryState(true);
	};

	const onCopyPhoneNumber = (id: string) => () => {
		const success = copyToClipboard(id);

		toaster.push({
			message: success ? 'Phone number copied to clipboard' : 'Failed to copy to clipboard',
			type: success ? 'successMessage' : 'errorMessage',
		});
	};

	return (
		<div className={css(styleSheet.numbersContainer)}>
			{phoneNumbers?.map(phoneNumber => {
				const isNew = moment(moment()).diff(phoneNumber.creationDate, 'seconds') <= 60;
				const wasUpdated = moment(moment()).diff(phoneNumber.lastModifiedDate) <= 100;
				const isDisabled = phoneNumber.outboundCallingDisabled === true;
				const handleCloseMrry = () => setMrryState(false);
				return (
					<div key={phoneNumber.id} className={`${css(styleSheet.cardContainer, styleSheet.flexContainer)} lead-rule`}>
						{isNew && (
							<div className={css(styleSheet.priority)}>
								<div className={css(styleSheet.priorityLabel)}>New</div>
							</div>
						)}

						{showMrry && wasUpdated && isDisabled && (
							<MrryModal
								fromDirection={MrryDirection.FromLeft}
								internalCloseButton={true}
								message={{
									content: (
										<div className={css(styleSheet.mrryContent)}>
											<p>This number has been disabled.</p>
											<p>Would you like to add another number for this user?</p>
											<Button label='Add another number' onClick={onClickToAddNumbers} size='small' />
										</div>
									),
									type: MrryMessageType.Success,
								}}
								onRequestClose={handleCloseMrry}
							/>
						)}

						{loadingNumber === phoneNumber.id ? (
							<div className={css(aidaBaseStyleSheet.flexCenter, styleSheet.loadingContainer)}>
								<LoadingSpinner />
							</div>
						) : (
							<Group spacingSize={20}>
								<Group fullWidth={false}>
									<Toggle
										id={`${phoneNumber.id}-outbound-toggle`}
										isOn={!isDisabled}
										onToggleCheckChanged={toggleOutboundCalling(phoneNumber.id)}
										uncheckedColor={grayIconFill}
									/>
								</Group>
								<Group spacingSize={15}>
									<div className={css(styleSheet.phoneNumberContainer)}>
										<span>{phoneNumber.number}</span>
										<span className={css(styleSheet.copyIcon)} onClick={onCopyPhoneNumber(phoneNumber.number)}>
											<CopyIcon fillColor={brandPrimary} />
										</span>
									</div>
									<div className={css(styleSheet.callingContainer)}>Outbound Calling</div>
									<div className={css(styleSheet.connectionContainer)}>{phoneNumber.connectionState}</div>
									<div className={css(styleSheet.dateContainer)}>
										Created: {moment(phoneNumber.creationDate).format('M/D/YY h:mm a')}
									</div>
									<div className={css(styleSheet.providerContainer)}>
										{`${phoneNumber.provider} (${phoneNumber.orderId})`}
									</div>
								</Group>
							</Group>
						)}
					</div>
				);
			})}
			{isLoading && (
				<div className={css(aidaBaseStyleSheet.flexCenter)}>
					<LoadingSpinner type='large' />
				</div>
			)}
		</div>
	);
};
