import * as h from 'history';
import { IReactionDisposer, autorun } from 'mobx';
import * as React from 'react';
import { FullScreenModalViewModel } from '../../../../viewmodels/AppViewModels';

interface ILocationState {
	closingModalLocation?: h.Location<any>;
	location?: h.Location<any>;
}

export const useSlotMachineFullScreenModal = () => {
	const [state, setState] = React.useState<ILocationState>({});
	const [modalProps] = React.useState<Partial<ReactModal.Props>>({
		onAfterClose: () => {
			setState(value => ({
				...value,
				closingModalLocation: null,
				location: null,
			}));
		},
	});
	const fullscreenModal = React.useRef(new FullScreenModalViewModel()).current;
	const onModalHistoryChangedDisposerRef = React.useRef<IReactionDisposer>(null);

	React.useEffect(() => {
		onModalHistoryChangedDisposerRef.current = autorun(() => {
			const { location, closingModalLocation } = state;
			if (location !== fullscreenModal.history.location && !!fullscreenModal.hasValidLocation) {
				// open
				setState(value => ({
					...value,
					location: fullscreenModal.history.location,
				}));
				return;
			}

			if (!fullscreenModal.hasValidLocation && !!location && closingModalLocation !== location) {
				// closing

				setState(value => ({
					...value,
					closingModalLocation: location,
					location: null,
				}));
			}
		});
		return () => {
			fullscreenModal.history.listenToBrowserLocationStateChanges(false);
			if (onModalHistoryChangedDisposerRef.current) {
				onModalHistoryChangedDisposerRef.current();

				onModalHistoryChangedDisposerRef.current = null;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		...state,
		fullscreenModal,
		modalProps,
	};
};
