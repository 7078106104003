import * as Api from '@ViewModels';
import moment from 'moment';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useErrorMessages } from '../../hooks';
import { IPrizeLogFilter } from '../../models/slotMachines';
import { ApiClient } from '../../services/api';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';

export const prizeLogFilterToGameTokenFilter = (filter: IPrizeLogFilter): Api.IGameTokenFilter => {
	const newFilter: Api.IGameTokenFilter = {
		criteria: [],
		op: Api.FilterOperator.And,
	};
	if (filter?.date) {
		newFilter.criteria.push({
			op: Api.FilterOperator.Gt,
			property: Api.GameTokenFilterCriteriaProperty.OutcomeDate,
			value: moment(filter?.date.start).startOf('day').format('YYYY-MM-DD'),
		});

		newFilter.criteria.push({
			op: Api.FilterOperator.Lt,
			property: Api.GameTokenFilterCriteriaProperty.OutcomeDate,
			value: moment(filter?.date.end).endOf('day').format('YYYY-MM-DD'),
		});
	}
	if (filter?.prizePaid) {
		newFilter.criteria.push({
			property: Api.GameTokenFilterCriteriaProperty.PrizePaid,
			value: 'false',
		});
	}
	if (filter?.user?.id) {
		newFilter.criteria.push({
			property: Api.GameTokenFilterCriteriaProperty.User,
			value: filter.user.id,
		});
	}
	return newFilter;
};

export const useLeaderboardPrizes = () => {
	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<Api.IPrizeLog[]>();

	const userSession = useUserSession();
	const errorMessages = useErrorMessages();

	const client = new ApiClient(userSession);

	const get = async ({ filter }: { filter: IPrizeLogFilter }) => {
		handleWorking();
		try {
			const response = await client.getLeaderboardPrizes(prizeLogFilterToGameTokenFilter(filter));
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			errorMessages.pushApiError(error);
		}
	};

	return {
		...state,
		empty: !state?.data,
		get,
		reset,
		status: state.status,
	};
};
