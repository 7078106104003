import { css } from 'aphrodite';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Button } from '../../../web/components/Button';
import { TagIcon } from '../../../web/components/svgs/icons/TagIcon';
import { XIcon } from '../../../web/components/svgs/icons/XIcon';
import { white } from '../../styles/colors';
import { LeadRuleViewModel, LeadRulesViewModel } from '../../viewModels/leadrules';
import { BlueModal } from '../BlueModal';
import { MrryDirection, MrryMessageType, MrryModal } from '../MrryModal';
import { Fill } from '../generics/Fill';
import { Group } from '../generics/Group';
import { RuleContainerHeader } from './components/RuleContainerHeader';
import { styleSheet } from './styles';

interface IProps {
	leadRule: LeadRuleViewModel;
	leadRules: LeadRulesViewModel;
	onRequestClose(result?: LeadRuleViewModel, canceled?: boolean): void;
}

interface IMrryState {
	message: string;
	open: boolean;
}

const mrryInitialState: IMrryState = {
	message: null,
	open: false,
};

export const LeadRuleScheduleModal: React.FC<IProps> = observer(({ leadRule, leadRules, onRequestClose }) => {
	const [editableLeadRule, setLeadRule] = useState<LeadRuleViewModel>(null);

	const [showMrry, setMrryState] = useState<IMrryState>(mrryInitialState);

	const userSession = useUserSession();

	useEffect(() => {
		setLeadRule(leadRule ? new LeadRuleViewModel(userSession, toJS(leadRule.getLeadRule)) : null);
	}, [leadRule, userSession]);

	const handleSave = () => {
		editableLeadRule
			.updateSchedule()
			.then(() => {
				leadRule.setLeadRule(editableLeadRule.getLeadRule);

				onRequestClose?.(null, true);
			})
			.catch(error => {
				setMrryState({
					message: error?.systemMessage || 'An error ocurred, please try again.',
					open: true,
				});
			})
			.finally(() => leadRules.sort());
	};

	const onClickToClose = () => {
		onRequestClose?.(null, true);
	};

	const handleCloseMrry = () => setMrryState(mrryInitialState);

	return (
		<div className={`lead-rule-modal ${css(styleSheet.container)}`}>
			<BlueModal
				isOpen={!!editableLeadRule}
				onRequestClose={onRequestClose}
				useDefaultHeader={false}
				className={css(styleSheet.leadRuleModal)}
			>
				<div className={css(styleSheet.header)}>
					<TagIcon fillColor={white} />
					<button onClick={onClickToClose}>
						<XIcon fillColor={white} height={12} width={12} />
					</button>
				</div>
				<RuleContainerHeader leadRule={editableLeadRule} />
				{showMrry.open && (
					<MrryModal
						fromDirection={MrryDirection.FromTop}
						message={{
							content: <p>{showMrry.message}</p>,
							duration: 5000,
							type: MrryMessageType.Error,
						}}
						onRequestClose={handleCloseMrry}
					/>
				)}
				<div className={css(styleSheet.divider)} />
				<div className={css(styleSheet.ctaContainer)}>
					<Group vCentered={true} spacingSize={20}>
						<Button label='Save' onClick={handleSave} />
						<Fill />
					</Group>
				</div>
			</BlueModal>
		</div>
	);
});
