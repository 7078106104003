import * as Api from '@ViewModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useErrorMessages } from '../../hooks';
import { ApiClient } from '../../services/api';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';

export interface IRequestOptions {
	pageSize?: number;
	pageToken?: string;
	sort?: string;
}

export const usePhoneCallNotes = <T>() => {
	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<T>();

	const userSession = useUserSession();
	const errorMessages = useErrorMessages();

	const client = new ApiClient(userSession);

	const get = async (phoneCallId: string, options?: IRequestOptions) => {
		const { pageSize = 25, pageToken, sort = 'asc' } = options || {};
		handleWorking();
		try {
			const response = await client.getPhoneCallNotes(phoneCallId, {
				pageSize,
				pageToken,
				sort,
			});
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			errorMessages.pushApiError(error);
		}
	};

	const create = async (phoneCallId: string, body: FormData) => {
		handleWorking();
		try {
			const response = await client.createPhoneCallNote(phoneCallId, body);
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			errorMessages.pushApiError(error);
		}
	};

	return {
		...state,
		create,
		empty: !state?.data,
		get,
		reset,
		status: state.status,
	};
};
