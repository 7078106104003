import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { LoadingSpinner } from '../../../../../web/components/LoadingSpinner';
import { TransparentButton } from '../../../../components/TransparentButton';
import { useGetScorecardTemplatesQuery } from '../../../../queries/ScorecardTemplate';
import { EditScorecardTemplateModal } from '../EditScorecardTemplateModal';
import { styleSheet } from './styles';

export const ScorecardTemplatesTable = () => {
	const [scorecardTemplateToEdit, setScorecardTemplateToEdit] = React.useState<Api.IScorecardTemplate>(null);
	const scorecardTemplatesQuery = useGetScorecardTemplatesQuery({});

	const loadMore = () => {
		// TODO: Load more
	};

	const isLoading = false;

	const edit = (scorecardTemplate: Api.IScorecardTemplate) => {
		setScorecardTemplateToEdit(scorecardTemplate);
	};

	// @ts-ignore
	const scorecardTemplates: Api.IScorecardTemplate[] = scorecardTemplatesQuery.data?.values ?? [];

	return (
		<>
			<table className={css(styleSheet.container)}>
				<thead>
					<tr className={css(styleSheet.headerContainer)}>
						<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Vertical</th>
						<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Enabled</th>
						<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Persona</th>
						<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>LeadStage</th>
						<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{scorecardTemplates.map(scorecardTemplate => (
						<tr className={css(styleSheet.rowContainer)} key={scorecardTemplate.creationDate}>
							<td className={css(styleSheet.rowData, styleSheet.rowDataInteractive, styleSheet.columnThin)}>
								{scorecardTemplate.vertical}
							</td>
							<td className={css(styleSheet.rowData, styleSheet.columnThin)}>
								{scorecardTemplate.enabled ? 'True' : 'False'}
							</td>
							<td className={css(styleSheet.rowData, styleSheet.columnThin)}>{scorecardTemplate.persona}</td>
							<td className={css(styleSheet.rowData, styleSheet.columnThin)}>{scorecardTemplate.leadStage}</td>
							<td className={css(styleSheet.rowData, styleSheet.columnThin)}>
								<div className={css(styleSheet.actionsColumn)}>
									<TransparentButton onClick={() => edit(scorecardTemplate)}>Edit</TransparentButton>
								</div>
							</td>
						</tr>
					))}
					<tr>
						{scorecardTemplates.length > 0 && (
							<td>
								<Waypoint bottomOffset='-200px' onEnter={loadMore} />
							</td>
						)}
					</tr>
					<tr>{isLoading && <LoadingSpinner />}</tr>
				</tbody>
			</table>

			{scorecardTemplateToEdit ? (
				<EditScorecardTemplateModal
					scorecardTemplate={scorecardTemplateToEdit}
					onCancel={() => setScorecardTemplateToEdit(null)}
				/>
			) : null}
		</>
	);
};
