import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import FlipMove from 'react-flip-move';
import { useHistory } from 'react-router';
import Waypoint from 'react-waypoint';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { ISelectOption } from '../../../../web/components/Select';
import { Toggle } from '../../../../web/components/Toggle';
import blitzPlayLightning1 from '../../../assets/blitzPlayLightning1.png';
import blitzPlayLightning2 from '../../../assets/blitzPlayLightning2.png';
import { LeadRule } from '../../../components/LeadRule';
import { LeadRuleModal } from '../../../components/LeadRuleModal';
import { LeadRuleScheduleModal } from '../../../components/LeadRuleScheduleModal';
import { Fill } from '../../../components/leadView/components/Fill';
import { Group } from '../../../components/leadView/components/Group';
import { useResponseHandler } from '../../../hooks/error';
import { grayIconFill } from '../../../styles/colors';
import { FieldKey } from '../../../viewModels/form';
import { LeadRuleViewModel, LeadRulesViewModel } from '../../../viewModels/leadrules';
import { BasicPage } from '../../BasicPage';
import { BlitzesSearch } from './components/BlitzesSearch';
import { VerticalSelect } from './components/VerticalSelect';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

export const Blitzes: React.FC<IProps> = observer(({ className = '' }) => {
	const [loadLeadsError] = useResponseHandler('LoadLeadRules', "Oh no! I couldn't load the Blitzes.");
	const [deleteLeadError] = useResponseHandler('SaveLeadRule', "Oh no! I couldn't delete this Blitz.");
	const userSession = useUserSession();
	const leadRules = useRef(new LeadRulesViewModel(userSession)).current;

	const [leadRuleToEdit, setLeadRuleToEdit] = useState<LeadRuleViewModel>(null);

	const [leadRuleToEditSchedule, setLeadRuleToEditSchedule] = useState<LeadRuleViewModel>(null);

	const [isNewRule, setIsNewRule] = useState(false);

	const [showInactive, setShowInactive] = useState(false);

	const [selectedVertical, setVertical] = useState<FieldKey>(FieldKey.Unknown);

	const history = useHistory();

	const load = () => {
		leadRules
			.load()
			.then(() => leadRules.sort())
			.catch(loadLeadsError);
		leadRules.sort();
	};

	useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseModal = (result?: LeadRuleViewModel) => {
		if (result) {
			result.load().catch(loadLeadsError);
		}

		setLeadRuleToEdit(null);

		setLeadRuleToEditSchedule(null);
		setIsNewRule(false);
		leadRules.sort();
	};

	const handleDeleteRule = (lead: LeadRuleViewModel) => () => {
		lead
			.delete()
			.then(() => {
				leadRules.rules.fetchResults.removeItems([lead]);
				leadRules.sort();
			})
			.catch(deleteLeadError);
	};

	const handleEditRule = (lead: LeadRuleViewModel) => () => {
		setLeadRuleToEdit(lead);
	};

	const handleEditScheduleRule = (lead: LeadRuleViewModel) => () => {
		setLeadRuleToEditSchedule(lead);
	};

	const handleCloneRule = (lead: LeadRuleViewModel) => () => {
		setIsNewRule(true);
		setLeadRuleToEdit(lead);
	};

	const handleNewRule = (lead: LeadRuleViewModel) => {
		setIsNewRule(true);
		setLeadRuleToEdit(lead);
	};

	const handleToggle = () => {
		setShowInactive(!showInactive);
	};

	const handleSelect = (selected: ISelectOption<FieldKey>) => {
		setVertical(selected.dataContext);
	};

	const rules = leadRules?.rules?.fetchResults;

	const listOfUniqueVerticals = rules
		.filter(item => item?.verticals?.length > 0)
		.map(item => [...(item?.verticals ?? [])])
		.reduce((acc, item) => acc.concat(item), []);

	const verticalOptions: ISelectOption[] = listOfUniqueVerticals
		.filter((item, index) => listOfUniqueVerticals.indexOf?.(item) === index)
		.map(item => ({
			dataContext: item,
			id: item,
			text: item,
		}));

	verticalOptions.unshift({
		dataContext: FieldKey.Unknown,
		id: 'all',
		text: 'All Lead Pools',
	});

	const filterRulesByVertical = (rule: LeadRuleViewModel) => {
		if (selectedVertical === FieldKey.Unknown) {
			return rule;
		}
		if (rule.verticals.indexOf(selectedVertical) !== -1) {
			return rule;
		}
	};

	const renderWaypoint = <Waypoint bottomOffset='-200px' onEnter={load} />;

	const isAllowedToSelectRule = userSession?.account?.features?.aida?.allowUsersToSelectRule;

	const handleGotoStartABlitz = () => {
		history.push('/settings/startABlitz');
	};

	return (
		<BasicPage className={`${className}`} title='blitzes'>
			<BlitzesSearch leadRules={leadRules} onReadyToCreate={handleNewRule} />

			{isAllowedToSelectRule && (
				<div className={css(styleSheet.startABlitzContainer)}>
					<button className={css(styleSheet.startABlitzButton)} onClick={handleGotoStartABlitz}>
						<img className={css(styleSheet.blitzPlayLightning1)} src={blitzPlayLightning1} alt='Blitz Play Lightning' />
						<img className={css(styleSheet.blitzPlayLightning2)} src={blitzPlayLightning2} alt='Blitz Play Lightning' />
						Start a Blitz
					</button>
				</div>
			)}

			<div className={css(styleSheet.leadRulesContainer)}>
				<div className={css(styleSheet.listHeader)}>
					<Group>
						<VerticalSelect options={verticalOptions} disabled={verticalOptions?.length <= 1} onChange={handleSelect} />
						<Fill />
						<Toggle
							id='show-inactive-lead-rules'
							text='Show Inactive'
							isOn={showInactive}
							onToggleCheckChanged={handleToggle}
							className={css(styleSheet.toggle)}
							uncheckedColor={grayIconFill}
						/>
					</Group>
				</div>
				<FlipMove
					enterAnimation={false}
					leaveAnimation={false}
					staggerDelayBy={50}
					staggerDurationBy={10}
					appearAnimation={true}
				>
					{rules.filter(filterRulesByVertical).map(rule => {
						if (!showInactive && !rule.isEnabled) {
							return null;
						}
						return (
							<div key={rule.id}>
								<LeadRule
									isReadonly={rule.type !== 'CustomLeadRule'}
									leadRules={leadRules}
									leadRule={rule}
									onClickToClone={handleCloneRule(rule)}
									onClickToEdit={handleEditRule(rule)}
									onClickToEditSchedule={handleEditScheduleRule(rule)}
									onClickToDelete={handleDeleteRule(rule)}
								/>
							</div>
						);
					})}
				</FlipMove>
				{rules?.length > 0 && renderWaypoint}
			</div>
			<LeadRuleModal
				isNewRule={isNewRule}
				leadRule={leadRuleToEdit}
				leadRules={leadRules}
				onRequestClose={handleCloseModal}
			/>
			<LeadRuleScheduleModal
				leadRule={leadRuleToEditSchedule}
				leadRules={leadRules}
				onRequestClose={handleCloseModal}
			/>
		</BasicPage>
	);
});
