import * as Api from '@ViewModels';
import { config, useSpring, useSprings } from '@react-spring/web';
import * as React from 'react';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages } from '../../hooks';
import { ISlotMachineReel } from '../../models/slotMachines';
import { SlotMachineViewModel } from '../../viewModels/slotMachines';
import { useSlotMachineModalContext } from './SlotMachineModalProvider/context';

const useReelAnimations = (reel: ISlotMachineReel) => {
	return useSprings(reel.symbols.length, i => {
		return {
			blur: 0,
			rotation: i * (360 / reel.symbols.length),
		};
	});
};

export const useSlots = (machine: SlotMachineViewModel, evenLoggingCategory = 'useSlots') => {
	const { logApiError } = useEventLogging(evenLoggingCategory);
	const errorMessages = useErrorMessages();
	const reelAnimations1 = useReelAnimations(machine.reels[0]);
	const reelAnimations2 = useReelAnimations(machine.reels[1]);
	const reelAnimations3 = useReelAnimations(machine.reels[2]);

	const [outcome, setOutcome] = React.useState<Api.ISlotMachineSpin>(null);
	const [loadingSpin, setLoadingSpin] = React.useState<boolean>(false);

	const spin = React.useCallback(
		async (gameOutcome?: Api.ISlotMachineSpin) => {
			const springs = [reelAnimations1, reelAnimations2, reelAnimations3];
			const spinningReelsPromises = Array.from<Promise<any>>({ length: springs.length });
			springs.forEach(async (reelSpring, index) => {
				const [itemProps, animationController] = reelSpring;
				const selectedIndex = itemProps.findIndex(x => x.rotation.goal % 360 === 0);

				let rotationToAdd = 0;
				if (gameOutcome) {
					const targetSymbol = gameOutcome.spinResult[index];
					const targetIndex = machine.reels[index].symbols.findIndex(x => x.value === targetSymbol);
					rotationToAdd = 360 - (targetIndex - selectedIndex) * (360 / itemProps.length);
				} else {
					rotationToAdd = Math.floor(Math.random() * itemProps.length) * (360 / itemProps.length);
				}

				const goals: number[] = [];
				spinningReelsPromises.push(
					new Promise(r => {
						animationController.start((i: number) => {
							const currentProps = itemProps[i];
							goals.push(currentProps.rotation.goal);
							return {
								blur: 1,
								config: {
									...config.wobbly,
									duration: 2000 + 500 * index,
									friction: 23,
								},
								from: { blur: 0 },
								onResolve: () => r(null),
								rotation: currentProps.rotation.goal + 360 * (index + 2) + rotationToAdd,
							};
						});
					})
				);

				await new Promise<void>(r => {
					setTimeout(() => {
						r();
					}, index * 1000);
				});
			});

			const result: Api.ISlotMachineSpin = gameOutcome || {
				gameId: machine.game.id,
				gameOutcomeResultType: Api.GameOutcomeResultType.Loss,
				spinResult: [],
			};
			if (!gameOutcome) {
				springs.forEach(async (reelSpring, index) => {
					const [itemProps] = reelSpring;
					const targetIndex = itemProps.findIndex(props => props.rotation.goal % 360 === 0);
					const target = machine.reels[index].symbols[targetIndex];
					result.spinResult.push(target.value);
				});
				result.gameOutcomeResultType =
					new Set(result.spinResult).size === 1 ? Api.GameOutcomeResultType.Win : Api.GameOutcomeResultType.Loss;
			}
			setOutcome(result);
			// wait for animations to settle
			await Promise.all(spinningReelsPromises);
			return result;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reelAnimations1, reelAnimations2, reelAnimations3]
	);
	const spinRef = React.useRef(spin);
	spinRef.current = spin;

	// #region handle
	const [handleProps, handleAnimationController] = useSpring(() => {
		return {
			config: { ...config.stiff },
			rotation: 0,
		};
	});

	const onHandleClick = React.useCallback(async () => {
		try {
			setLoadingSpin(true);
			const opResult = await machine.spin();
			handleAnimationController.start(() => {
				return {
					config: { ...config.stiff, mass: 0.5, tension: 300 },
					to: [{ rotation: 200 }, { rotation: 0 }],
				};
			});

			const result = await spinRef.current?.(opResult.value.gameOutcome);
			return result;
		} catch (error) {
			logApiError('Spin-Error', error);

			errorMessages.pushApiError(error);
		} finally {
			setLoadingSpin(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleAnimationController, machine]);
	// #endregion
	return {
		handleProps,
		loadingSpin,
		onHandleClick,
		outcome,
		reelItemProps: [reelAnimations1[0], reelAnimations2[0], reelAnimations3[0]],
	} as const;
};

export const useSlotMachineEndGameModal = (slotMachine: SlotMachineViewModel) => {
	const { onRequestClose: onRequestGameOver } = useSlotMachineModalContext();
	const endGameModalTimeoutRef = React.useRef<any>(null);
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	React.useEffect(() => {
		return () => {
			if (endGameModalTimeoutRef.current) {
				clearTimeout(endGameModalTimeoutRef.current);
				endGameModalTimeoutRef.current = null;
			}
		};
	}, [slotMachine]);

	const showEndGameModalWithTimeout = React.useCallback(
		async timeInMs => {
			endGameModalTimeoutRef.current = setTimeout(() => {
				if (slotMachine?.spinOutcome) {
					setIsOpen(true);
				}
			}, timeInMs);
		},
		[slotMachine]
	);

	const onRequestClose = React.useCallback(() => {
		setIsOpen(false);
		if ((slotMachine?.spinsRemaining?.length || 0) <= 0) {
			onRequestGameOver();
		}
	}, [onRequestGameOver, slotMachine]);

	return { isOpen, onRequestClose, showEndGameModalWithTimeout, slotMachine } as const;
};
