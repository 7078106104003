import { IUser, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getDisplayName } from '../../../../../models/UiUtils';
import { ViewModelTypeAutocomplete } from '../../../../containers/Settings/PhoneNumbers/components/ViewModelTypeAutocomplete';
import { styleSheet } from '../styles';

interface IProps {
	user?: IUser;
	onChange: (user: IUser) => void;
}

export const UserSelect: React.FC<IProps> = ({ user: initialUser, onChange }) => {
	// this is to allow transition from old views where the parent doesn't control the state
	// to new views (call log) where the parent controls the selection/state
	// keep internal state but allow parent to control it

	const [selectedItem, selectItem] = useState<IUser>(initialUser);
	useEffect(() => {
		selectItem(initialUser);
	}, [initialUser]);
	const handleSelection = (value: IUser) => {
		onChange(value);
		selectItem(value);
	};
	return (
		<ViewModelTypeAutocomplete<IUser>
			className={css(styleSheet.autocomplete)}
			defaultSelected={selectedItem}
			model={ResourceAutoCompleteViewModelType.User}
			onChange={handleSelection}
			placeholder={selectedItem ? 'SDR: ' + getDisplayName(selectedItem) : 'Filter by SDR'}
		/>
	);
};
