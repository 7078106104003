import { css } from 'aphrodite';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ViewportContext } from '../../hooks/useViewport';
import { styleSheet } from './styles';

export interface ITab {
	content?: string | JSX.Element;
	label?: string | JSX.Element;
	name: string;
	renderContent?: (selectedTab: string) => string | JSX.Element;
	renderLabel?: (selectedTab: string) => string | JSX.Element;
}

interface IProps {
	className?: string;
	defaultTab?: string;
	onChange?: (selectedTab: string) => void;
	selectedTab?: string;
	tabs: ITab[];
}

export const Tabs: React.FC<IProps> = ({ className, defaultTab, onChange, selectedTab, tabs }) => {
	const [currentTab, setTab] = useState<string>(defaultTab);

	useEffect(() => {
		if (selectedTab) {
			setTab(selectedTab);
		}
	}, [selectedTab]);

	const { isBelowSmall } = useContext(ViewportContext);

	const handleTabs = (newTab: string) => () => {
		if (!selectedTab) {
			setTab(newTab);
		}
		onChange?.(newTab);
	};

	const renderHeaders = tabs.map(tab => {
		return (
			<div
				key={tab.name}
				className={css(styleSheet.tabsTab, currentTab === tab.name && styleSheet.tabsTabActive)}
				onClick={handleTabs(tab.name)}
			>
				{tab.renderLabel ? tab.renderLabel(currentTab) : tab.label}
			</div>
		);
	});

	const renderContent = tabs.map(tab => {
		if (currentTab !== tab.name) {
			return null;
		}
		return (
			<div key={tab.name} className={css(styleSheet.tabsContent)}>
				{tab.renderContent ? tab.renderContent(currentTab) : tab.content}
			</div>
		);
	});

	const containerMobile = isBelowSmall ? styleSheet.tabsContainerMobile : null;
	const tabsTabsMobile = isBelowSmall ? styleSheet.tabsTabsMobile : null;

	return (
		<div className={`${css(styleSheet.tabsContainer, containerMobile)} ${className || null}`}>
			<div className={`${css(styleSheet.tabsTabs, tabsTabsMobile)}`}>{renderHeaders}</div>
			{renderContent}
		</div>
	);
};
