import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import FlipMove from 'react-flip-move';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { TextInput } from '../../../web/components/TextInput';
import { SearchIcon } from '../../../web/components/svgs/icons/SearchIcon';
import { XIcon } from '../../../web/components/svgs/icons/XIcon';
import PlaceholderImageSource from '../../assets/call-from-unknown-placeholder.svg';
import { ISearchPayload, SearchKeyOptions } from '../../contexts/search';
import { useErrorMessages } from '../../hooks';
import { grayIconFill, white } from '../../styles/colors';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { LeadSearchResultsViewModel } from '../../viewModels/leads/leads';
import { LeadServedSource } from '../../viewModels/queue';
import { BlueModal } from '../BlueModal';
import { LeadCard } from '../LeadCard';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	initialQuery?: string;
	isOpen?: boolean;
	onRequestClose?: (result: any, canceled: boolean) => void;
	payload: ISearchPayload;
}

export const SearchModalBase: React.FC<IProps> = ({
	className = '',
	initialQuery = '',
	isOpen,
	onRequestClose,
	payload,
}) => {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const searchVm = useRef(new LeadSearchResultsViewModel(userSession)).current;
	const [searchValue, setSearchValue] = useState(initialQuery);

	const [timeout, setTimeout] = useState<number>(null);

	const searchKey = payload?.key;

	const isCallFromUnknown = searchKey === SearchKeyOptions.CallFromUnknown;

	useEffect(() => {
		setSearchValue(initialQuery);
	}, [initialQuery]);

	useEffect(() => {
		if (!isOpen) {
			setSearchValue('');
			searchVm.reset();
			window.clearTimeout(timeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	useEffect(() => {
		window.clearTimeout(timeout);

		if (searchValue) {
			setTimeout(
				window.setTimeout(() => {
					searchVm
						.search(searchValue)
						.catch(err => {
							errorMessages.pushApiError(err);
						})
						.finally(() => {
							setTimeout(null);
						});
				}, 400)
			);
		} else {
			setTimeout(null);
		}

		return () => {
			window.clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	const onCloseClick = () => {
		onRequestClose(null, true);
	};

	const onInputRef = (ref: HTMLInputElement) => {
		if (ref) {
			ref.focus();
		}
	};

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const renderCallFromUnknownPlaceholder = (
		<div className={css(styleSheet.placeholder)}>
			<img className={css(styleSheet.placeholderImage)} src={PlaceholderImageSource} />
		</div>
	);

	const renderCallFromUnknownTitle = (
		<div className={css(styleSheet.title, styleSheet.smallTitle)}>
			We do not recognize the incoming call number, but you can search for an existing lead
		</div>
	);

	const renderHeader = () => {
		return (
			<div className={css(styleSheet.header)}>
				<TransparentButton className={css(styleSheet.close)} onClick={onCloseClick}>
					<XIcon fillColor={white} height={12} width={12} />
				</TransparentButton>
				{isCallFromUnknown ? renderCallFromUnknownTitle : <div className={css(styleSheet.title)}>Search</div>}
			</div>
		);
	};

	const renderResults = () => {
		if (!searchValue) {
			if (isCallFromUnknown) {
				return renderCallFromUnknownPlaceholder;
			}
			return null;
		}

		if (!searchVm.leads.length) {
			return <div className={css(styleSheet.noResults)}>{`No results for "${searchValue}"`}</div>;
		}

		return (
			<FlipMove
				enterAnimation={false}
				leaveAnimation={false}
				staggerDelayBy={50}
				staggerDurationBy={10}
				appearAnimation={true}
			>
				{searchVm.leads.map(lead => (
					<div className={css(styleSheet.leadCard)} key={lead.company.id}>
						<LeadCard source={LeadServedSource.Search} lead={lead} />
					</div>
				))}
			</FlipMove>
		);
	};

	return (
		<div className={`${css(styleSheet.searchModalContainer)} ${className}`}>
			<BlueModal
				className={css(styleSheet.searchModal)}
				headerAccessory={renderHeader()}
				isOpen={isOpen}
				onRequestClose={onRequestClose}
			>
				<div>
					<TextInput
						className={css(styleSheet.searchInput)}
						inputId='global-search-input'
						leftAccessory={<SearchIcon fillColor={grayIconFill} />}
						onChange={onSearch}
						inputRef={onInputRef}
						placeholder='Search for anything... Use "some quotes" for exact matches and -name to exclude results that include "name"'
						type='text'
						value={`${searchValue}`}
					/>
				</div>
				<div className={css(styleSheet.resultsContainer)}>
					{searchVm.isBusy || !!timeout ? (
						<LoadingSpinner className={css(aidaBaseStyleSheet.absoluteCenter)} type='large' />
					) : (
						renderResults()
					)}
				</div>
			</BlueModal>
		</div>
	);
};

export const SearchModal = observer(SearchModalBase);
