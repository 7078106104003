import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Waypoint from 'react-waypoint';
import { IDictionary, IUser } from '../../../extViewmodels';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../models/AppState';
import { getDisplayName } from '../../../models/UiUtils';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { LeadCard } from '../../components/LeadCard';
import { LeadView } from '../../components/leadView/LeadView';
import { LeadViewType } from '../../components/leadView/context';
import { FieldKey } from '../../viewModels/form';
import { LeadServedSource } from '../../viewModels/queue';
import { FollowUpsViewModel } from '../../viewModels/recentcalls';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps {
	className?: string;
}

const FollowUpsContainerBase: React.FC<IProps> = props => {
	const { userSession } = props;
	const history = useHistory();

	const followUpsVm = useRef(new FollowUpsViewModel(userSession)).current;

	const [user, setUser] = useState<IUser>(null);

	const [outcome, setOutcome] = useState<FieldKey>(null);

	const [viewType, setViewType] = useState<LeadViewType>(null);

	const loadMore = () => {
		const params: IDictionary = {};
		if (user) {
			params.userId = user.id;
		}

		if (outcome && outcome !== FieldKey.Unknown) {
			params.outcomeName = outcome;
		}

		followUpsVm.controller.getNext(null, 25, params);
	};

	useEffect(() => {
		// reset and reload the follow ups whenever the parameters change
		followUpsVm.controller.reset();
		loadMore();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, outcome]);

	const onRowClick = (companyId: string) => () => {
		if (companyId) {
			history.push({
				pathname: '/queue',
				search: `?from=${LeadServedSource.FollowUp}&companyId=${companyId}`,
			});
		}
	};

	const renderResults = () => {
		// if loading
		if (followUpsVm.controller.isFetching && !followUpsVm.controller.hasFetchedFirstPage) {
			return <LoadingSpinner type='login' />;
		}

		if (!followUpsVm.controller.fetchResults.length) {
			return <div className={css(styleSheet.noResults)}>No follow ups</div>;
		}

		if (viewType === LeadViewType.Card) {
			return followUpsVm.controller.fetchResults.map(followUp => {
				return (
					<div className={css(styleSheet.leadCard)} key={followUp.followUp.company.id}>
						<LeadCard source={LeadServedSource.FollowUp} lead={followUp.followUp} />
					</div>
				);
			});
		}

		return followUpsVm.controller.fetchResults.map(followUp => {
			const leadView = followUp.followUp;
			return (
				<LeadView.RowView key={leadView.company?.id} onClick={onRowClick(leadView.company?.id)}>
					<LeadView.RowElement width={340} displayType='FollowUp' value={leadView.followUp} />

					<LeadView.RowElement width={240} displayType='CompanyInfo' value={leadView.company} />

					<LeadView.RowElement width={200} displayType='Text' value={leadView.followUp?.name} />

					<LeadView.RowElement width={200} displayType='Text' value={getDisplayName(leadView.followUp?.creator)} />

					<LeadView.RowElement width={200} displayType='Interaction' value={leadView.lastInteraction} />

					<LeadView.RowElement width={80} displayType='Recording' value={leadView.interactionPhoneCall} />
				</LeadView.RowView>
			);
		});
	};

	const onUserSelected = (newUser: IUser) => setUser(newUser ?? null);
	const onOutcomeSelected = (newOutcome: FieldKey) => setOutcome(newOutcome);
	const onViewSelected = (newViewType: LeadViewType) => setViewType(newViewType);

	return (
		<LeadView onOutcomeSelected={onOutcomeSelected} onUserSelected={onUserSelected} onViewSelected={onViewSelected}>
			<LeadView.Title title='Follow Ups' />
			<LeadView.ViewSelector />
			<LeadView.Filters />
			<LeadView.TableHeader
				columns={[
					{ label: 'follow up', width: 340 },
					{ label: 'company info', width: 240 },
					{ label: 'name/description', width: 200 },
					{ label: 'sdr', width: 200 },
					{ label: 'last call', width: 200 },
					{ label: 'recording', width: 80 },
				]}
			/>
			<LeadView.TableContainer>
				{renderResults()}
				<Waypoint bottomOffset='-200px' onEnter={loadMore} />
			</LeadView.TableContainer>
		</LeadView>
	);
};

const FollowUpsContainerObserver = observer(FollowUpsContainerBase);
export const FollowUpsContainer = inject(UserSessionViewModelKey)(FollowUpsContainerObserver);
