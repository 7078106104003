import { css } from 'aphrodite';
import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ViewportContext } from '../../hooks/useViewport';
import { CoffeeIcon } from '../svgs/CoffeeIcon';
import { CoffeeLogo } from '../svgs/CoffeeLogo';
import { styleSheet } from './styles';

interface IProps {
	linkTo?: string;
}

export const Logo: React.FC<IProps> = ({ linkTo }) => {
	const { isBelowSmall } = useContext(ViewportContext);

	const defaultLinkTo = '/queue';
	const linkCallback = linkTo === null || !!linkTo ? linkTo : defaultLinkTo;

	const renderLogo = isBelowSmall ? <CoffeeIcon /> : <CoffeeLogo />;
	const logoContainerMobile = isBelowSmall ? styleSheet.logoContainerMobile : null;

	return (
		<div className={css(styleSheet.logoContainer, logoContainerMobile)}>
			{linkCallback ? (
				<Link to={linkCallback}>
					<CoffeeLogo />
				</Link>
			) : (
				renderLogo
			)}
		</div>
	);
};
