import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { parse } from 'query-string';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Noop } from '../../../extViewmodels/Utils';
import { copyToClipboard } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useLambda } from '../../../models/hooks/useLambda';
import { useModal } from '../../../models/hooks/useModal';
import { FormFieldType, IContact, IOperationResultNoValue, IPhoneNumber } from '../../../viewmodels/AppViewModels';
import { Button } from '../../../web/components/Button';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { TextInput } from '../../../web/components/TextInput';
import { useDealModal } from '../../contexts/dealModal';
import { useQueueLeadNote } from '../../contexts/queueLeadNote';
import { useTelephony } from '../../contexts/telephony';
import { useErrorMessages, useToaster } from '../../hooks';
import { useQueue } from '../../hooks/queue';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { FieldKey } from '../../viewModels/form';
import { LeadAction } from '../../viewModels/leads/interfaces';
import { LeadServedSource } from '../../viewModels/queue';
import { AddPhoneAction } from '../AddPhoneAction';
import { CallActions } from '../CallActions';
import { ClientsNearMe } from '../ClientsNearMe';
import { ContactCards } from '../ContactCards';
import { PhoneNumbersRepeater } from '../ContactInfo/components/PhoneNumbersRepeater';
import { DealModal } from '../DealModal';
import { ExecutiveSummary } from '../ExecutiveSummary';
import { ExternalLink } from '../ExternalLink';
import { IFabMenuItem } from '../Fab';
import { LastDeal } from '../LastDeal';
import { LeadAttributes } from '../LeadAttributes';
import { LeadStatuses } from '../LeadStatuses';
import { MeetingConfirmationDisplay } from '../MeetingConfirmationDisplay';
import { RemoveLeadCheckbox } from '../RemoveLeadCheckbox';
import { SocialProfiles } from '../SocialProfiles';
import { Timeline } from '../Timeline';
import { TransparentButton } from '../TransparentButton';
import { CurrentFollowUp } from '../followup/CurrentFollowUp';
import { FollowUpModal, IFollowUpCallbackArgs } from '../followup/FollowUpModal';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

interface IEditableData {
	companyName?: string;
	phoneNumbers?: IPhoneNumber[];
	webSite?: string;
}

export interface IContactModalProps {
	contact: IContact;
	show: boolean;
}

const emptyPhone = { label: 'Work', metadata: { e164: 'new' }, value: '' };

export const CallingCardBase: React.FC<IProps> = ({ className = '' }) => {
	const userSession = useUserSession();
	const { phoneCalls, isPendingOutcome } = useTelephony();
	const queue = useQueue();
	const dealModal = useDealModal();
	const history = useHistory();
	const { setShowQueueLeadNote } = useQueueLeadNote();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const [isEditingCompanyInfo, setIsEditingCompanyInfo, setIsEditingCompanyInfoLamdba] = useLambda(false);
	const [editableData, setEditableData] = useState<IEditableData>({});
	const [isAddingPhoneNumber, setIsAddingNewPhoneNumber] = useState(false);
	const [phoneNumberEditableInNonEditMode, setPhoneNumberEditableInNonEditMode] = useState<IPhoneNumber>(emptyPhone);

	const followUpModal = useModal(false, Noop, [], true, false);

	const providerName = queue.lead?.providerName;

	useEffect(() => {
		queue?.lead?.getContacts().catch(err => {
			errorMessages.pushApiError(err);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.companyId]);

	useEffect(() => {
		if (isEditingCompanyInfo) {
			setEditableData({
				companyName: queue.lead?.company.companyName,
				phoneNumbers: queue.lead?.company.phoneNumbers || [],
				webSite: queue.lead?.company.webSite,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditingCompanyInfo]);

	const { setTelephonyConfiguration } = useTelephony();
	useEffect(() => {
		setTelephonyConfiguration(queue?.lead?.suggestedTelephonyConfiguration || userSession.telephonyConfiguration);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue?.lead?.suggestedTelephonyConfiguration]);

	const getActionButtonStyles = () => {
		const styles = [styleSheet.actionButton];

		if (!isPendingOutcome && (queue.hasFulfilledRequirentsToMoveToNextLead || queue.skipReason)) {
			styles.push(styleSheet.nextButton);

			if (!queue.isDirectAccess && !isPendingOutcome) {
				// for no good reason but since we have 3 states
				styles.push(styleSheet.pulse);
			}
		} else {
			// looks like this means disabled (but it was called skip)
			styles.push(styleSheet.skipButton);
		}

		return styles;
	};

	const onCompanyNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setEditableData({
				...editableData,
				companyName: e.target.value,
			});
		},
		[editableData]
	);

	const onCompanyWebsiteChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setEditableData({
				...editableData,
				webSite: e.target.value,
			});
		},
		[editableData]
	);

	const onFollowUpMenuEditClicked = () => followUpModal.setIsOpen(true)();

	const onEditCompanyCancelClick = () => {
		setEditableData({});
		setIsEditingCompanyInfo(false);
	};

	const onEditCompanySaveClick = () => {
		const updatedLead = {
			company: { ...queue.lead.company.toJs(), ...editableData },
		};

		updatedLead.company.phoneNumbers = updatedLead.company.phoneNumbers.filter(x => !!x);

		queue.lead
			.update(updatedLead)
			.then(() => {
				toaster.push({
					message: `${queue.lead.company.companyName} was updated successfully.`,
					type: 'successMessage',
				});
			})
			.catch(err => {
				errorMessages.pushApiError(err);
			});

		setIsEditingCompanyInfo(false);
	};

	const onFabMenuItemClick = useCallback(
		(menuItem: IFabMenuItem) => {
			switch (menuItem.dataContext) {
				case 'AddNote':
					queue.setAction(LeadAction.AddNote);
					setShowQueueLeadNote(true);
					break;
				case 'CreateDeal':
					queue.lead?.createDealForm();
					dealModal.setShowDealModal(true);
					break;
				case 'UpdateDeal':
					queue.lead?.loadDealForm(queue.lead?.lastDeal?.id);
					dealModal.setShowDealModal(true);
					break;
				case 'SetFollowUp':
					followUpModal.setIsOpen(true)();
					break;
				default:
					break;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[queue]
	);

	const onProviderClick = () => {
		const providerUrl = queue.lead?.providerUrl;
		if (providerUrl) {
			window.open(providerUrl, '_blank');
		}
	};

	const onCopyLeadClick = () => {
		const url = window.location.origin + `/#/queue?from=${LeadServedSource.SharedLink}&companyId=${queue.companyId}`;
		const success = copyToClipboard(url);

		toaster.push({
			message: success ? 'Copied link to clipboard' : 'Error copying to clipboard',
			type: success ? 'successMessage' : 'errorMessage',
		});
	};

	const onGoogleClick = () => {
		const { company } = queue.lead;
		const q: string[] = [];

		if (company.companyName) {
			q.push(company.companyName.replace('&', ' ').split(' ').join('+'));
		}

		if (company?.address?.city) {
			q.push(company.address.city);
		}

		if (company?.address?.stateProvince) {
			q.push(company.address.stateProvince);
		}

		const url = encodeURI(`https://google.com/search?q=${q.join('+')}`);
		window.open(url, '_blank');
	};

	const proceedToNextLead = async () => {
		if (!queue.skipReasonSaved && queue.skipReason) {
			try {
				await queue.saveSkipReason(queue.lead.selectedFollowUp, phoneCalls.currentCall?.id);
			} catch (err) {
				errorMessages.pushApiError(err);
				return;
			}
		}

		const search = parse(history.location.search);

		history.push(`/queue${search?.ruleId ? `?ruleId=${search?.ruleId}` : ''}`);
		queue.getNextLead({ ruleId: search?.ruleId }).catch(err => {
			errorMessages.pushApiError(err);
		});

		phoneCalls.softReset();
	};

	/**
	 * It is important to iterate over the conditions in `canProceedToNextLead` here to tell the user why specifically
	 * they cannot move on
	 */
	const onNextClick = async () => {
		if (isPendingOutcome) {
			toaster.push({
				message: 'Save last call outcome before proceeding.',
				type: 'errorMessage',
			});
			return;
		}

		// if there is an open deal, they can always go to next lead
		if (queue.lead?.lastDealIsOpen || queue.isDirectAccess) {
			proceedToNextLead();
			return;
		}

		// if there is a call and an outcome, validate we have all we need for it
		if (phoneCalls.currentCall?.outcome) {
			// if you selected "booked", but not saved a deal, you cannot move on
			if (phoneCalls.currentCall?.outcome?.name === FieldKey.Booked && !queue.lead?.dealForm?.saved) {
				const field = queue.lead?.dealForm?.fields?.find(f => f.fieldType === FormFieldType.Note && !!f.note?.id);
				if (!field) {
					toaster.push({
						message: 'A deal must be created before you may proceed.',
						type: 'errorMessage',
					});
					return;
				}
			}

			// if you have not saved a note, and it is required, prompt the user
			if (phoneCalls.currentCall?.outcome?.requireNote && !queue.lead?.note?.id) {
				toaster.push({
					message: 'A note is required for this call outcome.',
					type: 'errorMessage',
				});

				queue.setAction(null);
				setShowQueueLeadNote(true);
				return;
			}
		}

		// if you've not yet made a phone call
		if (!queue.hasFulfilledRequirentsToMoveToNextLead && !queue.skipReason) {
			// TODO: based on the aida feature requirements to move to next lead, adjust the message
			// ...
			// warn user they must dial or pick a skip reason

			toaster.push({
				message: 'You must make a dial or pick a reason to skip this lead.',
				type: 'errorMessage',
			});
			return;
		}

		proceedToNextLead();
	};

	const onPhoneNumberAdded = useCallback(
		(phoneNumber: IPhoneNumber, showNewField = false) => {
			const updatedLead = { company: { ...queue.lead.company.toJs() } };

			updatedLead.company.phoneNumbers.push(phoneNumber);

			updatedLead.company.phoneNumbers = updatedLead.company.phoneNumbers.filter(x => !!x);
			setPhoneNumberEditableInNonEditMode(emptyPhone);

			queue.lead
				.update(updatedLead)
				.then(() => {
					toaster.push({
						message: `The phone number was added successfully.`,
						type: 'successMessage',
					});

					if (showNewField) {
						queue.lead.company.phoneNumbers.push(null);
					}

					setEditableData({
						...editableData,
						phoneNumbers: queue.lead.company?.phoneNumbers,
					});
				})
				.catch(err => {
					errorMessages.pushApiError(err);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[queue.lead, editableData]
	);

	const onPhoneNumberChanged = (phoneNumber: IPhoneNumber) => {
		setPhoneNumberEditableInNonEditMode(phoneNumber);
	};

	const onPhoneNumberDeleted = useCallback(
		(phoneNumber: IPhoneNumber) => {
			const updatedLead = { company: { ...queue.lead.company.toJs() } };

			updatedLead.company.phoneNumbers = updatedLead.company.phoneNumbers
				.filter(x => !!x)
				.filter(x => !(x.value === phoneNumber.value && x.label === phoneNumber.label));

			queue.lead
				.update(updatedLead)
				.then(() => {
					toaster.push({
						message: `The phone number was deleted successfully.`,
						type: 'successMessage',
					});

					setEditableData({
						...editableData,
						phoneNumbers: queue.lead.company?.phoneNumbers,
					});
				})
				.catch(err => {
					errorMessages.pushApiError(err);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[queue.lead, editableData]
	);

	const renderCompanyLocationInfo = () => {
		const city = queue.lead?.company?.address?.city ?? 'Unknown';
		const state = queue.lead?.company?.address?.stateProvince ?? 'Unknown';
		const timezone = queue.lead?.abbreviatedTimeZone;
		return `${city}, ${state} (${timezone})`;
	};

	const handlePhoneNumbers = (phoneNumbers: IPhoneNumber[]) => {
		setEditableData({
			...editableData,
			phoneNumbers,
		});
	};

	const renderEditingLeadInfo = () => {
		return (
			<div className={css(styleSheet.body, styleSheet.editing)}>
				<div className={css(styleSheet.companyInfoWrapper)}>
					<h1>Edit Company Details</h1>
				</div>
				<div className={css(styleSheet.fieldContainer)}>
					<label htmlFor='company-name'>Company Name</label>
					<TextInput
						inputId='company-name'
						onChange={onCompanyNameChange}
						type='text'
						value={`${editableData?.companyName ?? ''}`}
					/>
				</div>
				<div className={css(styleSheet.fieldContainer)}>
					<label htmlFor='company-name'>Website</label>
					<TextInput
						inputId='company-website'
						onChange={onCompanyWebsiteChange}
						type='text'
						value={`${editableData?.webSite ?? ''}`}
					/>
				</div>

				<PhoneNumbersRepeater values={editableData?.phoneNumbers} onChange={handlePhoneNumbers} />
				<div className={css([aidaBaseStyleSheet.flexHorizontalCenter, styleSheet.footer])}>
					<Button className='button' label='Save' onClick={onEditCompanySaveClick} />
					<Button className='button' label='Cancel' onClick={onEditCompanyCancelClick} size='normal' kind='reverse' />
				</div>
			</div>
		);
	};

	const renderLeadInfo = () => {
		let siteLink = queue.lead?.company?.webSite;
		if (!!siteLink && !siteLink.startsWith('http://') && !siteLink.startsWith('https://')) {
			siteLink = 'http://' + siteLink;
		}

		return (
			<>
				<div className={css(styleSheet.leftColHeader)}>
					<div>
						<LeadStatuses />
					</div>
					<div>
						<TransparentButton className={css(styleSheet.link)} onClick={setIsEditingCompanyInfoLamdba(true)}>
							Edit
						</TransparentButton>
					</div>
				</div>
				<div className={css(styleSheet.body)}>
					<div className={css(styleSheet.companyInfoWrapper)}>
						<div className={css(styleSheet.companyLinksContainer)}>
							<ExternalLink onClick={onGoogleClick} />
							{queue.lead?.providerUrl && <ExternalLink onClick={onProviderClick} name={providerName} />}
							<div onClick={onCopyLeadClick} className={css(styleSheet.companyLink)}>
								Copy Link to Lead
							</div>
						</div>
						<h1>{queue.lead?.company.companyName}</h1>
						{!!siteLink && (
							<a href={siteLink} target='_blank' title={siteLink} rel='noreferrer'>
								{siteLink}
							</a>
						)}
						<div className={css(styleSheet.location)}>{renderCompanyLocationInfo()}</div>
					</div>
					<div className={css(styleSheet.socialContainer)}>
						<SocialProfiles socialProfiles={queue.lead?.company.socialProfiles} />
						{userSession.account.isLevitateSalesCoffeeAccount && <ClientsNearMe />}
					</div>
					<MeetingConfirmationDisplay lead={queue.lead} />
					<div className={css(styleSheet.callActionContainer)}>
						<CallActions phoneNumbers={queue.lead?.company.phoneNumbers} />
						<div
							className={css(
								styleSheet.addAction,
								isAddingPhoneNumber ? styleSheet.addActionsContainerVertical : styleSheet.addActionsContainer
							)}
						>
							<AddPhoneAction
								editorClassName={css(styleSheet.addPhoneAction, isAddingPhoneNumber && styleSheet.addActionChild)}
								onAddingStatusChange={setIsAddingNewPhoneNumber}
								onPhoneNumberAdded={onPhoneNumberAdded}
								onPhoneNumberDeleted={onPhoneNumberDeleted}
								onPhoneNumberChanged={onPhoneNumberChanged}
								phoneNumber={phoneNumberEditableInNonEditMode}
							/>
						</div>
						<ContactCards />
					</div>
				</div>
			</>
		);
	};

	const onClickToConfirm = ({ importance, name, selectedDate }: IFollowUpCallbackArgs) => {
		queue
			.setFollowUp(selectedDate.toDate(), name, importance)
			.then(() => {
				followUpModal.setIsOpen(false)();

				toaster.push({
					message: `Follow up is updated to ${selectedDate.format('MM/DD/YYYY hh:mm A')}`,
					type: 'successMessage',
				});
			})
			.catch((err: IOperationResultNoValue) => {
				toaster.push({
					message: `Unable to set follow up: ${err.systemMessage}`,
					type: 'errorMessage',
				});
			});
	};

	return (
		<div className={`${css(styleSheet.callingCardContainer)} ${className}`}>
			<div className={css(styleSheet.leftCol)}>{isEditingCompanyInfo ? renderEditingLeadInfo() : renderLeadInfo()}</div>
			<div className={css(styleSheet.rightCol)}>
				<div className={css(styleSheet.rightColHeader)}>
					<div className={css(styleSheet.headerButtonsContainer)}>
						<div className={css(styleSheet.headerButtonsRightContainer)}>
							<RemoveLeadCheckbox />
							<button
								className={css(...getActionButtonStyles())}
								onClick={onNextClick}
								disabled={queue.isSavingSkipReason}
							>
								{queue.isSavingSkipReason ? <LoadingSpinner type='extra-small' /> : 'Next'}
							</button>
						</div>
					</div>
				</div>
				<div className={css(styleSheet.body)}>
					{queue.lead?.followUp ? <CurrentFollowUp followUp={queue.lead.followUp} /> : <div />}
					{queue.lead?.lastDealIsOpen || queue.lead?.previousDeals?.length ? (
						<LastDeal deal={queue.lead?.lastDeal} previousDeals={queue.lead?.previousDeals} />
					) : (
						<div />
					)}
					<div className={css(styleSheet.tagsContainer)}>
						<LeadAttributes />
					</div>
					<ExecutiveSummary />
					<div className={css(styleSheet.timelineContainer)}>
						{!!queue.lead?.company && (
							<Timeline
								lead={queue.lead}
								onFabMenuItemClick={onFabMenuItemClick}
								onFollowUpMenuEditClicked={onFollowUpMenuEditClicked}
							/>
						)}
					</div>
				</div>
			</div>
			<DealModal />
			<FollowUpModal {...followUpModal} onClickToConfirm={onClickToConfirm} />
		</div>
	);
};

export const CallingCard = observer(CallingCardBase);
