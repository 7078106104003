import { css } from 'aphrodite';
import { Provider, inject, observer } from 'mobx-react';
import { parse } from 'query-string';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { IErrorMessageComponentProps, IUserSessionComponentProps } from '../../../models/AppState';
import { Queue } from '../../components/Queue';
import { DealModalProvider } from '../../contexts/dealModal';
import { EmailEditorProvider } from '../../contexts/email';
import { QueueLeadNoteProvider } from '../../contexts/queueLeadNote';
import { ErrorMessagesViewModelKey, UserSessionViewModelKey } from '../../models/AppState';
import { QueueState, QueueViewModelKey } from '../../models/QueueState';
import { ICreateQueueParams, QueueViewModel } from '../../viewModels/queue';
import { styleSheet } from './styles';

interface IProps extends IErrorMessageComponentProps, IUserSessionComponentProps, RouteComponentProps {}

interface IState {
	isLoading?: boolean;
	currentSearch?: ICreateQueueParams;
}

class QueueContainerBase extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		const { history } = props;
		const search = parse(history.location.search) as ICreateQueueParams;
		this.state = {
			currentSearch: search,
			isLoading: true,
		};
		QueueState[QueueViewModelKey] = new QueueViewModel(props.userSession, search);
	}

	public componentDidUpdate() {
		const { currentSearch } = this.state;
		const { errorMessages, history } = this.props;
		const search = parse(history.location.search) as ICreateQueueParams;
		if (search?.companyId !== currentSearch?.companyId) {
			this.setState({ currentSearch: search }, () => {
				QueueState[QueueViewModelKey].setLead(search).catch(err => {
					errorMessages.pushApiError(err);
				});
			});
		}
	}

	public componentDidMount() {
		const { errorMessages } = this.props;
		const search = parse(this.props.history.location.search) as ICreateQueueParams;
		const queue = QueueState[QueueViewModelKey];

		if (!search?.companyId) {
			queue
				.getNextLead({ ruleId: search?.ruleId })
				.then(() => {
					this.setState({ isLoading: false });
				})
				.catch(err => {
					errorMessages.pushApiError(err);
				});
		} else {
			this.setState({ isLoading: false });
		}
	}

	public render() {
		return (
			<Provider {...QueueState}>
				{this.state.isLoading ? (
					<span style={{ display: 'none' }} />
				) : (
					<EmailEditorProvider>
						<DealModalProvider>
							<QueueLeadNoteProvider>
								<div className={css(styleSheet.container)}>
									<Queue />
								</div>
							</QueueLeadNoteProvider>
						</DealModalProvider>
					</EmailEditorProvider>
				)}
			</Provider>
		);
	}
}

const QueueContainerObserver = observer(QueueContainerBase);
const QueueContainerWithRouter = withRouter(QueueContainerObserver);
export const QueueContainer = inject(UserSessionViewModelKey, ErrorMessagesViewModelKey)(QueueContainerWithRouter);
