import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { IOperationResultNoValue } from '../../../extViewmodels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { QuickSightDashboardViewModel } from '../../../viewmodels/AdminViewModels';
import { brandSecondary } from '../../styles/colors';
import { LocationIcon } from '../svgs/icons/LocationIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	dashboardId?: string;
}

export const Report: React.FC<IProps> = ({ dashboardId, className = '' }) => {
	const userSession = useUserSession();

	const [error, setError] = useState<string>(undefined);

	const [url, setUrl] = useState<string>(undefined);

	const openUrl = async () => {
		dashboardId ||= userSession.account.isLevitateSalesCoffeeAccount
			? QuickSightDashboardViewModel.AIDARealMagicDialsId
			: QuickSightDashboardViewModel.AIDACustomersDialsId;

		const viewModel = new QuickSightDashboardViewModel(userSession, dashboardId, null, null);
		const embedUrl = await viewModel.getExternalEmbedUrl();
		window.open(embedUrl, '_blank');
	};

	React.useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		dashboardId ||= userSession.account.isLevitateSalesCoffeeAccount
			? QuickSightDashboardViewModel.AIDARealMagicDialsId
			: QuickSightDashboardViewModel.AIDACustomersDialsId;

		const viewModel = new QuickSightDashboardViewModel(userSession, dashboardId, null, null);
		viewModel
			.loadAida()
			.then(() => {
				setUrl(viewModel.quickSightUrl);
			})
			.catch((err: IOperationResultNoValue) => {
				setError(`Failed to open QuickSight: ${err.systemMessage}`);
			});
	}, [dashboardId]);

	if (error) {
		return <span>{error}</span>;
	}

	return (
		<>
			<iframe className={className} style={{ border: 'none', height: '100%', width: '100%' }} src={url} />
			{url && (
				<div>
					<a className={css(styleSheet.location)} onClick={openUrl}>
						Open in New Tab
						<LocationIcon className={css(styleSheet.locationIcon)} fillColor={brandSecondary} />
					</a>
				</div>
			)}
		</>
	);
};
