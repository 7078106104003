import { css } from 'aphrodite';
import * as React from 'react';
import { useRef } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { copyToClipboard } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useToaster } from '../../hooks';
import { white } from '../../styles/colors';
import { TransparentButton } from '../TransparentButton';
import { CopyIcon } from '../svgs/icons/CopyIcon';
import { ForwardIcon } from '../svgs/icons/audio/ForwardIcon';
import { PauseIcon } from '../svgs/icons/audio/PauseIcon';
import { PlayIcon } from '../svgs/icons/audio/PlayIcon';
import { RewindIcon } from '../svgs/icons/audio/RewindIcon';
import { VolumeIcon } from '../svgs/icons/audio/VolumeIcon';
import { VolumeMuteIcon } from '../svgs/icons/audio/VolumeMute';
import './audioPlayer.less';
import { styleSheet } from './styles';

interface IProps {
	autoplay: boolean;
	phoneCallId: string;
	source: string;
	subtitle: string;
	title: string;
}

export const CustomAudioPlayer: React.FC<IProps> = ({ autoplay = false, phoneCallId, source, subtitle, title }) => {
	const toaster = useToaster();
	const userSession = useUserSession();

	const copyAudioLink = (evt: React.MouseEvent<HTMLSpanElement>) => {
		evt.stopPropagation();
		const url = `${userSession.webServiceHelper.baseUrl}/PhoneCall/${phoneCallId}/Download`;
		if (copyToClipboard(url)) {
			toaster.push({
				message: `Link copied to clipboard`,
				type: 'successMessage',
			});
		} else {
			toaster.push({
				message: `Email failed to copy to clipboard`,
				type: 'errorMessage',
			});
		}
	};

	const renderHeader = (
		<div className={css(styleSheet.header)}>
			<div className={css(styleSheet.flexBetween)}>
				<div className={css(styleSheet.titleBar)}>
					<div className={css(styleSheet.titleContainer)}>
						<div className={css(styleSheet.title)}>
							{title}
							<TransparentButton onClick={copyAudioLink} className={css(styleSheet.copyButton)}>
								<CopyIcon fillColor={white} className={css(styleSheet.copyIcon)} />
							</TransparentButton>
						</div>
						<div className={css(styleSheet.subtitle)}>{subtitle}</div>
					</div>
				</div>
			</div>
		</div>
	);

	const iconSet = useRef({
		forward: <ForwardIcon />,
		pause: <PauseIcon />,
		play: <PlayIcon />,
		rewind: <RewindIcon />,
		volume: <VolumeIcon />,
		volumeMute: <VolumeMuteIcon />,
	}).current;

	return (
		<div className={css(styleSheet.container)}>
			{renderHeader}
			<AudioPlayer
				className={css(styleSheet.playerWrapper)}
				customAdditionalControls={[]}
				customControlsSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
				src={source}
				customIcons={iconSet}
				layout='horizontal'
				autoPlay={autoplay}
			/>
		</div>
	);
};
