import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { AddDealProperties } from '../../components/IntegrationProperties/AddDealProperties';
import { AddLeadProperties } from '../../components/IntegrationProperties/AddLeadProperties';
import { DealProperties } from '../../components/IntegrationProperties/DealProperties';
import {
	IntegrationPropertiesContextProvider,
	IntegrationPropertiesTypes,
} from '../../components/IntegrationProperties/IntegrationPropertiesContext';
import { LeadProperties } from '../../components/IntegrationProperties/LeadProperties';
import { BasicPage } from '../BasicPage';
import { IntegrationCards } from './components/IntegrationCards';
import { styleSheet } from './styles';

export enum IntegrationOptions {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	DealProperties = 'deal-properties',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	LeadProperties = 'lead-properties',
}

interface IRouteMatch {
	option: IntegrationOptions.DealProperties | IntegrationOptions.LeadProperties;
	adding: 'adding';
}

export const IntegrationContainer: React.FC = () => {
	const defaultTitle = 'Integration';

	const [renderConfig, setConfig] = useState<{
		component: React.ReactComponentElement<any>;
		title: string;
		type: IntegrationPropertiesTypes;
	}>(null);

	const matchOption = useRouteMatch<IRouteMatch>('/integration/:option');
	const matchAdding = useRouteMatch<IRouteMatch>('/integration/:option/:adding');
	const option = matchOption?.params?.option;
	const adding = matchAdding?.params?.adding;
	const isAdding = !!adding;
	useEffect(() => {
		switch (option) {
			case IntegrationOptions.DealProperties:
				setConfig({
					component: isAdding ? <AddDealProperties /> : <DealProperties />,
					title: IntegrationOptions.DealProperties,
					type: IntegrationPropertiesTypes.Deals,
				});
				break;
			case IntegrationOptions.LeadProperties:
				setConfig({
					component: isAdding ? <AddLeadProperties /> : <LeadProperties />,
					title: IntegrationOptions.LeadProperties,
					type: IntegrationPropertiesTypes.Leads,
				});
				break;
			default:
				setConfig({
					component: <IntegrationCards />,
					title: defaultTitle,

					type: null,
				});
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [option, adding]);

	return (
		<BasicPage className={css(styleSheet.basicPage)} title={renderConfig?.title?.replace('-', ' ')}>
			<IntegrationPropertiesContextProvider type={renderConfig?.type}>
				{renderConfig?.component}
			</IntegrationPropertiesContextProvider>
		</BasicPage>
	);
};
