import * as Api from '@ViewModels';
import { computed, observable } from 'mobx';
import { ILeadServedOutcomeView } from './leads/interfaces';
/**
 * The view models in this file are just skins over the interfaces. They are used for various views like the Call Log
 * screen, Follow Ups, etc.
 */

export class RecentCallViewModel extends Api.ViewModel implements ILeadServedOutcomeView {
	@observable public view: ILeadServedOutcomeView;
	constructor(userSession: Api.UserSessionContext, view: ILeadServedOutcomeView) {
		super(userSession);
		this.view = view;
	}
	@computed
	get phoneCall() {
		return this.view.phoneCall;
	}
	@computed
	get interaction() {
		return this.view.interaction;
	}
	@computed
	get company() {
		return this.view.company;
	}
	@computed
	get contact() {
		return this.view.contact;
	}
	@computed
	get lastDeal() {
		return this.view.lastDeal;
	}
	@computed
	get followUp() {
		return this.view.followUp;
	}
	@computed
	get nextMeeting() {
		return this.view.nextMeeting;
	}
	@computed
	get creator() {
		return this.view.creator;
	}
	@computed
	public get id() {
		// view.id is the leadserved so it is not enough to be unique

		return `${this.view.id}-${this.view.interaction.date}`;
	}
}

export enum LeadServedFilterCriteriaProperty {
	Category = 'Category',
	CompanyTag = 'CompanyTag',
	Date = 'Date',
	Manager = 'Manager',
	Outcome = 'Outcome',
	RecordingChannels = 'RecordingChannels',
	Team = 'Team',
	TranscriptionStatus = 'TranscriptionStatus',
	User = 'User',
	UserRole = 'UserRole',
}

export type ILeadServedFilterCriteria = Api.IFilterCriteria<LeadServedFilterCriteriaProperty>;

export type ILeadServedFilterRequest = ILeadServedFilterCriteria;
export class RecentCallsViewModel extends Api.ViewModel {
	@observable public recentCalls: Api.FilteredPageCollectionController<
		ILeadServedOutcomeView,
		RecentCallViewModel,
		ILeadServedFilterRequest
	>;
	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.recentCalls = new Api.FilteredPageCollectionController<
			ILeadServedOutcomeView,
			RecentCallViewModel,
			ILeadServedFilterRequest
		>({
			apiPath: this.composeApiUrl({ urlPath: 'LeadServed/filter' }),
			client: this.userSession.webServiceHelper,
			httpMethod: 'POST',
			transformer: x => new RecentCallViewModel(this.userSession, x),
		});
	}

	@computed
	get controller() {
		return this.recentCalls;
	}
}
