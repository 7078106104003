import * as Api from '@ViewModels';
import { IPrizeLog } from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { getDisplayName } from '../../../../../extViewmodels/Utils';
import { Group } from '../../../../components/generics/Group';
import { SimpleCheckbox } from '../../../../components/generics/SimpleCheckbox';
import { SlotMachineSymbol } from '../../../../components/slotMachines/SlotMachineSymbol';
import { styleSheet } from '../styles';

interface IProps {
	item: IPrizeLog;
	setGameTokenAsPaid?: (gameTokenId: string) => void;
}

export const ListItem: React.FC<IProps> = ({ item, setGameTokenAsPaid }) => {
	const gameData = item?.gameToken;

	const gameOutcome = gameData?.gameOutcome;

	const handleChangePaid = () => setGameTokenAsPaid(item.gameToken.id);

	const date = gameData?.redemptionDate ? moment(gameData.redemptionDate).format('MM/DD/YYYY') : 'Undefined';

	const paid = gameOutcome?.prizePaid;

	return (
		<div className={css(styleSheet.listItemsContainer)}>
			<Group className={css(styleSheet.card)} vCentered>
				<div className={css(styleSheet.cardRegularText, styleSheet.itemName)}>{getDisplayName(gameData?.creator)}</div>
				<div className={css(styleSheet.cardAmount, styleSheet.itemPrize)}>{gameOutcome?.prize}</div>
				<div className={css(styleSheet.cardTokens, styleSheet.itemSpinResult)}>
					<Group hCentered vCentered>
						{gameOutcome?.spinResult?.map((symbol: Api.SlotMachineSymbol, index: number) => (
							<SlotMachineSymbol key={index} type={symbol} style={{ height: 30, width: 'auto' }} />
						))}
					</Group>
				</div>
				<div className={css(styleSheet.cardRegularText, styleSheet.itemDate)}>
					<span className={css(styleSheet.cardRegularTextTitle)}>WHEN:</span> {date}
				</div>
				<div className={css(styleSheet.cardRegularText, styleSheet.itemAchievement)}>
					<span className={css(styleSheet.cardRegularTextTitle)}>FOR:</span>{' '}
					{item?.achievementDescription || 'Undefined'}
				</div>
				<div className={css(styleSheet.cardRegularText, styleSheet.cardLastColumn)}>
					<SimpleCheckbox
						className={css(styleSheet.checkbox, paid ? styleSheet.checkboxPaid : null)}
						checked={paid}
						disabled={paid}
						id={`paid-prize-${gameData.id}`}
						label='Paid'
						onChange={!paid ? handleChangePaid : undefined}
					/>
				</div>
			</Group>
		</div>
	);
};
