import { IPhoneCallCategory, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ViewModelTypeAutocomplete } from '../../../../containers/Settings/PhoneNumbers/components/ViewModelTypeAutocomplete';
import { styleSheet } from '../styles';

interface IProps {
	defaultValue?: IPhoneCallCategory;
	onChange: (item: string) => void;
}

export const CategorySelect: React.FC<IProps> = ({ defaultValue, onChange }) => {
	const [selectedItem, selectItem] = useState<IPhoneCallCategory>(null);
	useEffect(() => {
		selectItem(defaultValue);
	}, [defaultValue]);
	const handleSelection = (value: IPhoneCallCategory) => {
		onChange(value?.name);
		selectItem(value);
	};
	return (
		<ViewModelTypeAutocomplete<IPhoneCallCategory>
			className={`category-select ${css(styleSheet.autocomplete)}`}
			placeholder={selectedItem?.name ? '' + selectedItem?.name : 'Filter by Category'}
			model={ResourceAutoCompleteViewModelType.PhoneCallCategory}
			onChange={handleSelection}
		/>
	);
};
