import { useCallback } from 'react';
import { useToaster } from '.';
import { IOperationResultNoValue } from '../../extViewmodels';
import { useEventLogging } from '../../models/Logging';

export const useResponseHandler = (action: string, errorMessage = '') => {
	const toaster = useToaster();
	const { logApiError } = useEventLogging();

	const errorHandler = useCallback((err: IOperationResultNoValue) => {
		logApiError(`${action}-Error`, err);

		toaster.push({
			message: err.systemMessage || errorMessage,
			type: 'errorMessage',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [errorHandler] as const;
};
