import { IUser } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { getDisplayName } from '../../../../extViewmodels/Utils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { Button } from '../../../../web/components/Button';
import { AutoCompleteSearchField } from '../../../../web/components/autocomplete/AutoCompleteSearchField';
import { ResourceAutoCompleteViewModelType } from '../../../components/autocomplete/autocomplete';
import { GoalDatePicker } from '../../../components/goals/GoalDatePicker';
import { GoalsTable } from '../../../components/goals/GoalsTable';
import { SearchIcon } from '../../../components/svgs/icons/SearchIcon';
import { useErrorMessages, useToaster } from '../../../hooks';
import { aidaBaseStyleSheet } from '../../../styles/styles';
import { GoalsViewModel } from '../../../viewModels/goals';
import { BasicPage } from '../../BasicPage';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

export const Goals: React.FC<IProps> = observer(({ className = '' }) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const userSession = useUserSession();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const goals = useRef(new GoalsViewModel(userSession)).current;

	useEffect(() => {
		goals.reset();

		goals.load()?.catch(err => errorMessages.pushApiError(err));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goals.user]);

	const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.currentTarget.files[0];
		if (!file) {
			return; // cancelled the file picker
		}

		inputRef.current.value = '';
		goals
			.upload(file)

			.then(() => toaster.push({ message: 'Goals were uploaded', type: 'successMessage' }))

			.catch(err => errorMessages.pushApiError(err));
	};

	const onUploadClick = () => {
		inputRef.current?.click();
	};

	const onUserSelected = (user: IUser) => (goals.user = user);

	const onUserClear = (): void => (goals.user = null);

	return (
		<BasicPage className={`${css(styleSheet.goalsContainer)} ${className}`} title='goals'>
			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.header)}>
					{/* user select box */}
					<AutoCompleteSearchField
						anchorClassName='user-auto-complete-anchor'
						placeholder='Search for a user...'
						className={css(styleSheet.userAutocomplete)}
						dropdownClassName='user-auto-complete-dropdown'
						leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
						initialSearchQuery={null}
						inputId='user-select-goals'
						onItemSelected={onUserSelected}
						hideResultsFooter={false}
						preventImpersonation={true}
						resultsLimit={5}
						type={ResourceAutoCompleteViewModelType.User}
						clearSearchFieldAfterSelectingItem={true}
					/>
					<div className={css(styleSheet.selectedUser)}>{getDisplayName(goals.user)}</div>
					{goals.user && <Button label='Clear' onClick={onUserClear} kind='secondary' />}
				</div>
				<div className={css(styleSheet.header)}>
					<div className={css(styleSheet.selectedUser)}>Date Range:</div>
					<GoalDatePicker goals={goals} />
					<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
						<input
							type='file'
							className={css(styleSheet.fileInput)}
							onChange={onFileChange}
							ref={inputRef}
							accept='.xlsx'
						/>
						<Button label='Upload' onClick={onUploadClick} kind='secondary' />
					</div>
				</div>
			</div>
			<GoalsTable goals={goals} />
		</BasicPage>
	);
});
