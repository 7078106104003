import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CalendarDateIcon } from '../../../web/components/svgs/icons/CalendarDateIcon';
import { CallLogIcon } from '../../../web/components/svgs/icons/CallLogIcon';
import { FollowUpsIcon } from '../../../web/components/svgs/icons/FollowUpsIcon';
import { PhoneIcon } from '../../../web/components/svgs/icons/PhoneIcon';
import { SearchIcon } from '../../../web/components/svgs/icons/SearchIcon';
import { SettingsIcon } from '../../../web/components/svgs/icons/SettingsIcon';
import { brandPrimaryLight } from '../../styles/colors';
import { TransparentButton } from '../TransparentButton';
import { TrophyIcon } from '../svgs/icons/TrophyIcon';
import { styleSheet } from './styles';

export enum NavLinkName {
	CallLog = 'CallLog',
	Demos = 'Demos',
	FollowUps = 'FollowUps',
	Leaderboard = 'Leaderboard',
	Queue = 'Queue',
	Search = 'Search',
	Settings = 'Settings',
}

export interface INavLink {
	onClick?: () => void;
	path?: string;
	text: NavLinkName;
}

interface IProps {
	className?: string;
	link: INavLink;
}

const activeColor = '#fff';
const inactiveColor = brandPrimaryLight;

export const NavLink: React.FC<IProps> = ({ className = '', link }) => {
	const location = useLocation();

	const [isActive, setIsActive] = useState(location.pathname.includes(link.path));

	useEffect(() => {
		setIsActive(location.pathname.includes(link.path));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const onMouseEnter = () => {
		setIsActive(true);
	};

	const onMouseLeave = () => {
		setIsActive(location.pathname.includes(link.path));
	};

	const renderIcon = () => {
		switch (link.text) {
			case NavLinkName.CallLog:
				return <CallLogIcon fill={isActive ? activeColor : inactiveColor} />;
			case NavLinkName.Demos:
				return <CalendarDateIcon fillColor={isActive ? activeColor : inactiveColor} />;
			case NavLinkName.FollowUps:
				return <FollowUpsIcon fill={isActive ? activeColor : inactiveColor} />;
			case NavLinkName.Leaderboard:
				return <TrophyIcon fillColor={isActive ? activeColor : inactiveColor} />;
			case NavLinkName.Queue:
				return <PhoneIcon fill={isActive ? activeColor : inactiveColor} />;
			case NavLinkName.Search:
				return <SearchIcon fillColor={isActive ? activeColor : inactiveColor} />;
			case NavLinkName.Settings:
				return <SettingsIcon stroke={isActive ? activeColor : inactiveColor} />;
			default:
				return null;
		}
	};

	return link.onClick ? (
		<TransparentButton
			className={`${css(styleSheet.navLinkContainer, isActive && styleSheet.active)} ${className}`}
			onClick={link.onClick}
		>
			{renderIcon()}
		</TransparentButton>
	) : (
		<Link
			className={`${css(styleSheet.navLinkContainer, isActive && styleSheet.active)} ${className}`}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			to={link.path}
		>
			{renderIcon()}
		</Link>
	);
};
