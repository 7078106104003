import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { ISelectedEmailRecipient } from '..';
import { replaceTokenWithActualFirstNameString } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { EmailCampaignBrowserViewModel } from '../../../../viewmodels/AppViewModels';
import { AutomationPreviewStep } from '../../../../web/components/automation/AutomationPreview/presentation';
import { styleSheet } from '../styles';
import { ChildAutomationViewer } from './ChildAutomationViewer';

export interface IProps {
	selectedRecipient: ISelectedEmailRecipient;
	template: Api.AutomationTemplateViewModel;
}

export const ParentAutomationViewer: React.FC<IProps> = ({ selectedRecipient, template }) => {
	const userSession = useUserSession();
	const browser = useRef(new EmailCampaignBrowserViewModel(userSession)).current;
	const [automationTemplate, setTemplate] = useState(null);

	useEffect(() => {
		browser.loadAutomationTemplateById(template.id).then(response => {
			setTemplate(response.value);
		});
	}, [browser, template.id]);

	if (!automationTemplate) {
		return null;
	}

	return automationTemplate?.published?.steps?.map((step: Api.IAutomationStep) => {
		if (step?.content) {
			step.content = replaceTokenWithActualFirstNameString(step.content, selectedRecipient?.contact?.firstName);
		}
		const renderChildTemplates = (childTemplate: Api.AutomationTemplateViewModel) => (
			<ChildAutomationViewer selectedRecipient={selectedRecipient} template={childTemplate} />
		);
		return (
			<div className={css(styleSheet.automationPreviewContainer)} key={step.id}>
				<AutomationPreviewStep
					automationTemplate={automationTemplate}
					showPublished={true}
					step={step}
					steps={automationTemplate.published.steps}
					renderCustomAutomationPreview={renderChildTemplates}
				/>
			</div>
		);
	});
};
