import { css } from 'aphrodite';
import * as React from 'react';
import { ConfirmationDialog, IConfirmationDialogProps } from '../../../web/components/ConfirmationDialog';
import { IModalProps } from '../../../web/components/Modal';
import { styleSheet } from './styles';

export interface IBlueConfirmationDialogProps extends IConfirmationDialogProps {
	className?: string;
	modalProps?: IModalProps;
}

export const BlueConfirmationDialog: React.FC<IBlueConfirmationDialogProps> = ({
	children,
	className = '',
	modalProps,
	...restProps
}) => {
	return (
		<ConfirmationDialog
			{...restProps}
			className={className}
			modalProps={{
				overlayPresentationStyle: {
					afterOpenClassName: css(styleSheet.blueModalAfterOpen),
					beforeCloseClassName: css(styleSheet.blueModalBeforeClose),
					className: `modal-overlay ${css(styleSheet.blueModal)} ${className}`,
					transitionInDuration: 500,
					transitionOutDuration: 500,
				},
				...modalProps,
			}}
		>
			{children}
		</ConfirmationDialog>
	);
};
