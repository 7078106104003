import * as Api from '@ViewModels';
import { IBaseApiModel, IRemoteEvent, IUserReference, UserSessionContext, ViewModel } from '@ViewModels';
import { action, computed, observable, runInAction } from 'mobx';
import { LightningType } from '../components/svgs/LightningGraphic';
import { IWebsocketSubscription, WebsocketsListener } from './websockets';

export type LeaderboardSort = 'points' | 'connections' | 'dials' | 'demos';

export interface IGamificationRemoteResourceEvent extends IBaseApiModel {
	calling?: string;
	connected?: string;
	hangup?: string;
	ringing?: string;
}

export interface IScore {
	followUpsSet?: number;
	prospectingDials?: number;
	dials: number;
	connections: number;
	demos: number;
	points: number;
}

interface IDailyActivity extends IBaseApiModel {
	creator: IUserReference;
	day: string;
	score: IScore;
	/** Total goal for the day */
	goals: IScore;
	/** Where the user should be given the current time */
	projectedScore: IScore;
}

export enum MemePointsPlacement {
	Top = 'Top',
	TopRight = 'TopRight',
	Right = 'Right',
	BottomRight = 'BottomRight',
	Bottom = 'Bottom',
	BottomLeft = 'BottomLeft',
	Left = 'Left',
	TopLeft = 'TopLeft',
	Center = 'Center',
}

export interface IMemeTheme {
	primaryColor: string;
	secondaryColor: string;
	backgroundColor: string;
	pointsColor: string;
	pointsBackgroundColor: string;
	pointsPlacement: MemePointsPlacement;
}

export interface IFile {
	mimeType: string;
	url: string;
}

export interface IMeme {
	id: string;
	activities: string[];
	image: IFile;
	sound?: IFile;
	/** The point value */
	value?: number;
	phrase: string;
	memeType: LightningType;
	theme: IMemeTheme;
}

export interface ILeaderboardUser {
	userReference: IUserReference;
	score: IScore;
}

export interface ILeaderboard {
	projectedScore?: IScore;
	scores: ILeaderboardUser[];
	title: string;
	totalGoals?: IScore;
	totalScore?: IScore;
}

export interface ILeaderboardDemosScore {
	goal: number;
	demos: number;
}
export interface ILeaderboardDemos {
	day: string;
	totalDials: number;
	totalVerticalLeaderboard: {
		verticalStatistics: Record<string, ILeaderboardDemosScore>;
		totalStatistics: ILeaderboardDemosScore;
	};
	roleLeaderboards: Record<
		string,
		{
			verticalStatistics: Record<string, ILeaderboardDemosScore>;
			totalStatistics: ILeaderboardDemosScore;
		}
	>;
}

export enum LeaderboardFilterCriteriaProperty {
	Manager = 'Manager',
	Team = 'Team',
	User = 'User',
	UserRole = 'UserRole',
	Vertical = 'Vertical',
}

export type ILeaderboardFilterCriteria = Api.IFilterCriteria<LeaderboardFilterCriteriaProperty>;

export enum LeaderboardDemosFilterCriteriaProperty {
	Day = 'Day',
}

export type ILeaderboardDemosFilterCriteria = Api.IFilterCriteria<LeaderboardDemosFilterCriteriaProperty>;
/**
 * Meme and score management In the future, badges and other goodies should be associated with the Gamification view
 * model
 */
export class GamificationViewModel extends ViewModel {
	@observable.ref private mWebsocketsListener: WebsocketsListener;

	@observable private mDailyActivity: IDailyActivity;

	@observable private mMeme: IMeme;
	@observable private mMemeTimer = 5000; // time in ms for meme to show

	@observable private mMemeTimeout: number;

	@observable private mUserLeaderboard: ILeaderboard;

	@observable private mTeamLeaderboards: ILeaderboard[];
	@observable public gettingScores = false;
	@observable public gettingLeaderboard = false;
	public initScoresRetrieved = false;
	private static SUBSCRIPTION = 'gamification';

	constructor(userSession: UserSessionContext, websockets: WebsocketsListener) {
		super(userSession);
		this.mWebsocketsListener = websockets;
	}

	@computed
	get connections() {
		return this.mDailyActivity?.score?.connections || 0;
	}

	@computed
	get prospectingDials() {
		return this.mDailyActivity?.score?.prospectingDials || 0;
	}

	@computed
	get followUpsSet() {
		return this.mDailyActivity?.score?.followUpsSet || 0;
	}

	@computed
	get demos() {
		return this.mDailyActivity?.score?.demos || 0;
	}

	@computed
	get dials() {
		return this.mDailyActivity?.score?.dials || 0;
	}

	@computed
	get points() {
		return this.mDailyActivity?.score?.points || 0;
	}

	@computed
	get userPercentage(): IScore {
		return {
			connections: Math.round(((this.connections || 0) / (this.goals?.connections || 1)) * 100),
			demos: Math.round(((this.demos || 0) / (this.goals?.demos || 1)) * 100),
			dials: Math.round(((this.dials || 0) / (this.goals?.dials || 1)) * 100),
			points: Math.round(((this.points || 0) / (this.goals?.points || 1)) * 100),
			prospectingDials: Math.round(((this.prospectingDials || 0) / (this.goals?.prospectingDials || 1)) * 100),
			followUpsSet: Math.round(((this.followUpsSet || 0) / (this.goals?.followUpsSet || 1)) * 100),
		};
	}

	@computed
	get goalPercentage(): IScore {
		return {
			connections: Math.round(((this.projectedScore?.connections || 0) / (this.goals?.connections || 1)) * 100),
			demos: Math.round(((this.projectedScore?.demos || 0) / (this.goals?.demos || 1)) * 100),
			dials: Math.round(((this.projectedScore?.dials || 0) / (this.goals?.dials || 1)) * 100),
			points: Math.round(((this.projectedScore?.points || 0) / (this.goals?.points || 1)) * 100),
			prospectingDials: Math.round(
				((this.projectedScore?.prospectingDials || 0) / (this.goals?.prospectingDials || 1)) * 100
			),
			followUpsSet: Math.round(((this.projectedScore?.followUpsSet || 0) / (this.goals?.followUpsSet || 1)) * 100),
		};
	}

	@computed
	get percentToGoal(): IScore {
		return {
			connections: Math.round((this.userPercentage.connections / (this.goalPercentage.connections || 1)) * 100),
			demos: Math.round((this.userPercentage.demos / (this.goalPercentage.demos || 1)) * 100),
			dials: Math.round((this.userPercentage.dials / (this.goalPercentage.dials || 1)) * 100),
			points: Math.round((this.userPercentage.points / (this.goalPercentage.points || 1)) * 100),
			prospectingDials: Math.round(
				(this.userPercentage.prospectingDials / (this.goalPercentage.prospectingDials || 1)) * 100
			),
			followUpsSet: Math.round((this.userPercentage.followUpsSet / (this.goalPercentage.followUpsSet || 1)) * 100),
		};
	}

	@computed
	get projectedScore() {
		return this.mDailyActivity?.projectedScore;
	}

	@computed
	get goals() {
		return this.mDailyActivity?.goals;
	}

	@computed
	get meme() {
		return this.mMeme;
	}

	@computed
	get userLeaderboard() {
		return this.mUserLeaderboard;
	}

	@computed
	get teamLeaderboards() {
		return this.mTeamLeaderboards;
	}

	@action
	public connect = () => {
		if (this.userSession?.user) {
			const subscription: IWebsocketSubscription = {
				callback: this.onGamificationEvent,
				events: ['UserDailyActivityEvent'],
				name: GamificationViewModel.SUBSCRIPTION,
			};

			this.mWebsocketsListener.subscribe(subscription);
		}
	};

	@action
	public getUserLeaderboard = async (sort: LeaderboardSort = 'points', asc = false) => {
		if (!this.gettingLeaderboard) {
			this.gettingLeaderboard = true;
			try {
				const value = await this.webServiceHelper.callAsync<ILeaderboard>(
					this.composeApiUrl({
						queryParams: { sort: asc ? 'asc' : 'desc', sortBy: sort },
						urlPath: `userDailyActivity/leaderboard`,
					}),
					'GET'
				);
				runInAction(() => {
					this.mUserLeaderboard = value;
				});
			} finally {
				runInAction(() => {
					this.gettingLeaderboard = false;
				});
			}
		}
	};

	@action
	public getUserLeaderboardFiltered = async (
		criteria: ILeaderboardFilterCriteria[],
		sort: LeaderboardSort = 'points',
		asc = false
	) => {
		if (!this.gettingLeaderboard) {
			this.gettingLeaderboard = true;
			try {
				const value = await this.webServiceHelper.callAsync<ILeaderboard>(
					this.composeApiUrl({
						queryParams: { sort: asc ? 'asc' : 'desc', sortBy: sort },
						urlPath: `userDailyActivity/leaderboard/filter`,
					}),
					'POST',
					{ criteria }
				);
				runInAction(() => {
					this.mUserLeaderboard = value;
				});
			} finally {
				runInAction(() => {
					this.gettingLeaderboard = false;
				});
			}
		}
	};

	@action
	public getTeamLeaderboards = async (sort: LeaderboardSort = 'points', asc = false) => {
		if (!this.gettingLeaderboard) {
			this.gettingLeaderboard = true;

			try {
				const value = await this.webServiceHelper.callAsync<ILeaderboard[]>(
					this.composeApiUrl({
						queryParams: { sort: asc ? 'asc' : 'desc', sortBy: sort },
						urlPath: `userDailyActivity/leaderboard/breakdown`,
					}),
					'GET'
				);
				runInAction(() => {
					this.mTeamLeaderboards = value;
				});
			} finally {
				runInAction(() => {
					this.gettingLeaderboard = false;
				});
			}
		}
	};

	@action
	public getScores = async () => {
		if (!this.gettingScores) {
			this.gettingScores = true;

			try {
				const value = await this.userSession.webServiceHelper.callAsync<IDailyActivity>(
					this.composeApiUrl({ urlPath: `userDailyActivity` }),
					'GET'
				);
				runInAction(() => {
					this.mDailyActivity = value;
					this.initScoresRetrieved = true;
				});
			} finally {
				runInAction(() => {
					this.gettingScores = false;
				});
			}
		}
	};

	@action
	public reset = () => {
		this.mWebsocketsListener.unsubscribe(GamificationViewModel.SUBSCRIPTION);
	};

	@action
	public setMeme = (meme: IMeme) => {
		this.mMeme = meme;

		if (this.mMeme) {
			this.mMemeTimeout = window.setTimeout(() => {
				this.mMeme = null;
			}, this.mMemeTimer);
		}
	};

	@action
	public closeMeme = () => {
		window.clearTimeout(this.mMemeTimeout);

		this.mMemeTimeout = null;

		this.mMeme = null;
	};

	@action
	private onGamificationEvent = async (events: IRemoteEvent<IDailyActivity>[]) => {
		events.forEach(e => {
			this.mDailyActivity = e.value;
		});
	};
}
