import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from '../../../../web/components/Button';
import { Checkbox } from '../../../../web/components/Checkbox';
import { ISelectOption } from '../../../../web/components/Select';
import { BlueModal } from '../../BlueModal';
import { Fill } from '../../leadView/components/Fill';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';
import { weekDays } from '../utils';
import { TimeRangeRepeater } from './TimeRangeRepeater';

export interface IRangeValues {
	start: ISelectOption<number>;
	end: ISelectOption<number>;
}

export interface IScheduleInterval {
	enabled: boolean;
	index: number;
	label: string;
	range?: IRangeValues[];
}

interface IProps {
	values: IScheduleInterval[];
	isOpen: boolean;
	onClickToClose: () => void;
	onClickToSave: (values: IScheduleInterval[]) => void;
}

export const ScheduleModal: React.FC<IProps> = ({ values, isOpen, onClickToClose, onClickToSave }) => {
	const [selectedSchedule, setSchedule] = useState<IScheduleInterval[]>(values || weekDays);

	useEffect(() => {
		if (values) {
			setSchedule(values);
		}
	}, [values]);

	const handleTimeRepeaterChanges = (index: number) => (value: { enabled?: boolean; range?: IRangeValues[] }) => {
		const prevState = [...selectedSchedule];
		const newState = prevState.map(item => {
			if (item.index === index) {
				item = { ...item, ...value };
			}
			return item;
		});
		setSchedule(newState);
	};

	const handleSaveSchedule = () => {
		onClickToSave?.(selectedSchedule);
	};

	const handleWeekdayState =
		(index: number) =>
		({ target }: React.ChangeEvent<HTMLInputElement>) => {
			handleTimeRepeaterChanges(index)({ enabled: target?.checked });
		};

	const handleTimeRange = (index: number) => (range: IRangeValues[]) => {
		handleTimeRepeaterChanges(index)({ range });
	};

	const currentState = [...selectedSchedule];

	return (
		<BlueModal isOpen={isOpen} onRequestClose={onClickToClose} className={css(styleSheet.leadRuleScheduleModal)}>
			<div className={css(styleSheet.title)}>Set Schedule</div>
			<Group vertical={true} className={css(styleSheet.weekdaysWrapper)}>
				{selectedSchedule.map(({ label, index }) => {
					const currentItem = currentState.filter(item => item.index === index)?.[0];
					return (
						<React.Fragment key={label}>
							<Group>
								<div className={css(styleSheet.tagCheckboxContainer)}>
									<Checkbox
										className={css(styleSheet.tagCheckbox)}
										id={`checkbox-${label}-${index}`}
										checked={currentItem?.enabled}
										onChange={handleWeekdayState(index)}
									>
										<span className={css(styleSheet.checkboxText)}>{label}</span>
									</Checkbox>
								</div>
								<TimeRangeRepeater
									values={currentItem?.range}
									disabled={!currentItem?.enabled}
									onChange={handleTimeRange(index)}
								/>
							</Group>
						</React.Fragment>
					);
				})}
			</Group>
			<Group>
				<Fill />
				<Button label='Update' onClick={handleSaveSchedule} />
			</Group>
		</BlueModal>
	);
};
