import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import * as React from 'react';
import { IOperationResultNoValue } from '../../../../extViewmodels';
import { Noop, timezoneMap } from '../../../../extViewmodels/Utils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useModal } from '../../../../models/hooks/useModal';
import { useToaster } from '../../../hooks';
import { useQueue } from '../../../hooks/queue';
import { error, orange, success } from '../../../styles/colors';
import { FollowUpImportance, ILeadFollowUp } from '../../../viewModels/queue';
import { PepperIcon } from '../../svgs/icons/PepperIcon';
import { FollowUpModal, IFollowUpCallbackArgs } from '../FollowUpModal';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	followUp?: ILeadFollowUp;
}

export const CurrentFollowUp: React.FC<IProps> = observer(props => {
	const queue = useQueue();
	const userSession = useUserSession();
	const toaster = useToaster();
	const { followUp, className, styles = [] } = props;
	const followUpModal = useModal(false, Noop, [], true, false);
	if (!followUp) {
		return null;
	}

	const onClick = () => {
		followUpModal.setIsOpen(true)();
	};

	const pepperFill =
		followUp.importance === FollowUpImportance.High
			? error
			: followUp.importance === FollowUpImportance.Medium
				? orange
				: success;

	const onClickToConfirm = ({ importance, name, selectedDate }: IFollowUpCallbackArgs) => {
		queue
			.setFollowUp(selectedDate.toDate(), name, importance)
			.then(() => {
				followUpModal.setIsOpen(false)();

				toaster.push({
					message: `Follow up is updated to ${selectedDate.format('MM/DD/YYYY hh:mm A')}`,
					type: 'successMessage',
				});
			})
			.catch((err: IOperationResultNoValue) => {
				toaster.push({
					message: `Unable to set follow up: ${err.systemMessage}`,
					type: 'errorMessage',
				});
			});
	};

	return (
		<div className={`${css(styleSheet.container, ...styles)} current-follow-up ${className || ''}`}>
			<PepperIcon className={css(styleSheet.pepperIcon)} fillColor={pepperFill} />
			<span className={css(styleSheet.text)} onClick={onClick}>
				{followUp.scheduled !== true && 'Unscheduled '}Follow Up:{' '}
				{moment(followUp.followUpDate).format('M/D/YY h:mm a ') +
					timezoneMap[userSession.user.userPreferences?.timeZone || momentTz.tz.guess()]}
				<span className={css(styleSheet.date)}>&nbsp;{followUp.name}</span>
			</span>
			<FollowUpModal {...followUpModal} onClickToConfirm={onClickToConfirm} />
		</div>
	);
});
