import { StompConfig } from '@stomp/stompjs';
import * as Api from '@ViewModels';
import { action, computed, observable } from 'mobx';

export type WebsocketSubscriptionCallback<TEventType = any> = (events: Api.IRemoteEvent<TEventType>[]) => void;

export interface IWebsocketSubscription<TEventType = any> {
	callback: WebsocketSubscriptionCallback<TEventType>;
	/** Unique name for subscription */
	name: string;
	/** Ex. PhoneCallEvent, TimelineEvent */
	events?: string[];
}

/**
 * The WebsocketsListener is designed to provide multiple easy to use subscriptions to a single underlying RabbitMq
 * connection
 */
export class WebsocketsListener<
	TUserSession extends Api.UserSessionContext = Api.UserSessionContext,
> extends Api.RemoteResourceEventsViewModel<TUserSession, any> {
	@observable.ref private userSession: TUserSession;
	private eventSubscriptions: IWebsocketSubscription[] = [];
	private lastMessageReceived: Date;
	private lastMessageReceivedBySubscription: Record<string, Date> = {};

	constructor(userSession: TUserSession, eventLogger?: Api.IEventLoggingService) {
		super(userSession, '', '#', eventLogger);
		this.userSession = userSession;
	}

	/** In this case, the base route for topic subscription is set for the user, which most resources are under */
	protected composeRoute() {
		return `User.${this.userSession.user.id}.#`;
	}

	/** Subscribe to a specific topic */
	public subscribe(subscription: IWebsocketSubscription) {
		if (!this.eventSubscriptions.find(x => x.name === subscription.name)) {
			this.eventSubscriptions.push(subscription);
		}
	}

	/** Unsubscribe from a specific topic */
	public unsubscribe(subscriptionName: string) {
		this.eventSubscriptions = this.eventSubscriptions.filter(x => x.name !== subscriptionName);
	}

	public hasSubscription(subscriptionName: string) {
		return this.eventSubscriptions.findIndex(x => x.name === subscriptionName) >= 0;
	}

	public connect = (stompConfig?: StompConfig) => {
		return this.mRemoteEvents
			.connect(stompConfig)
			?.then(() => console.log('%cWebsockets connected:', 'color: yellow;', new Date()))
			?.then(this.onConnect);
	};

	public permananentlyDisconnect() {
		const promise = super.permananentlyDisconnect();
		this.eventSubscriptions = [];
		console.log('%cWebsockets disconnected:', 'color: red;', new Date());
		return promise;
	}

	@action
	protected onMessage(events: Api.IRemoteEvent<any>[]) {
		this.lastMessageReceived = new Date();
		this.eventSubscriptions.forEach(sub => {
			// events that this subscription cares about
			const subEvents: Api.IRemoteEvent<any>[] = [];

			events.forEach(evt => {
				if (sub.events.includes(evt.valueType)) {
					subEvents.push(evt);
				}
			});

			if (subEvents.length) {
				this.lastMessageReceivedBySubscription[sub.name] = new Date();
				sub.callback(subEvents);
			}
		});
	}

	@action
	public init = (stompConfig?: StompConfig) => {
		if (this.userSession?.user) {
			this.connect(stompConfig);
		}
	};

	@computed
	public get lastMessageReceivedTime() {
		return this.lastMessageReceived;
	}

	public lastMessageReceivedTimeBySubscriptionName(name: string) {
		return this.lastMessageReceivedBySubscription[name];
	}
}
