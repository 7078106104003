import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { styleSheet } from '../styles';

const useOnClickOutside = (refs: HTMLElement[], handler: (event: MouseEvent) => void) => {
	useEffect(() => {
		const listener = (event: MouseEvent) => {
			const refsCount = refs.filter(ref => {
				return !!ref?.contains(event.target as Node);
			});
			if (refsCount?.length > 0) {
				return;
			}
			handler(event);
		};
		document.addEventListener('mousedown', listener);

		// @ts-ignore
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);

			// @ts-ignore
			document.removeEventListener('touchstart', listener);
		};
	}, [refs, handler]);
};

interface IProps {
	onKeyPress: (keys: string) => void;
}

export const Dialpad: React.FC<IProps> = ({ onKeyPress }) => {
	const [isOpen, setOpen] = useState(false);

	const [containerRef, setContainerRef] = useState<HTMLElement>(null);

	const [triggerRef, setTriggerRef] = useState<HTMLElement>(null);
	const [lastKey, setLastKey] = useState('');

	useOnClickOutside([containerRef, triggerRef], () => setOpen(false));

	const handleTriggerClick = () => {
		setOpen(!isOpen);
	};

	const handleKeyPress = (key: string) => () => {
		const value = lastKey + key;
		setLastKey(value);
		onKeyPress?.(key);
	};

	const isActive = lastKey?.length > 0;

	return (
		<Popover
			align='center'
			content={
				<div className={css(styleSheet.dialpadContainer)} ref={setContainerRef}>
					<input type='text' className={css(styleSheet.dialpadInfo)} value={lastKey || '-'} readOnly={true} />
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('1')}>
						1
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('2')}>
						<div>2</div>
						<small>ABC</small>
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('3')}>
						<div>3</div>
						<small>DEF</small>
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('4')}>
						<div>4</div>
						<small>GHI</small>
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('5')}>
						<div>5</div>
						<small>JKL</small>
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('6')}>
						<div>6</div>
						<small>MNO</small>
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('7')}>
						<div>7</div>
						<small>PQRS</small>
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('8')}>
						<div>8</div>
						<small>TUV</small>
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('9')}>
						<div>9</div>
						<small>WXYZ</small>
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('*')}>
						✳
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('0')}>
						0
					</div>
					<div className={css(styleSheet.dialpadKey)} onClick={handleKeyPress('#')}>
						#
					</div>
				</div>
			}
			isOpen={isOpen}
			positions={['bottom', 'top']}
			ref={setTriggerRef}
		>
			<div
				onClick={handleTriggerClick}
				className={css([styleSheet.dialpadTrigger, isActive && styleSheet.dialpadTriggerActive])}
			>
				<svg height='22' viewBox='0 0 20 20' width='22'>
					<path
						id='key1'
						d='M6 5.25C6.69036 5.25 7.25 4.69036 7.25 4C7.25 3.30964 6.69036 2.75 6 2.75C5.30964 2.75 4.75 3.30964 4.75 4C4.75 4.69036 5.30964 5.25 6 5.25Z'
					/>
					<path
						id='key2'
						d='M6 9.25C6.69036 9.25 7.25 8.69036 7.25 8C7.25 7.30964 6.69036 6.75 6 6.75C5.30964 6.75 4.75 7.30964 4.75 8C4.75 8.69036 5.30964 9.25 6 9.25Z'
					/>
					<path
						id='key3'
						d='M7.25 12C7.25 12.6904 6.69036 13.25 6 13.25C5.30964 13.25 4.75 12.6904 4.75 12C4.75 11.3096 5.30964 10.75 6 10.75C6.69036 10.75 7.25 11.3096 7.25 12Z'
					/>
					<path
						id='key4'
						d='M10 5.25C10.6904 5.25 11.25 4.69036 11.25 4C11.25 3.30964 10.6904 2.75 10 2.75C9.30964 2.75 8.75 3.30964 8.75 4C8.75 4.69036 9.30964 5.25 10 5.25Z'
					/>
					<path
						id='key5'
						d='M11.25 8C11.25 8.69036 10.6904 9.25 10 9.25C9.30964 9.25 8.75 8.69036 8.75 8C8.75 7.30964 9.30964 6.75 10 6.75C10.6904 6.75 11.25 7.30964 11.25 8Z'
					/>
					<path
						id='key6'
						d='M10 13.25C10.6904 13.25 11.25 12.6904 11.25 12C11.25 11.3096 10.6904 10.75 10 10.75C9.30964 10.75 8.75 11.3096 8.75 12C8.75 12.6904 9.30964 13.25 10 13.25Z'
					/>
					<path
						id='key7'
						d='M11.25 16C11.25 16.6904 10.6904 17.25 10 17.25C9.30964 17.25 8.75 16.6904 8.75 16C8.75 15.3096 9.30964 14.75 10 14.75C10.6904 14.75 11.25 15.3096 11.25 16Z'
					/>
					<path
						id='key8'
						d='M14 5.25C14.6904 5.25 15.25 4.69036 15.25 4C15.25 3.30964 14.6904 2.75 14 2.75C13.3096 2.75 12.75 3.30964 12.75 4C12.75 4.69036 13.3096 5.25 14 5.25Z'
					/>
					<path
						id='key9'
						d='M15.25 8C15.25 8.69036 14.6904 9.25 14 9.25C13.3096 9.25 12.75 8.69036 12.75 8C12.75 7.30964 13.3096 6.75 14 6.75C14.6904 6.75 15.25 7.30964 15.25 8Z'
					/>
					<path
						id='key0'
						d='M14 13.25C14.6904 13.25 15.25 12.6904 15.25 12C15.25 11.3096 14.6904 10.75 14 10.75C13.3096 10.75 12.75 11.3096 12.75 12C12.75 12.6904 13.3096 13.25 14 13.25Z'
					/>
				</svg>
			</div>
		</Popover>
	);
};
