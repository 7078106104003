import { IPhoneCallCategory } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useModal } from '../../../../../models/hooks/useModal';
import { DefaultConfirmationOptions } from '../../../../../web/components/ConfirmationDialog';
import { WarningIcon } from '../../../../../web/components/svgs/icons/WarningIcon';
import { BlueConfirmationDialog } from '../../../../components/BlueConfirmationDialog';
import { Category } from '../../../../components/CategoryListBuilder';
import { Fill } from '../../../../components/leadView/components/Fill';
import { Group } from '../../../../components/leadView/components/Group';
import { usePhoneCallCategory } from '../../../../entities/PhoneCall/usePhoneCallCategory';
import { FetchStatus } from '../../../../entities/utils/useStatesOnFetch';
import { styleSheet } from '../styles';
import { CategoryCardColors } from './CategoryCardColors';
import { CategoryCardOptions } from './CategoryCardOptions';

interface IProps {
	category: IPhoneCallCategory;
	onRequestToUpdateList?: () => void;
}

export const CategoryCard: React.FC<IProps> = ({ category, onRequestToUpdateList }) => {
	const [currentCategory, setCategory] = useState(category);
	// const [isEditing, setEditing] = useState(false);
	// const [categoryName, setCategoryName] = useState(category?.name);

	const categoryName = category?.name;

	const history = useHistory();

	const { update, status } = usePhoneCallCategory<IPhoneCallCategory>();

	const handleUpdates = (change: IPhoneCallCategory, callback?: () => void) => {
		update(currentCategory.id, change).then(({ value }) => {
			setCategory(value);
			callback?.();
		});
	};

	const archiveConfirmationModal = useModal(
		false,
		() => {
			handleUpdates({ ...currentCategory, archived: true }, onRequestToUpdateList);
		},
		[]
	);

	// const handleRename = () => {
	// 	setEditing(true);
	// };

	const handleArchive = () => {
		archiveConfirmationModal.setIsOpen(true)();
	};

	const handleColorChange = (color: string) => {
		handleUpdates({ ...currentCategory, hexColor: color });
	};

	// const updateCategoryName: (args: string) => void = useCallback(debounce((args: string) => {
	// 	const value = args?.[0];
	// 	if (value) {
	// 		handleUpdates({ ...currentCategory, name: value });
	// 	}
	// }, 400, false), []);

	// const handleCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setCategoryName(event?.target?.value);
	// 	updateCategoryName(event?.target?.value);
	// };

	// const toggleEditing = (editingState: boolean) => () => setEditing(editingState);

	const handleBrowse = (value: string) => () => {
		history.push(`/call-log?category=${value}`);
	};

	return (
		<div className={css(styleSheet.cardContainer, styleSheet.flexContainer)}>
			{status === FetchStatus.Working && <div className={css(styleSheet.cardIsBlocked)} />}

			<Group spacingSize={20} vCentered={true}>
				<Group spacingSize={5} vCentered={true} fullWidth={false}>
					<Category value={categoryName} color={currentCategory.hexColor} />
					{/* { isEditing ? (
						<TextInput
							className={ css(styleSheet.textInput) }
							inputId='filter-name-input'
							placeholder='Category Name'
							onChange={ handleCategoryName }
							onBlur={ toggleEditing(false) }
							type='text'
							value={ categoryName }
						/>
					) : (
						<Category
							value={ categoryName }
							color={ currentCategory.hexColor }
						/>
					) }
					{ isEditing ? (
						<TransparentButton onClick={ toggleEditing(false) }>
							<small>Cancel</small>
						</TransparentButton>
					) : (
						<TransparentButton className={ css(styleSheet.editButton) } onClick={ toggleEditing(true) }>
							<EditItemPenIcon fillColor={ brandSecondary } />
						</TransparentButton>
					) } */}
				</Group>

				<Fill />

				<Group vCentered={true} spacingSize={5} fullWidth={false} className={css(styleSheet.fixedSize)}>
					<span className={css(styleSheet.title)}>Color:</span>

					<CategoryCardColors onChange={handleColorChange} defaultColor={currentCategory.hexColor} />
				</Group>

				<Group vCentered={true} spacingSize={5} fullWidth={false} className={css(styleSheet.fixedSize)}>
					<span className={css(styleSheet.title)}>Calls with this tag:</span>
					<span className={css(styleSheet.usage)}>{currentCategory.usage}</span>
				</Group>

				<CategoryCardOptions
					onClickToArchive={handleArchive}
					onClickToBrowse={handleBrowse(categoryName)}
					// onClickToRename={ handleRename }
				/>
			</Group>
			<BlueConfirmationDialog
				icon={<WarningIcon />}
				modalProps={archiveConfirmationModal}
				options={DefaultConfirmationOptions}
				title={`Archive category: ${currentCategory.name}?`}
			/>
		</div>
	);
};
