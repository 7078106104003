import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { styleSheet } from '../styles';
import { SortOptions } from './ListHeader';

interface IProps {
	onSelect: (option: SortOptions) => void;
}

export const ListColumnNav: React.FC<IProps> = ({ onSelect }) => {
	const [selected, setSelected] = useState<SortOptions>(SortOptions.Demos);
	const handleSelection = (option: SortOptions) => () => {
		setSelected(option);
		onSelect(option);
	};
	const options: string[] = Object.keys(SortOptions)?.map(key => key.toLowerCase()) || [];
	return (
		<div className={css(styleSheet.listColumnNav)}>
			{/* @ts-ignore */}
			{options.map((item: SortOptions) => (
				<div
					key={item}
					className={css(styleSheet.columnNavItem, selected === item && styleSheet.columnNavItemSelected)}
					onClick={handleSelection(item)}
				>
					{item}
				</div>
			))}
		</div>
	);
};
