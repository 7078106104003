import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Router } from 'react-router-dom';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { DeprecatedCloseButton } from '../../../../web/components/DeprecatedCloseButton';
import { Modal } from '../../../../web/components/Modal';
import { PrivateRoute } from '../../../../web/components/PrivateRoute';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import { SlotMachinePrizeLegend } from '../SlotMachinePrizeLegend';
import { ISlotMachineModalContext, SlotMachineModalContext } from './context';
import { useSlotMachineFullScreenModal } from './hooks';
import { SlotMachine, SlotMachineLoader } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const SlotMachineModalProvider: React.FC<IProps> = observer(props => {
	const { children } = props;
	const userSession = useUserSession();
	const { fullscreenModal, modalProps } = useSlotMachineFullScreenModal();

	const [slotMachine, setSlotMachine] = React.useState<SlotMachineViewModel>(null);

	const onClose = React.useCallback(() => {
		fullscreenModal.history.goBackToLocationBeforeTrackedHistory(true);

		setSlotMachine(null);
	}, [fullscreenModal.history]);

	const [context] = React.useState<ISlotMachineModalContext>({
		onRequestClose: onClose,
		playSlotMachine: (machine: SlotMachineViewModel) => {
			setSlotMachine(machine);
			// doesn't really matter what we push until we need to support more than the default route
			fullscreenModal.history.push('/');
		},
	});

	React.useEffect(() => {
		if (slotMachine?.tokens.length <= 0 && modalProps?.isOpen) {
			onClose();
		}
	}, [slotMachine?.tokens, modalProps?.isOpen, onClose]);

	return (
		<SlotMachineModalContext.Provider value={context}>
			{children}

			{userSession?.account?.features?.aida.achievementsEnabled ? (
				<Modal
					{...modalProps}
					className={css(styleSheet.modal)}
					isOpen={!!fullscreenModal.hasValidLocation}
					onRequestClose={onClose}
					shouldCloseOnOverlayClick={true}
				>
					<Router history={fullscreenModal.history}>
						<PrivateRoute userSession={userSession}>
							<DeprecatedCloseButton className={css(styleSheet.modalCloseButton)} fillColor='#fff' onClick={onClose} />
							<SlotMachineLoader machineType={slotMachine?.type}>
								<SlotMachine machine={slotMachine} />
								<SlotMachinePrizeLegend
									machineType={slotMachine?.type}
									slotMachine={slotMachine}
									styles={[styleSheet.prizeLegend]}
								/>
							</SlotMachineLoader>
						</PrivateRoute>
					</Router>
				</Modal>
			) : null}
		</SlotMachineModalContext.Provider>
	);
});
