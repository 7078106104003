import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { useViewport } from '../../../hooks/useViewport';
import { AidaDraggable } from '../../AidaDraggable';

export interface IDragState {
	active: boolean;
	x?: number;
	y?: number;
}

export interface IProps {
	isOpen: boolean;
	onDragChange: (dragState: IDragState) => void;
}

export const DraggableWrapper: React.FC<IProps> = observer(({ children, isOpen, onDragChange }) => {
	const [boundingRect, setBoundingRect] = useState<DOMRect>(null);

	const [defaultXY, setDefaultXY] = useState<{ x: number; y: number }>({
		x: 0,
		y: 0,
	});

	const [dragging, setDragging] = useState<IDragState>({
		active: false,
		x: defaultXY.x,
		y: defaultXY.y,
	});

	const setModalPosition = () => {
		const containerElement = document.querySelector('.email-drag-container');
		const containerPosition = containerElement?.getBoundingClientRect();

		const x = window.innerWidth / 2 - containerPosition?.width / 2;

		const y = window.innerHeight / 2 - containerPosition?.height / 2;

		const dragState = { active: isOpen, x, y };

		setBoundingRect(containerPosition);
		setDefaultXY({ x, y });
		setDragging(dragState);
		onDragChange(dragState);
	};

	useEffect(() => {
		setModalPosition();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	useViewport(setModalPosition);

	const onDrag = (event: DraggableEvent, { x, y }: DraggableData) => {
		event.stopPropagation();
		const dragState = { active: true, x, y };
		setDragging(dragState);
		onDragChange(dragState);
	};

	const onDragStop = (_: DraggableEvent, { x, y }: DraggableData) => {
		const { active } = dragging;
		if (active) {
			const offset = 15;
			let cX = x;
			let cY = y;
			if (x <= 0) {
				cX = offset;
			}
			if (y <= 0) {
				cY = offset;
			}
			if (x >= window.innerWidth - boundingRect.width) {
				cX = window.innerWidth - boundingRect.width - offset;
			}
			if (y >= window.innerHeight - boundingRect.height) {
				cY = window.innerHeight - boundingRect.height - offset;
			}
			const dragState = { active: false, x: cX, y: cY };
			setDragging(dragState);
			onDragChange(dragState);
		}
	};

	if (!isOpen) {
		return null;
	}

	return (
		<AidaDraggable
			onDrag={onDrag}
			onStop={onDragStop}
			defaultPosition={{ x: defaultXY.x, y: defaultXY.y }}
			position={{ x: dragging.x, y: dragging.y }}
			bounds={false}
			isDraggable={true}
			defaultClassNameDragging={null}
		>
			{children}
		</AidaDraggable>
	);
});
