import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { copyToClipboard } from '../../../../../models/UiUtils';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useAudio } from '../../../../contexts/audioPlayer';
import { useToaster } from '../../../../hooks';
import { Group } from '../../../generics/Group';
import { ChatUserIcon } from '../../../svgs/icons/ChatUserIcon';
import { ChatUsersIcon } from '../../../svgs/icons/ChatUsersIcon';
import { CopyIcon } from '../../../svgs/icons/CopyIcon';
import { TranscriptionIcon } from '../../../svgs/icons/TranscriptionIcon';
import { PlayIcon } from '../../../svgs/icons/audio/PlayIcon';
import { styleSheet } from '../../LeadViewRowElement/styles';

interface IProps {
	creationDate: string;
	creatorName: string;
	id: string;
	onClickPlay?: () => void;
	phoneNumber: string;
	publicUrl: string;
	recordingChannels?: number;
	transcriptionId?: string;
}

export const RecordingIndicator: React.FC<IProps> = ({
	creationDate,
	creatorName,
	id,
	onClickPlay,
	phoneNumber,
	publicUrl,
	recordingChannels,
	transcriptionId,
}) => {
	const audio = useAudio();
	const toaster = useToaster();
	const userSession = useUserSession();

	const onAudioPlay = (event: React.MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
		audio.setIsOpen(true)();
		audio.setAudioSrc(publicUrl);
		audio.setTitle(`Call Recording (${moment(creationDate).format('M/DD/YYYY [at] h:mma')})`);
		audio.setSubtitle(`${creatorName} called ${phoneNumber}`);
		audio.setPhoneCallId(id);
	};

	const copyAudioLink = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		event.stopPropagation();
		const url = `${userSession?.webServiceHelper?.baseUrl}/PhoneCall/${id}/Download`;
		if (copyToClipboard(url)) {
			toaster.push({
				message: `Link copied to clipboard`,
				type: 'successMessage',
			});
		} else {
			toaster.push({
				message: `Email failed to copy to clipboard`,
				type: 'errorMessage',
			});
		}
	};

	const handleClickPlay = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		event.stopPropagation();
		onClickPlay?.();
	};

	const renderTranscription = transcriptionId && (
		<Group vCentered spacingSize={5} className={css(styleSheet.transcriptionComponents)}>
			<TranscriptionIcon />

			{recordingChannels > 1 ? <ChatUsersIcon fillColor='#CDAD07' /> : <ChatUserIcon fillColor='#CDAD07' />}
		</Group>
	);

	if (publicUrl) {
		if (onClickPlay) {
			return (
				<Group vCentered spacingSize={0}>
					<div className={css(styleSheet.reportLink, styleSheet.copyAudioLink)} onClick={handleClickPlay}>
						<div className={css(styleSheet.reportLink, styleSheet.fontSizeSmall)}>Play</div>
						<PlayIcon fillColor='#D3DBDB' />
					</div>
					{renderTranscription}
				</Group>
			);
		}
		return (
			<div className={css(styleSheet.audioColumn)}>
				<div className={css(styleSheet.reportLink, styleSheet.copyAudioLink)} onClick={onAudioPlay}>
					<div className={css(styleSheet.reportLink, styleSheet.fontSizeSmall)}>Play</div>
					<PlayIcon fillColor='#D3DBDB' />
				</div>
				{userSession?.account?.features?.aida?.allowAnonymousAccessToCallRecording && (
					<div className={css(styleSheet.reportLink, styleSheet.copyAudioLink)} onClick={copyAudioLink}>
						<CopyIcon fillColor='#D3DBDB' />
					</div>
				)}
				{renderTranscription}
			</div>
		);
	}

	return <div className={css(styleSheet.empty, styleSheet.fontSizeSmall)}>None</div>;
};
