import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FilterOperator } from '../../../../extViewmodels';
import { debounce } from '../../../../models/UiUtils';
import { Checkbox } from '../../../../web/components/Checkbox';
import { ISelectOption } from '../../../../web/components/Select';
import { TextInput } from '../../../../web/components/TextInput';
import { TagListBuilder } from '../../../../web/components/entities/tags/TagListBuilder';
import { TagExpandIcon } from '../../../../web/components/svgs/icons/TagExpandIcon';
import { TagNarrowIcon } from '../../../../web/components/svgs/icons/TagNarrowIcon';
import { FieldKey } from '../../../viewModels/form';
import { LeadRuleViewModel } from '../../../viewModels/leadrules';
import { Group } from '../../leadView/components/Group';
import { OutcomeSelect } from '../../leadView/components/OutcomeSelect';
import { filterByChecked, reduceChildren } from '../../leadView/components/OutcomeSelect/utils';
import { styleSheet } from './../styles';
import { useTagList } from './../utils';

interface IProps {
	leadRule: LeadRuleViewModel;
}

export const EditFilters: React.FC<IProps> = observer(({ leadRule }) => {
	const [publicName, setPublicName] = useState('');
	const [blitzName, setBlitzName] = useState('');

	const [orTags, addOrTag, removeOrTag, setOrTags] = useTagList([], false);
	const [andTags, addAndTag, removeAndTag, setAndTags] = useTagList([], false);
	const [notTags, addNotTag, removeNotTag, setNotTags] = useTagList([], false);
	const [outcomes, setOutcomes] = useState([]);

	const [showingAndTags, setShowingAndTags] = useState(false);
	const [showingNotTags, setShowingNotTags] = useState(false);
	const [showingOutcomesSelect, setShowingOutcomesSelect] = useState(false);

	useEffect(() => {
		if (leadRule) {
			const tags = leadRule?.filterToArrays;
			setOrTags(tags.or);
			setAndTags(tags.and);
			setNotTags(tags.not);

			setOutcomes(tags.outcomes);
			setShowingAndTags(!!tags.and.length);
			setShowingNotTags(!!tags.not.length);
			setShowingOutcomesSelect(!!tags.outcomes.length);

			setPublicName(leadRule?.name);

			setBlitzName(leadRule?.identifier);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadRule]);

	useEffect(() => {
		leadRule?.setFilterFromArrays(andTags, orTags, notTags, outcomes);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orTags, andTags, notTags, outcomes]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updatePublicName: (args: string) => void = useCallback(
		debounce(
			args => {
				leadRule?.setName(args[0]);
			},
			1000,
			false
		),
		[]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updateBlitzName: (args: string) => void = useCallback(
		debounce(
			args => {
				leadRule?.setIdentifier(args[0]);
			},
			1000,
			false
		),
		[]
	);

	const handleName = (isPublic?: boolean) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (isPublic) {
			setPublicName(event?.target?.value);
			updatePublicName(event?.target?.value);
			return;
		}
		setBlitzName(event?.target?.value);
		updateBlitzName(event?.target?.value);
	};

	const handleNotCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowingNotTags(event.target.checked);
		setNotTags([]);
	};

	const handleAndCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowingAndTags(event.target.checked);
		setAndTags([]);
	};

	const handleOutcomesCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowingOutcomesSelect(event.target.checked);
		setOutcomes([]);
	};

	const handleOutcomesChange = (selectedOutcome: ISelectOption<FieldKey>[]) => {
		setOutcomes(
			selectedOutcome
				?.reduce(reduceChildren, [])
				?.filter(filterByChecked)
				?.map((i: ISelectOption<FieldKey>) => i.name)
		);
	};

	return (
		<div className={css(styleSheet.editFilterContainer)}>
			<Group vertical={true} spacingSize={4}>
				<Group vCentered={true}>
					<div className={css(styleSheet.filterNameTitle)}>Blitz Name:</div>
					<TextInput
						className={css(styleSheet.filterNameInput)}
						inputId='public-name-input'
						placeholder='Blitz Name'
						onChange={handleName(true)}
						type='text'
						value={publicName}
					/>
				</Group>
				<Group vCentered={true}>
					<div className={css(styleSheet.filterNameTitle)}>Blitz ID:</div>
					<TextInput
						className={css(styleSheet.filterNameInput)}
						inputId='blitz-name-input'
						placeholder='Blitz ID'
						onChange={handleName()}
						type='text'
						value={blitzName}
					/>
				</Group>
			</Group>
			<div className={css(styleSheet.tagSection)}>
				<div>
					<TagExpandIcon />
				</div>
				<div>
					<div className={css(styleSheet.tagSectionTitle)}>
						Included tags (this will <span className={css(styleSheet.bold)}>expand</span> the list)
					</div>
					<TagListBuilder
						className={css(styleSheet.tagListBuilderContainer)}
						separator={FilterOperator.Or}
						tags={orTags}
						inputId='add-or-tags'
						onTagAdded={addOrTag}
						onTagRemoved={removeOrTag}
					/>
				</div>
			</div>
			<div className={css(styleSheet.tagSection)}>
				<div>
					<TagNarrowIcon />
				</div>
				<div>
					<div className={css(styleSheet.tagSectionTitle)}>
						<span className={css(styleSheet.bold)}>Narrow</span> the list
					</div>
					<Checkbox
						className={css(styleSheet.tagCheckbox)}
						id='and-tags'
						checked={showingAndTags}
						onChange={handleAndCheckChange}
					>
						<span className={css(styleSheet.checkboxText)}>Contacts must also have these tags...</span>
					</Checkbox>
					{showingAndTags ? (
						<TagListBuilder
							className={css(styleSheet.tagListBuilderContainer)}
							separator={FilterOperator.And}
							tags={andTags}
							inputId='add-and-tags'
							onTagAdded={addAndTag}
							onTagRemoved={removeAndTag}
						/>
					) : null}

					<Checkbox
						className={css(styleSheet.tagCheckbox)}
						id='not-tags'
						checked={showingNotTags}
						onChange={handleNotCheckChange}
					>
						<span className={css(styleSheet.checkboxText)}>Exclude these tags...</span>
					</Checkbox>
					{showingNotTags ? (
						<TagListBuilder
							className={css(styleSheet.tagListBuilderContainer)}
							separator={FilterOperator.Not}
							tags={notTags}
							inputId='add-not-tags'
							onTagAdded={addNotTag}
							onTagRemoved={removeNotTag}
						/>
					) : null}
					<Checkbox
						className={css(styleSheet.tagCheckbox)}
						id='outcomes'
						checked={showingOutcomesSelect}
						onChange={handleOutcomesCheckChange}
					>
						<span className={css(styleSheet.checkboxText)}>Only include the following outcomes...</span>
					</Checkbox>
					{showingOutcomesSelect ? (
						<div className={css(styleSheet.customMultiselect)}>
							<OutcomeSelect
								closeOnClickInside={true}
								defaultChecked={false}
								defaultValues={outcomes}
								isInline={true}
								isMulti={true}
								onChange={handleOutcomesChange}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
});
