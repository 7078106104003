import * as React from 'react';
import { ISelectOption, Select } from '../../../../../web/components/Select';
import { styleSheet } from './styles';

interface IProps {
	disabled?: boolean;
	onChange?: (item: ISelectOption<string>) => void;
	selected?: ISelectOption<string>;
}

const options: ISelectOption<string>[] = [
	{
		dataContext: null,
		id: 'all',
		text: 'All Channels',
	},
	{
		dataContext: '1',
		id: '1',
		text: 'Rep Only',
	},
	{
		dataContext: '2',
		id: '2',
		text: 'All Parties',
	},
];

export const RecordingChannelsSelect: React.FC<IProps> = ({ disabled, onChange, selected }) => {
	const onClick = (option: ISelectOption<string>) => {
		onChange(option);
	};

	const selectedOption = options.find(x => x.dataContext === selected?.dataContext ?? null) || options[0];

	return (
		<Select
			dropdownStyles={[styleSheet.selectDropdown]}
			onOptionClick={onClick}
			options={options}
			disabled={disabled}
			selectedOption={selectedOption}
			styles={[styleSheet.select]}
		/>
	);
};
