import * as React from 'react';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';

export interface ISlotMachineModalContext {
	onRequestClose(): void;
	playSlotMachine(machine: SlotMachineViewModel): void;
}

export const SlotMachineModalContext = React.createContext<ISlotMachineModalContext>(null);

export const useSlotMachineModalContext = () => {
	const context = React.useContext(SlotMachineModalContext);
	if (!context) {
		throw new Error("Use of this component requires 'SlotMachineModalContext'");
	}
	return context;
};
