import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { IRange } from '../../../../extViewmodels';
import { DateRangePickerModal } from '../../../../web/components/DateRangePicker';
import { GoalsViewModel } from '../../../viewModels/goals';
import { styleSheet } from './styles';

interface IProps {
	defaultDate?: Date;
	className?: string;
	styles?: StyleDeclarationValue[];
	goals?: GoalsViewModel;
}

export const GoalDatePicker: React.FC<IProps> = observer(props => {
	const { defaultDate = new Date(), goals, className, styles = [] } = props;
	const [startDate, setStartDate] = useState(defaultDate);
	const [endDate, setEndDate] = useState(defaultDate);
	const [showPicker, setShowPicker] = useState(false);

	useEffect(() => {
		goals.reset();

		goals.startDate = startDate;

		goals.endDate = endDate;

		goals.load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	const onShowPicker = () => setShowPicker(true);

	const onDateRangePickerRequestClose = (range?: IRange<Date>, cancel?: boolean) => {
		if (range && !cancel) {
			setStartDate(range.start);

			setEndDate(range.end);
		}

		setShowPicker(false);
	};

	return (
		<div className={css(styleSheet.datePickerContainer)}>
			<div
				className={`${css(styleSheet.container, ...styles)} goal-date-picker ${className || ''}`}
				onClick={onShowPicker}
			>
				{moment(startDate).format('MM-DD-yyyy')}
			</div>
			<div
				className={`${css(styleSheet.container, ...styles)} goal-date-picker ${className || ''}`}
				onClick={onShowPicker}
			>
				{moment(endDate).format('MM-DD-yyyy')}
			</div>
			<DateRangePickerModal
				allowPastDates={true}
				modalProps={{
					isOpen: showPicker,
					onRequestClose: onDateRangePickerRequestClose,
				}}
			/>
		</div>
	);
});
