import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { CherriesSymbol } from '../CherriesSymbol';
import { LevCoinSymbol } from '../LevCoinSymbol';
import { LuckySevenSymbol } from '../LuckySevenSymbol';
import { QuestionMarkSymbol } from '../QuestionMarkSymbol';
import { TigerKingSymbol } from '../TigerKingSymbol';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	symbolStyles?: StyleDeclarationValue[];
	symbols: Api.SlotMachineSymbol[];
}

export const SlotMachineSymbolGroup: React.FC<IProps> = props => {
	const { className, styles = [], symbols, symbolStyles } = props;

	const getComponent = (symbol: Api.SlotMachineSymbol) => {
		let SymbolComponent: React.ComponentType<{ styles?: StyleDeclarationValue[] }> = null;
		switch (symbol) {
			// case Api.SlotMachineSymbol.Bar: {
			// 	SymbolComponent = BarSymbol;
			// 	break;
			// }
			case Api.SlotMachineSymbol.Cherry: {
				SymbolComponent = CherriesSymbol;
				break;
			}
			case Api.SlotMachineSymbol.LevitateCoin: {
				SymbolComponent = LevCoinSymbol;
				break;
			}
			case Api.SlotMachineSymbol.Seven: {
				SymbolComponent = LuckySevenSymbol;
				break;
			}
			case Api.SlotMachineSymbol.QuestionMark: {
				SymbolComponent = QuestionMarkSymbol;
				break;
			}
			case Api.SlotMachineSymbol.Tiger: {
				SymbolComponent = TigerKingSymbol;
				break;
			}
			default: {
				return null;
			}
		}
		return SymbolComponent;
	};

	return (
		<div className={`${css(styleSheet.container, ...styles)} slot-machine-symbol-group ${className || ''}`}>
			{symbols.map((symbol, i) => {
				const SymbolComponent = getComponent(symbol);

				return <SymbolComponent key={i} styles={symbolStyles} />;
			})}
		</div>
	);
};
