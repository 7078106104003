import { CherriesSymbol } from '../../svgs/slotMachines/CherriesSymbol';
import { LevCoinSymbol } from '../../svgs/slotMachines/LevCoinSymbol';
import { LuckySevenSymbol } from '../../svgs/slotMachines/LuckySevenSymbol';
import { QuestionMarkSymbol } from '../../svgs/slotMachines/QuestionMarkSymbol';
import { TigerKingSymbol } from '../../svgs/slotMachines/TigerKingSymbol';
// import { BarSymbol } from '../../svgs/slotMachines/BarSymbol';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	style?: React.CSSProperties | ((baseProperties: React.CSSProperties) => React.CSSProperties);
	styles?: StyleDeclarationValue[];
	type: Api.SlotMachineSymbol;
}

export const SlotMachineSymbol: React.FC<IProps> = props => {
	const { className, styles = [], type, style: styleProvider, ...restProps } = props;
	const classNameValue = `${css(...styles)} slot-machine-symbol-${type} ${className || ''}`;
	let style: React.CSSProperties = { height: 'auto', width: 'auto' };
	if (typeof styleProvider === 'function') {
		style = styleProvider(style);
	} else if (styleProvider) {
		style = { ...style, ...styleProvider };
	}

	let Component: React.ComponentType<{
		className?: string;
		style: React.CSSProperties;
		styles?: StyleDeclarationValue[];
	}> = null;
	switch (type) {
		// case Api.SlotMachineSymbol.Bar: {
		// 	Component = BarSymbol;
		// 	break;
		// }
		case Api.SlotMachineSymbol.Cherry: {
			Component = CherriesSymbol;
			break;
		}
		case Api.SlotMachineSymbol.LevitateCoin: {
			Component = LevCoinSymbol;
			break;
		}
		case Api.SlotMachineSymbol.Seven: {
			Component = LuckySevenSymbol;
			break;
		}
		case Api.SlotMachineSymbol.QuestionMark: {
			Component = QuestionMarkSymbol;
			break;
		}
		case Api.SlotMachineSymbol.Tiger: {
			Component = TigerKingSymbol;
			break;
		}
		default: {
			break;
		}
	}
	if (!Component) {
		return null;
	}
	return <Component {...restProps} style={style} className={classNameValue} />;
};
