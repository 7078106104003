import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ILeaderboardDemos } from '../../viewModels/gamification';
import { Group } from '../leadView/components/Group';
import { DemosList, IDemosListRowItem } from './components/DemosList';
import { styleSheet } from './styles';

export interface ILeaderBoardProps {
	renderEmptyState?: string | JSX.Element;
	renderFilter: JSX.Element;
	scores: ILeaderboardDemos;
}

export const DemosScore: React.FC<ILeaderBoardProps> = ({ renderEmptyState, renderFilter, scores }) => {
	const [data, setData] = useState<ILeaderboardDemos>(null);

	useEffect(() => {
		if (scores) {
			setData(scores);
		}
	}, [scores]);

	if (!data) {
		return null;
	}

	const verticalTotal = data?.totalVerticalLeaderboard?.totalStatistics;
	verticalTotal.goal = Math.round(verticalTotal.goal || 0);
	const verticalStats = data?.totalVerticalLeaderboard?.verticalStatistics;
	const verticalNames = Object.keys(data?.totalVerticalLeaderboard?.verticalStatistics || {});
	const verticalRows: IDemosListRowItem[] = [];
	verticalNames.forEach(name => {
		verticalRows.push({
			demos: verticalStats[name]?.demos,
			goal: Math.round(verticalStats[name]?.goal || 0),
			label: name,
			name,
		});
	});

	const roles = data?.roleLeaderboards;
	const roleNames = Object.keys(roles || {});

	const totalDials = new Intl.NumberFormat('en-IN').format(data?.totalDials || 0);

	return (
		<div className={css(styleSheet.leaderBoardContainer)}>
			<Group className={css(styleSheet.demosTotalDialsDisplayContainer)} spacingSize={20} vertical={true}>
				{renderFilter}
				<div className={css(styleSheet.demosTotalDialsDisplay)}>{totalDials} Dials</div>
			</Group>
			{renderEmptyState ? (
				renderEmptyState
			) : (
				<Group vertical={true} spacingSize={40}>
					<DemosList label='Verticals' rows={verticalRows} total={verticalTotal} />
					{roleNames.map(name => {
						const total = roles[name]?.totalStatistics;
						total.goal = Math.round(total.goal || 0);
						const stats = roles[name]?.verticalStatistics;
						const names = Object.keys(roles[name]?.verticalStatistics || {});
						const rows: IDemosListRowItem[] = [];
						names.forEach(key => {
							rows.push({
								demos: stats[key]?.demos,
								goal: Math.round(stats[key]?.goal || 0),
								label: key,
								name: key,
							});
						});
						return <DemosList key={name} label={name} rows={rows} total={total} />;
					})}
				</Group>
			)}
		</div>
	);
};
