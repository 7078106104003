import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import equal from 'fast-deep-equal';
import produce from 'immer';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import Waypoint from 'react-waypoint';
import { IAutomationInfo, SizeConstraint } from '../../../../models';
import * as AppState from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { ILocalNotification } from '../../../../models/LocalNotifications';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	createContentStateWithHtmlStringValue,
	getDisplayName,
} from '../../../../models/UiUtils';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { LoadingSpinner } from '../../LoadingSpinner';
import { LocalNotificationObserver } from '../../LocalNotificationObserver';
import { IMoreMenuItem } from '../../MoreMenu';
import { Placeholder } from '../../Placeholder';
import { Portal } from '../../Portal';
import { TimelineScheduledMeeting } from '../../TimelineScheduledMeeting';
import { ActionItemFeedCard } from '../../cards/ActionItemFeedCard';
import { DefaultCompactEditorConfig } from '../../notes/NoteEditor';
import { RichContentDocumentEditor } from '../../richContent/RichContentDocumentEditor';
import { NotesPlaceholderIcon } from '../../svgs/icons/NotesPlaceholderIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { EntityConversationContent } from '../EntityConversationContent';
import { EntityModalContent } from '../EntityModalContent';
import {
	EntityTimelineEventAction,
	EntityTimelineEventDateHeader,
	EntityTimelineEventIcon,
	EntityTimelineEventTitle,
	EntityTimelineNoteEventContent,
	EntityTimelineSatisfactionSurveyResponseEventContent,
} from './presentation';
import { styleSheet } from './styles';

interface IProps<
	TEntity extends Api.IEntity = Api.IEntity,
	TEntityViewModel extends Api.EntityViewModel<TEntity> = Api.EntityViewModel<TEntity>,
> extends IEventLoggingComponentProps,
		AppState.IUserSessionComponentProps,
		AppState.INoteComposerComponentProps,
		AppState.IActionItemComposerComponentProps,
		AppState.ISingleEmailComposerComponentProps,
		AppState.IErrorMessageComponentProps,
		AppState.ITextMessagingComponentProps,
		RouteComponentProps<any> {
	className?: string;
	entity: TEntityViewModel;
	hideActionItems?: boolean;
	idsToExclude?: string[];
	onEntityClicked?(entity: Api.EntityViewModel<Api.IEntity>, e: React.MouseEvent<HTMLElement>): void;
	onMoreMenuOptionClicked?(
		richContent: Api.RichContentViewModel,
		option: 'edit' | 'delete',
		e: React.MouseEvent<HTMLElement>
	): void;
	readonly?: boolean;
	renderAction?(evt: Api.TimelineEventViewModel): JSX.Element;
	renderActionFor?: Api.TimelineEventTypes[];
	renderContent?(evt: Api.TimelineEventViewModel): JSX.Element;
	renderContentFor?: Api.TimelineEventTypes[];
	placeholderText?: string;
	scrollToBottomWaypointPortalId?: string;
	sendMessageDisabled?: boolean;
	sizeConstraint?: SizeConstraint;
	eventTypes: Api.TimelineEventTypes[];
}

function isSameDay(d1: Date, d2: Date) {
	return d1.getDate() === d2.getDate() && d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
}

interface IState {
	showDeletingRichContentModal?: Api.RichContentViewModel;
}

export class _EntityTimeline<
	TEntity extends Api.IEntity = Api.IEntity,
	TEntityViewModel extends Api.EntityViewModel<TEntity> = Api.EntityViewModel<TEntity>,
> extends React.Component<IProps<TEntity, TEntityViewModel>> {
	private mMounted: boolean;
	private mTimeout: number;
	@observable private mTimeline: Api.EntityTimelineViewModel;

	public readonly state: IState = {
		showDeletingRichContentModal: null,
	};

	constructor(props: IProps<TEntity, TEntityViewModel>) {
		super(props);
		// FOLLOWUP: Resolve

		// @ts-ignore
		this.mTimeline = new Api.EntityTimelineViewModel(props.entity);
	}

	public componentDidMount() {
		this.loadMoreContent();
		this.mMounted = true;
	}

	// FOLLOWUP: Resolve

	// @ts-ignore
	public componentDidUpdate(prevProps: IProps) {
		if (!equal(this.props.eventTypes, prevProps.eventTypes)) {
			this.mTimeline.controller.reset();
			this.loadMoreContent();
			return;
		}

		if (!equal(prevProps, this.props)) {
			this.loadMoreContent();
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
		window.clearTimeout(this.mTimeout);
	}

	public render() {
		const { className, idsToExclude = [], sizeConstraint, placeholderText, entity } = this.props;
		const { showDeletingRichContentModal } = this.state;
		const list: (Date | Api.TimelineEventViewModel)[] = produce(
			this.mTimeline.controller.fetchResults.backingArray,
			// FOLLOWUP: Resolve

			// @ts-ignore
			newFetchResults => {
				const result = [];
				let prevTime: Date = null;
				for (const event of newFetchResults) {
					if (!idsToExclude.find(id => id === (event as any)?.viewmodel?.id)) {
						const sameDay = isSameDay(new Date(event.timestamp), new Date(prevTime));
						if (!sameDay || prevTime === null) {
							result.push(new Date(event.timestamp));
						}
						result.push(event);
						prevTime = new Date(event.timestamp);
					}
				}
				return result;
			}
		);

		const controller = this.mTimeline.controller;
		const isEmpty = controller.hasFetchedFirstPage && list.length < 1;
		return (
			<ul className={`${css(styleSheet.container)} entity-rich-content ${className || ''}`}>
				{isEmpty ? (
					<Placeholder
						isEmpty={isEmpty}
						loaderType={sizeConstraint === 'compact' ? 'small' : 'large'}
						message={placeholderText || "You haven't added any notes or reminders yet"}
						placeholderIcon={<NotesPlaceholderIcon />}
					/>
				) : (
					!!entity && list.map(x => this.renderEvent(x))
				)}
				{!!entity && !controller.isFetching && controller.fetchResults?.length > 0 && this.renderBottomWaypoint()}
				{controller.isFetching && <LoadingSpinner type='large' />}
				<LocalNotificationObserver
					topic={Topics.CREATE_ACTION_ITEM}
					onNotificationReceived={this.onCreateOrUpdateNotificationReceived}
				/>
				<LocalNotificationObserver
					topic={Topics.EDIT_ACTION_ITEM}
					onNotificationReceived={this.onCreateOrUpdateNotificationReceived}
				/>
				<LocalNotificationObserver
					topic={Topics.TIMELINE_REFRESH}
					onNotificationReceived={this.onTimelineRefreshNotificationReceived}
				/>
				<LocalNotificationObserver
					topic={Topics.CREATE_NOTE}
					onNotificationReceived={this.onNoteCreatedNotificationReceived}
				/>
				<LocalNotificationObserver topic={Topics.SEND_EMAIL} onNotificationReceived={this.onEmailsSent} />
				<LocalNotificationObserver topic={Topics.CREATE_AUTOMATION} onNotificationReceived={this.onAutomationEdited} />
				<LocalNotificationObserver topic={Topics.DELETE_AUTOMATION} onNotificationReceived={this.onAutomationEdited} />
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: !!showDeletingRichContentModal,
						onRequestClose: this.onDeleteContentConfirmationDialogRequestClose,
					}}
					options={[
						{
							isDestructive: true,
							representedObject: true,
							title: 'Delete',
						},
						{
							isCancel: true,
							representedObject: false,
							title: 'Cancel',
						},
					]}
					title={`Are you sure you want to delete this ${
						showDeletingRichContentModal?.toJs()?._type === 'ActionItem' ? 'action item' : 'note'
					}?`}
				/>
			</ul>
		);
	}

	private onAutomationEdited = (notification?: ILocalNotification<IAutomationInfo>) => {
		const { entity } = this.props;
		if (
			!!entity?.id &&
			notification?.info?.contact?.id === entity.id &&
			notification?.info?.automation?.steps?.some(x => x.step?._type === 'ActionItemAutomationStep')
		) {
			// reload
			this.mTimeline.controller.reset();
			this.loadMoreContent();
		}
	};

	private onEmailsSent = (notification: ILocalNotification<Api.EmailMessageViewModel>) => {
		const { entity } = this.props;
		if (!!entity && entity instanceof Api.ContactViewModel) {
			if (!!notification.info.options && !!notification.info.options.saveAsNote) {
				let scheduleReload = false;
				if (notification.info instanceof Api.EmailMessageViewModel) {
					const entityEmailAddresses = (entity.emailAddresses || []).map(x => x.value).filter(x => !!x);
					const emailMessage = notification.info;
					if (emailMessage.contactsToAdd.length > 0) {
						const recipientIds = emailMessage.contactsToAdd
							.filter(x => !emailMessage.contactsToOmit.has(x))
							.map(x => x.id);
						scheduleReload = recipientIds.indexOf(entity.id) >= 0;
					} else if (entityEmailAddresses.length > 0) {
						const addresses = (emailMessage.contactsToAdd.toArray() || [])
							.map(y => {
								const emailAddress = emailMessage.getPreferredEmailAddressForContact(y);
								return !!emailAddress && !!emailAddress.value ? emailAddress.value : null;
							})
							.filter(z => !!z);
						const matchingEmail = entityEmailAddresses.find(x => {
							return !!addresses.find(y => y === x);
						});
						scheduleReload = !!matchingEmail;
					}
				}

				if (scheduleReload) {
					// defer a reload
					setTimeout(() => {
						if (this.mMounted) {
							this.mTimeline.controller.reset();
							this.loadMoreContent();
						}
					}, 1000);
				}
			}
		}
	};

	private onTimelineRefreshNotificationReceived = () => {
		this.mTimeline.controller.reset();
		this.loadMoreContent();
	};

	private onNoteCreatedNotificationReceived = (notification: ILocalNotification<Api.INote>) => {
		const { userSession, entity } = this.props;
		if (entity && !this.mTimeline.controller.isFetching) {
			const noteModel = notification.info;
			if (noteModel.referencedEntities) {
				const entityIds = [...noteModel.referencedEntities.contacts, ...noteModel.referencedEntities.companies].map(
					x => x.entity.id
				);
				if (entityIds.indexOf(entity.id) >= 0) {
					const contacts = noteModel.referencedEntities?.contacts || [];
					const event =
						noteModel.context?.source === Api.RichContentContextSource.PhoneCall
							? new Api.PhoneCallEventViewModel(userSession, {
									...noteModel,
									_type: 'PhoneCallEvent',
									note: noteModel,
									timestamp: noteModel.creationDate,
									title: `${getDisplayName(userSession.user)} had a phone call`,
								})
							: new Api.NoteEventViewModel(userSession, {
									...noteModel,
									_type: 'NoteEvent',
									note: noteModel,
									timestamp: noteModel.creationDate,
									title: `${getDisplayName(userSession.user)} created a note about ${
										contacts.length > 0
											? contacts?.length > 1
												? `${contacts.length} contacts`
												: getDisplayName(contacts?.[0]?.entity)
											: 'this call'
									}`,
								});
					this.mTimeline.controller.fetchResults.splice(0, 0, event);
				}
			}
		}
	};

	private onCreateOrUpdateNotificationReceived = (notification: ILocalNotification<Api.IActionItem>) => {
		const { userSession, entity } = this.props;
		const actionItemModel = notification.info;

		const entityIds = [
			...actionItemModel.referencedEntities.contacts,
			...actionItemModel.referencedEntities.companies,
		].map(x => x.entity.id);
		if (entityIds.indexOf(entity.id) >= 0) {
			let index = -1;
			const existingActionItem = entity.richContent.find((x, i) => {
				const found = x.id === actionItemModel.id;
				index = i;
				return found;
			});
			const contacts = actionItemModel.referencedEntities?.contacts;
			const actionItemEvent = new Api.ActionItemEventViewModel(userSession, {
				...actionItemModel,
				_type: 'ActionItemEvent',
				resourceId: actionItemModel.id,
				timestamp: actionItemModel.creationDate,
				title: `${getDisplayName(userSession.user)} created an action item about ${
					contacts?.length > 1 ? `${contacts.length} contacts` : getDisplayName(contacts?.[0].entity)
				}`,
			});
			actionItemEvent.viewmodel.load();
			if (!!existingActionItem && index >= 0) {
				// replace existing
				this.mTimeline.controller.fetchResults.splice(index, 1, actionItemEvent);
			} else {
				// convert to vm and add it
				this.mTimeline.controller.fetchResults.splice(0, 0, actionItemEvent);
			}
			this.mTimeout = window.setTimeout(() => {
				this.mTimeline.controller.reset();
				this.loadMoreContent();
			}, 1000);
		}
	};

	private renderBottomWaypoint() {
		const { scrollToBottomWaypointPortalId } = this.props;
		const waypoint = <Waypoint bottomOffset='-600px' onEnter={this.loadMoreContent} />;
		if (scrollToBottomWaypointPortalId) {
			return <Portal destination={scrollToBottomWaypointPortalId}>{waypoint}</Portal>;
		}

		return waypoint;
	}

	private loadMoreContent = () => {
		const { logApiError, eventTypes, entity } = this.props;
		if (entity && !this.mTimeline.controller.isFetching) {
			this.mTimeline.controller
				.getNext(null, 25, {
					typeOf: eventTypes,
				})
				.catch(error => logApiError('RichContentLoad-Error', error));
		}
	};

	private renderEvent(event: Api.TimelineEventViewModel | Date) {
		const { renderContent, renderContentFor, renderAction, renderActionFor, readonly } = this.props;
		if (!(event instanceof Api.TimelineEventViewModel)) {
			return <EntityTimelineEventDateHeader date={event} key={event.toUTCString()} />;
		}

		return (
			<div className={css(styleSheet.eventContainer)} key={event.id} data-id={event.id}>
				<div className={css(styleSheet.eventTime)}>{moment(event.timestamp).format('h:mm A')}</div>
				<div className={css(styleSheet.eventIconContainer)}>
					<div className={css(styleSheet.eventIcon)}>
						<EntityTimelineEventIcon event={event} />
					</div>
					<div className={css(styleSheet.eventFillLine)} />
				</div>
				<div className={css(styleSheet.eventBodyContainer)}>
					<div className={css(styleSheet.eventTitleContainer)}>
						<EntityTimelineEventTitle event={event} />
						<div className={css(styleSheet.eventAccessory)}>
							{!!renderAction && renderActionFor?.includes(event.type) ? (
								renderAction(event)
							) : (
								<EntityTimelineEventAction
									event={event}
									onMoreMenuItemClicked={this.onMoreMenuItemClicked}
									readonly={readonly}
								/>
							)}
						</div>
					</div>
					<div className={css(styleSheet.eventContent)}>
						{!!renderContent && renderContentFor?.includes(event.type)
							? renderContent(event)
							: this.renderContent(event)}
					</div>
				</div>
			</div>
		);
	}

	private renderContent(event: Api.TimelineEventViewModel) {
		const { entity } = this.props;

		switch (event.type) {
			case 'ReceivedEmailEvent':
			case 'ViewedEmailEvent':
			case 'RepliedEmailEvent':
			case 'SentEmailEvent': {
				const evt = event as Api.SentEmailEventViewModel;
				if (evt.viewmodel?.rawContentState) {
					return <EntityModalContent event={evt} />;
				}
				return null;
			}
			case 'DealCreatedEvent':
			case 'DealUpdatedEvent': {
				const noteEvt = event as Api.NoteEventViewModel;
				if (noteEvt.viewmodel.rawContentState) {
					return (
						<>
							<RichContentDocumentEditor
								autoFocus={false}
								className='note-editor-body-editor'
								config={DefaultCompactEditorConfig}
								contentState={convertRawRichTextContentStateToRichContentEditorState(noteEvt.viewmodel.rawContentState)}
								readOnly={true}
							/>
							<TimelineScheduledMeeting scheduledMeeting={event?.scheduledMeeting} />
						</>
					);
				}

				return <TimelineScheduledMeeting scheduledMeeting={event?.scheduledMeeting} />;
			}
			case 'NoteEvent': {
				const note = event as Api.NoteEventViewModel;
				if (note.viewmodel.rawContentState) {
					return <EntityTimelineNoteEventContent note={note} />;
				}
				return null;
			}
			case 'PhoneCallCompletedEvent':
			case 'PhoneCallEvent':
			case 'UntrackedPhoneCallEvent':
			case 'SkipLeadEvent': {
				const note = event as Api.NoteEventViewModel;
				if (note.viewmodel.rawContentState) {
					return (
						<RichContentDocumentEditor
							autoFocus={false}
							className='note-editor-body-editor'
							config={DefaultCompactEditorConfig}
							contentState={convertRawRichTextContentStateToRichContentEditorState(note.viewmodel.rawContentState)}
							readOnly={true}
						/>
					);
				}
				return null;
			}
			case 'ActionItemEvent': {
				const vm = (event as Api.ActionItemEventViewModel).viewmodel;
				return <ActionItemFeedCard actionItem={vm} moreMenuDisabled={true} />;
			}
			case 'ConversationThreadEvent': {
				const conversation = event as Api.ConversationThreadEventViewModel;
				return <EntityConversationContent event={conversation} entity={entity?.toJs()} />;
			}
			case 'SatisfactionSurveyResponseEvent': {
				const satisfactionSurveyResponseEvent = event as Api.SatisfactionSurveyResponseEventViewModel;
				return <EntityTimelineSatisfactionSurveyResponseEventContent event={satisfactionSurveyResponseEvent} />;
			}
			case 'HandwrittenCardOrderEvent': {
				const cardOrder = event as Api.HandwrittenCardOrderEventViewModel;
				const signature = cardOrder.cardSignature ? `<p>${cardOrder.cardSignature}</p>` : '';
				const content = `<p>${cardOrder.cardContent}</p>${signature}`;
				return (
					<RichContentDocumentEditor
						autoFocus={false}
						className='note-editor-body-editor'
						config={DefaultCompactEditorConfig}
						contentState={createContentStateWithHtmlStringValue(content)}
						readOnly={true}
					/>
				);
				return null;
			}
			case 'MeetingEvent':
			case 'MeetingScheduledEvent':
			case 'ExternalSentEmailEvent':
			default: {
				return null;
			}
		}
	}

	private onDeleteContentConfirmationDialogRequestClose = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		const { showDeletingRichContentModal } = this.state;
		const { logInput, logApiError, errorMessages, entity } = this.props;
		if (!!result && !canceled && !!showDeletingRichContentModal) {
			logInput('MoreMenuDeleteNote', 'Click', {
				id: showDeletingRichContentModal.id,
			});
			entity
				.deleteRichContent(showDeletingRichContentModal)
				?.then(() => {
					const item = this.mTimeline.controller.fetchResults.find(
						x => (x as Api.ActionItemEventViewModel).viewmodel.id === showDeletingRichContentModal.id
					);
					this.mTimeline.controller.fetchResults.removeItems([item]);
				})
				?.catch(error => {
					logApiError('NoteDelete-Error', error);
					errorMessages.pushApiError(error);
				});
		}

		this.setState({ showDeletingRichContentModal: null });
	};

	private onMoreMenuItemClicked = (
		richContent: Api.RichContentViewModel,
		menuItem: IMoreMenuItem,
		e: React.MouseEvent<HTMLElement>
	) => {
		const { noteComposer, actionItemComposer, onMoreMenuOptionClicked, logInput, entity } = this.props;

		if (onMoreMenuOptionClicked) {
			onMoreMenuOptionClicked(richContent, menuItem.representedObject, e);
		}

		if (e.defaultPrevented) {
			e.stopPropagation();
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		const getAction = (name: string) =>
			`MoreMenu${name}${richContent instanceof Api.NoteViewModel ? 'Note' : 'ActionItem'}`;
		if (menuItem.representedObject === 'delete') {
			this.setState({ showDeletingRichContentModal: richContent });
		} else if (menuItem.representedObject === 'edit') {
			logInput(getAction('Edit'), 'Click', { id: richContent.id });
			if (richContent instanceof Api.NoteViewModel) {
				noteComposer.show(richContent);
			} else if (richContent instanceof Api.ActionItemViewModel) {
				actionItemComposer.show(
					richContent,
					(editedActionItem?: Api.ActionItemViewModel, result?: Api.RichContentComposerResult) => {
						if (!!editedActionItem && result === Api.RichContentComposerResult.Delete) {
							entity.deleteRichContent(editedActionItem);
						}
					}
				);
			}
		}
	};
}

const EntityTimelineAsObserver = observer(_EntityTimeline);
const EntityTimelineWithContext = inject(
	AppState.ActionItemComposerViewModelKey,
	AppState.NoteComposerViewModelKey,
	AppState.SingleEmailComposerKey,
	AppState.UserSessionViewModelKey,
	AppState.ErrorMessagesViewModelKey,
	AppState.TextMessagingViewModelKey
)(EntityTimelineAsObserver);
const EntityTimelineRouter = withRouter(EntityTimelineWithContext);
export const EntityTimeline = withEventLogging(EntityTimelineRouter, 'EntityTimeline');

const EntityTimelineBareObserver = observer(_EntityTimeline);
const EntityTimelineBareRouter = withRouter(EntityTimelineBareObserver);
export const EntityTimelineBare = withEventLogging(EntityTimelineBareRouter, 'EntityTimeline');
