import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ICompany } from '../../../../extViewmodels';
import { getDisplayName } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { CallRecordingDetails } from '../../../components/CallRecordingDetails';
import { LeadViewWrapper } from '../../../components/leadView/LeadViewWrapper';
import { RecordingIndicator } from '../../../components/leadView/components/RecordingIndicator';
import { TextColumn } from '../../../components/leadView/components/TextColumn';
import { PlayIcon } from '../../../components/svgs/icons/audio/PlayIcon';
import { IPhoneCallFilter, usePhoneCall } from '../../../entities/PhoneCall/usePhoneCall';
import { CopyButton } from '../../../hooks/useCopyToClipboard/CopyButton';
import { ViewportContext } from '../../../hooks/useViewport';
import { white } from '../../../styles/colors';
import { IPhoneCallItem } from '../../../viewModels/phonecall';
import { LeadServedSource } from '../../../viewModels/queue';
import { styleSheet } from '../styles';

interface IRouteMatch {
	recentCallId: string;
}

export const IncomingCallsTab: React.FC = () => {
	const { data, empty, get, status } = usePhoneCall<IPhoneCallItem[]>();

	const userSession = useUserSession();

	const [recentCallId, setRecentCallId] = useState(null);
	const [autoplay, setAutoplay] = useState(false);

	const history = useHistory();

	const matchRecentCallId = useRouteMatch<IRouteMatch>('/call-log/:recentCallId');

	const recentCallIdParam = matchRecentCallId?.params?.recentCallId;

	useEffect(() => {
		setRecentCallId(recentCallIdParam);
	}, [recentCallIdParam]);

	const defaultFilter: IPhoneCallFilter = {
		direction: 'Inbound',

		user: userSession.user,
	};

	useEffect(() => {
		get(defaultFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { isBelowSmall } = useContext(ViewportContext);

	const handleLogDetails = (callId: string, isAutoplay?: boolean) => () => {
		history.push(`/call-log/${callId || ''}`);

		setAutoplay(isAutoplay);
	};

	const handleRowClick = (item: IPhoneCallItem) => {
		return handleLogDetails(item?.phoneCall?.id);
	};

	const handleClickPlay = (phoneCallId: string) => () => {
		handleLogDetails(phoneCallId, true)();
	};

	const getLeadUrl = (company: ICompany) => {
		const { origin, pathname } = location;
		return `${origin + pathname}#/queue?from=${LeadServedSource.CallLog}&companyId=${company?.id}`;
	};

	const handleOpenLead = (company: ICompany) => () => {
		const companyId = company?.id;
		history.push(`/queue?from=${LeadServedSource.CallLog}&companyId=${companyId}`);
	};

	return (
		<>
			<LeadViewWrapper<IPhoneCallItem>
				title='Call Logs'
				data={data}
				isLoading={status === 'working' || status === 'waiting'}
				isEmpty={empty}
				getRowToClick={handleRowClick}
				hideHeader={true}
				columns={[
					{
						Component: ({ phoneCall }) => {
							const phoneNumberText = phoneCall?.phoneNumber?.standard || 'Unknown';
							return (
								<TextColumn
									isSmall={isBelowSmall}
									text={phoneNumberText}
									renderRightAccessory={<CopyButton value={phoneNumberText} />}
								/>
							);
						},
						label: 'Number',
						width: isBelowSmall ? 220 : 240,
					},
					{
						Component: ({ company }) => {
							const companyName = company?.companyName;
							return (
								<TextColumn
									hrefLink={getLeadUrl(company)}
									isSmall={isBelowSmall}
									onClick={!isBelowSmall && companyName ? handleOpenLead(company) : null}
									text={companyName || 'Unknown'}
								/>
							);
						},
						label: 'Company',
						width: isBelowSmall ? 220 : 240,
					},
					{
						Component: ({ phoneCall }) => {
							const date = moment(phoneCall?.lastModifiedDate).format('ddd, MM/DD/YY, h:mmA');
							return <TextColumn text={date} isSmall={true} />;
						},
						hide: isBelowSmall,
						label: 'Time',
						width: 200,
					},
					{
						Component: ({ phoneCall }) => {
							if (phoneCall?.recording?.publicUrl) {
								return <PlayIcon fillColor={white} className={css(styleSheet.playIcon)} />;
							}
							return null;
						},
						hide: !isBelowSmall,
						label: '',
						width: 20,
					},
					{
						Component: ({ phoneCall }) => {
							return (
								<RecordingIndicator
									creationDate={phoneCall?.creationDate}
									creatorName={getDisplayName(phoneCall?.creator)}
									phoneNumber={phoneCall?.phoneNumber?.standard}
									id={phoneCall?.id}
									publicUrl={phoneCall?.recording?.publicUrl}
									onClickPlay={handleClickPlay(phoneCall?.id)}
								/>
							);
						},
						hide: isBelowSmall,
						label: 'Recording',
						width: 120,
					},
				]}
			/>
			{recentCallId && (
				<CallRecordingDetails autoplay={autoplay} onClickToClose={handleLogDetails(null)} recentCallId={recentCallId} />
			)}
		</>
	);
};
