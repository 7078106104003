import { FC, createContext, useContext, useState } from 'react';

export interface IDraggableWindowContext {
	registerWindow(windowId: string): void;
	unregisterWindow(windowId: string): void;
	bringToFront(windowId: string): void;
	windowIds: string[];
}

export const calculateDraggableWindowZIndex = (windows: string[], windowId: string) => windows.indexOf(windowId) + 10;

export const DraggableWindowContext = createContext<IDraggableWindowContext>(null);

export const DraggableWindowContextProvider: FC = ({ children }) => {
	// The highest window will always be last in the list
	const [windowIds, setWindows] = useState<string[]>([]);

	/**
	 * Add window to the top of the current list of windows It will be rendered in front when first registered
	 *
	 * @param windowId Unique window ID to be added
	 */
	const registerWindow = (windowId: string) => setWindows([...windowIds, windowId]);

	/**
	 * Remove window from list of orderable windows
	 *
	 * @param windowId Unique window ID to be removed
	 */
	const unregisterWindow = (windowId: string) => setWindows([...windowIds.filter(id => id !== windowId)]);

	const bringToFront = (windowId: string) => setWindows([...windowIds.filter(id => id !== windowId), windowId]);

	return (
		<DraggableWindowContext.Provider value={{ bringToFront, registerWindow, unregisterWindow, windowIds }}>
			{children}
		</DraggableWindowContext.Provider>
	);
};

export const useDraggableWindowContext = () => {
	return useContext<IDraggableWindowContext>(DraggableWindowContext);
};
