import { StyleDeclaration, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormFieldType, IFormField } from '../../../extViewmodels';
import { ISelectOption, Select } from '../../../web/components/Select';
import { IFormFieldOption } from '../../viewModels/form';
import { styleSheet } from './styles';

export type SelectedDecisionMaker = ISelectOption<IFormField<IFormFieldOption>>;

interface IProps {
	className?: string;
	DecisionMakerSelectStyles?: StyleDeclaration[];
	decisionMaker?: string;

	selected: SelectedDecisionMaker;
	onSelect: (value: SelectedDecisionMaker) => void;
}

const noneAndCustomOptions = [
	{
		dataContext: {
			fieldType: FormFieldType.Custom,
			label: 'Decision Maker',
			name: 'Decision Maker',
		},
		id: 'decisionMaker',
		text: 'Decision Maker',
	},
	{
		dataContext: {
			fieldType: FormFieldType.Custom,
			label: 'Influencer',
			name: 'Influencer',
		},
		id: 'influencer',
		text: 'Influencer',
	},
	{
		dataContext: {
			fieldType: FormFieldType.Custom,
			label: 'Other',
			name: 'Other',
		},
		id: 'other',
		text: 'Other',
	},
];

const DecisionMakerSelectBase: React.FC<IProps> = ({
	className = 'decisionMaker',
	DecisionMakerSelectStyles = [],

	selected,
	onSelect,
}) => {
	const [selectedOption, setSelectedOption] = useState<SelectedDecisionMaker>(null);

	useEffect(() => {
		setSelectedOption(selected);
	}, [selected]);

	const renderTitle = () => {
		let content: JSX.Element = null;

		if (!selectedOption) {
			content = <span>Other</span>;
		} else if (selectedOption.dataContext.fieldType === FormFieldType.Custom) {
			content = <span className={css(styleSheet.selectedOptionText)}>{selectedOption.text}</span>;
		} else {
			content = <span className={css(styleSheet.selectedOptionText)}>{selectedOption.text}</span>;
		}

		return <div className={`follow-up-title ${css(styleSheet.title)}`}>{content}</div>;
	};

	const onOptionClick = (option: SelectedDecisionMaker) => {
		onSelect(option);
	};

	return (
		<div className={`${css(styleSheet.DecisionMakerSelectContainer)} ${className}`}>
			<div className={`decision-maker-label ${css(styleSheet.label)}`} />
			<Select
				dropdownStyles={[styleSheet.dropdown]}
				options={noneAndCustomOptions}
				onOptionClick={onOptionClick}
				selectedOption={selectedOption}
				selectedOptionTitle={renderTitle()}
				styles={[styleSheet.selectContainer, ...DecisionMakerSelectStyles]}
				triggerStyles={[styleSheet.trigger]}
			/>
		</div>
	);
};

export const DecisionMakerSelect = observer(DecisionMakerSelectBase);
