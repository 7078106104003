import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useTelephony } from '../../../contexts/telephony';
import { useErrorMessages } from '../../../hooks';
import { useQueue } from '../../../hooks/queue';
import { FieldKey } from '../../../viewModels/form';
import { LeadAction } from '../../../viewModels/leads/interfaces';
import { IPhoneCallOutcome, IPhoneCallOutcomeGroup, PhoneCallStatus } from '../../../viewModels/phonecall';
import { TransparentButton } from '../../TransparentButton';
import { SmallArrowUpIcon } from '../../svgs/icons/SmallArrowIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	onStatusOptionClicked?(outcome: IPhoneCallOutcome): void;
}

interface IOutcomeOptionProps {
	iconColor: string;
	isGroup?: boolean;
	onClick: () => void;
	outcome: IPhoneCallOutcomeGroup | IPhoneCallOutcome;
	text: string;
}

const OutcomeOption: React.FC<IOutcomeOptionProps> = observer(({ onClick, outcome }) => {
	const { phoneCalls } = useTelephony();

	const enabled = !!phoneCalls.currentCall || outcome.name === FieldKey.BadLead;

	return (
		<TransparentButton
			className={css(
				styleSheet.optionContainer,
				phoneCalls.currentCall?.outcome?.id === outcome?.id && styleSheet.selected
			)}
			disabled={!enabled}
			onClick={onClick}
		>
			<div className={css(styleSheet.dot)} style={{ background: outcome.hexColor }} />
			<span>
				{outcome.label}
				{!!((outcome as IPhoneCallOutcomeGroup).children || outcome.name === FieldKey.BadLead) && (
					<SmallArrowUpIcon className={css(styleSheet.smallArrow)} />
				)}
			</span>
		</TransparentButton>
	);
});

export const ShortcutOptions: React.FC<IProps> = observer(({ className = '', onStatusOptionClicked }) => {
	const queue = useQueue();
	const { phoneCalls } = useTelephony();
	const errorMessages = useErrorMessages();

	const onMenuOptionClick = (outcome: IPhoneCallOutcome | IPhoneCallOutcomeGroup) => async () => {
		onStatusOptionClicked(outcome);
		if (outcome.name === FieldKey.Connected) {
			queue.setAction(LeadAction.Next);

			phoneCalls.currentCall?.setOutcome(null);
			queue.lead.clearFollowUpOptions();
		} else {
			phoneCalls.currentCall?.setOutcome(outcome);

			queue.lead.getFollowUp(outcome).catch(err => errorMessages.pushApiError(err));
		}
	};

	const renderOptions = () => {
		return (queue?.outcomeShortcuts || []).map(outcome => {
			return (
				<OutcomeOption
					iconColor={outcome.hexColor}
					isGroup={!!(outcome as IPhoneCallOutcomeGroup).children}
					key={outcome.id}
					onClick={onMenuOptionClick(outcome)}
					outcome={outcome}
					text={outcome.label}
				/>
			);
		});
	};

	return (
		<div className={`${css(styleSheet.shortcutsOptionsContainer)} ${className}`}>
			<div className={css(styleSheet.outcomesContainer)}>
				<div className={css(styleSheet.title)}>
					{!!phoneCalls.currentCall &&
					phoneCalls.currentCall.status !== PhoneCallStatus.Calling &&
					phoneCalls.currentCall.status !== PhoneCallStatus.Connected &&
					!phoneCalls.currentCall.outcome
						? 'Last Call Outcome?'
						: 'Outcome'}
				</div>
				<div>{renderOptions()}</div>
			</div>
		</div>
	);
});
