import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { IPhoneCall, IScheduledMeeting } from '../../../extViewmodels';
import { getDisplayName } from '../../../models/UiUtils';
import { CalendarDateIcon } from '../../../web/components/svgs/icons/CalendarDateIcon';
import { ClockAlertIcon } from '../../../web/components/svgs/icons/ClockAlertIcon';
import { FollowUpsIcon } from '../../../web/components/svgs/icons/FollowUpsIcon';
import { useAudio } from '../../contexts/audioPlayer';
import { brandPrimary, brandSecondary, error, orange, success, white } from '../../styles/colors';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { IDeal } from '../../viewModels/leads/interfaces';
import { FollowUpImportance, ILeadFollowUp, LeadServedSource } from '../../viewModels/queue';
import { LocationIcon } from '../svgs/icons/LocationIcon';
import { PepperIcon } from '../svgs/icons/PepperIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	name?: string;
	leadId: string;
	phone?: string;
	website?: string;
	onClick?(): void;
	contactInfo?(): JSX.Element;
	followUp?: ILeadFollowUp;
	lastDeal?: IDeal;
	nextMeeting?: IScheduledMeeting;
	phoneCall?: IPhoneCall;
	interaction?: {
		dateLabel?: string;
		date?: string;
		outcomeLabel?: string;
		outcomeColor?: string;
		outcome?: string;
	};
}

export const CardView: React.FC<IProps> = props => {
	const {
		name,
		phone,
		website,
		onClick,
		contactInfo,
		interaction,
		followUp,
		lastDeal,
		nextMeeting,
		leadId,
		className = '',
		phoneCall,
	} = props;
	const audio = useAudio();

	const renderFollowUpInfo = () => {
		if (!followUp) {
			return null;
		}

		const fu = moment(followUp.followUpDate);
		const isMidnight = fu.hours() === 0 && fu.minutes() === 0 && fu.seconds() === 0;
		let formattedDate = fu.format(`M/DD/YY${isMidnight ? '' : ' h:mm A'}`);
		const isScheduled = followUp.scheduled;
		if (!isScheduled) {
			if (fu.isSame(moment(), 'day')) {
				formattedDate = 'Today';
			} else if (fu.isSame(moment().add(1, 'day'), 'day')) {
				formattedDate = 'Tomorrow';
			}
		}

		const renderPepper = () => {
			if (!followUp.scheduled) {
				return null;
			}

			const pepperFill =
				followUp.importance === FollowUpImportance.High
					? error
					: followUp.importance === FollowUpImportance.Medium
						? orange
						: success;

			return <PepperIcon className={css(styleSheet.pepper)} fillColor={pepperFill} />;
		};

		const followUpDescription = followUp.name?.length > 30 ? followUp.name.slice(0, 30) + '...' : followUp.name;

		return (
			<div className={css(styleSheet.followUpContainer)}>
				<div>
					<span>{formattedDate}</span>
					<span className={css(styleSheet.followUpClockIcon)}>
						<FollowUpsIcon fill={white} />
					</span>
					{fu.isBefore(moment()) && <span className={css(styleSheet.followUpOverdue)}>Overdue</span>}
					{followUp.scheduled && (
						<span className={css(styleSheet.followUpClockIcon)}>
							<ClockAlertIcon fillColor={brandPrimary} />
						</span>
					)}
					{renderPepper()}
					<span className={css(styleSheet.followUpName)}>{followUpDescription}</span>
				</div>
			</div>
		);
	};

	const renderLastOpenDealInfo = () => {
		if (!lastDeal || !lastDeal.isOpen || !nextMeeting) {
			return null;
		}

		const fu = moment(nextMeeting.startDate);
		const formattedDate = fu.format(`M/DD/YY h:mm A`);

		return (
			<div className={css(styleSheet.lastDealContainer)}>
				<span>{formattedDate}</span>
				<span className={css(styleSheet.followUpClockIcon)}>
					<CalendarDateIcon fillColor={brandPrimary} />
				</span>
			</div>
		);
	};

	const onNewTabClick = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	const onAudioPlay = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		audio.setIsOpen(true)();

		audio.setAudioSrc(phoneCall.recording.publicUrl);

		audio.setTitle(`Call Recording (${moment(phoneCall.creationDate).format('M/DD/YYYY [at] h:mma')})`);

		audio.setSubtitle(`${getDisplayName(phoneCall.creator)} called ${phoneCall.phoneNumber?.standard}`);

		audio.setPhoneCallId(phoneCall.id);
	};

	return (
		<div className={`${css(styleSheet.leadCardContainer)} ${className}`} onClick={onClick}>
			<div className={css(styleSheet.tagContainer)}>
				{renderLastOpenDealInfo()}
				{renderFollowUpInfo()}
			</div>
			<div
				className={css(
					styleSheet.section,
					styleSheet.companyContainer,
					(followUp || lastDeal) && styleSheet.companyContainerExtraMargin
				)}
			>
				<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
					<div className='company-name'>{name}</div>
				</div>
				{!!phone && <div className='company-phone'>{phone}</div>}
				{!!website && <div className='company-website'> {website} </div>}
			</div>

			{contactInfo()}
			<div className={css(styleSheet.section)}>
				<a
					className='company-link'
					onClick={onNewTabClick}
					href={`${window.location.origin}/#/queue?from=${LeadServedSource.SharedLink}&companyId=${leadId}`}
					target='_blank'
					rel='noreferrer'
				>
					Open in New Tab
					<LocationIcon className={css(styleSheet.locationIcon)} fillColor={brandSecondary} />
				</a>
				<div className={css(styleSheet.lastInteractionContainer, !interaction && aidaBaseStyleSheet.flexCenter)}>
					{interaction ? (
						<>
							<div className={css(styleSheet.lastInteraction)}>
								<span className={css(styleSheet.label)}>{interaction.dateLabel}</span>
								<span>{moment(interaction.date).format('ddd, MM/DD/YY, h:mmA')}</span>
							</div>
							<div className={css(aidaBaseStyleSheet.flexHorizontalCenter, styleSheet.lastInteraction)}>
								<span className={css(styleSheet.label)}>{interaction.outcomeLabel}</span>
								<div className={css(styleSheet.lastInteraction)}>
									<div className={css(styleSheet.dot)} style={{ background: interaction.outcomeColor }} />
									<span>{interaction.outcome}</span>
								</div>
							</div>
							{phoneCall?.recording?.publicUrl && (
								<div className={css(aidaBaseStyleSheet.flexHorizontalCenter, styleSheet.recording)}>
									<span className={css(styleSheet.label)}>Recording:</span>
									<span className={css(styleSheet.playButton)} onClick={onAudioPlay}>
										Play
									</span>
								</div>
							)}
						</>
					) : (
						<span className={css(styleSheet.label)}>No interactions yet</span>
					)}
				</div>
			</div>
		</div>
	);
};
