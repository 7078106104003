import { css } from 'aphrodite';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { HTTPMethod } from '../../../../../extViewmodels';
import { useEventLogging } from '../../../../../models/Logging';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { Checkbox } from '../../../../../web/components/Checkbox';
import { Dropdown } from '../../../../../web/components/Dropdown';
import { ISelectOption, Select } from '../../../../../web/components/Select';
import { DisclosureIcon } from '../../../../../web/components/svgs/icons/DisclosureIcon';
import { useToaster } from '../../../../hooks';
import { FieldKey } from '../../../../viewModels/form';
import { IPhoneCallOutcome } from '../../../../viewModels/phonecall';
import { Group } from '../Group';
import { styleSheet } from './styles';
import { arrayOfLabels, checkAll, filterByChecked, prepareResponse, reduceChildren } from './utils';

interface IProps {
	closeOnClickInside?: boolean;
	defaultChecked?: boolean;
	defaultValues?: string[];
	disabled?: boolean;
	isInline?: boolean;
	isMulti?: boolean;
	onChange?: (item: ISelectOption<FieldKey>[]) => void;
}

export const OutcomeSelect: React.FC<IProps> = ({
	closeOnClickInside,
	defaultChecked = true,
	defaultValues,
	disabled,
	isInline,
	isMulti,
	onChange,
}) => {
	const userSession = useUserSession();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('OutcomeSelect');
	const [outcomeOptions, setOutcomeOptions] = useState<ISelectOption<FieldKey>[]>([]);

	const [selectedOutcome, selectOutcome] = useState<FieldKey>(null);

	const [openDropdown, setOpenDropdown] = useState(false);

	useEffect(() => {
		const loadOutcomes = async () => {
			const apiUrl: string = isMulti ? 'aida/outcomes' : 'lead/PhoneCallOutcomes';
			const method: HTTPMethod = isMulti ? 'POST' : 'GET';

			try {
				const value = await userSession.webServiceHelper.callAsync<IPhoneCallOutcome[]>(apiUrl, method, []);

				const options = value.map(prepareResponse);
				options.unshift({
					checked: true,
					dataContext: FieldKey.Unknown,
					id: 'all',
					name: 'all',
					text: 'All outcome types',
				});

				if (isMulti) {
					options?.map(checkAll(defaultChecked, defaultValues));
				} else {
					selectOutcome(FieldKey.Unknown);
				}

				setOutcomeOptions(options);
			} catch (error) {
				logApiError('LoadOutcomes-Error', error);

				toaster.push({
					// @ts-ignore
					message: error.systemMessage ?? 'Unable to load outcomes',
				});
			}
		};

		loadOutcomes();
	}, [defaultChecked, defaultValues, isMulti, logApiError, toaster, userSession]);

	const onOutcomeClick = (option: ISelectOption<FieldKey>) => {
		onChange([option]);
		selectOutcome(option?.dataContext);
	};

	const handleCheckboxChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		const isAll = target?.id === 'all';

		const checkSpecific = (parent: ISelectOption<FieldKey>) => {
			if (parent?.id === target.id) {
				parent.checked = target.checked;
			}
			parent?.children?.map((child: ISelectOption<FieldKey>) => {
				if (!Object.prototype.hasOwnProperty.call(Object(child), 'checked')) {
					child.checked = true;
				}
				if (parent?.id === target.id && target.checked) {
					child.checked = true;
				}
				if (parent?.id === target.id && !target.checked) {
					child.checked = false;
				}
				if (child?.id === target.id) {
					child.checked = target.checked;
					if (!target.checked) {
						parent.checked = false;
					}
				}
				return child;
			});
			const childrenCheckedSize = parent?.children?.filter(filterByChecked)?.length;
			if (childrenCheckedSize === parent?.children?.length) {
				parent.checked = true;
			}
			return parent;
		};

		const toggleAllOption = (check: boolean) => (parent: ISelectOption<FieldKey>) => {
			if (parent.id === 'all') {
				parent.checked = check;
			}
			return parent;
		};

		const outcomes = outcomeOptions.map(isAll ? checkAll(target.checked) : checkSpecific);

		const parentCheckedSize = outcomes?.filter(filterByChecked)?.length;

		if (parentCheckedSize === outcomes?.length) {
			outcomes.map(toggleAllOption(true));
		} else {
			outcomes.map(toggleAllOption(false));
		}
		if (closeOnClickInside) {
			setOpenDropdown(false);
		}
		setOutcomeOptions(outcomes);

		onChange(outcomes);
	};

	const optionsChildrenOnly = outcomeOptions?.reduce(reduceChildren, []);
	const checkedOnly = optionsChildrenOnly?.filter(filterByChecked);
	const optionsLabelsOnly = checkedOnly.map(arrayOfLabels);
	const isAllSelected = checkedOnly?.length === optionsChildrenOnly?.length;
	const selectedLabel = optionsLabelsOnly?.length ? optionsLabelsOnly.join(', ') : 'None';
	const counterLabel = `Outcomes: ${isAllSelected ? 'All' : selectedLabel}`;

	const renderOutcomeList = (
		<>
			<Group vertical={true}>
				{outcomeOptions.map((parent: ISelectOption<FieldKey>, parentIndex) => {
					return (
						<Fragment key={parentIndex}>
							<Checkbox
								id={parent.id}
								type='large'
								onChange={handleCheckboxChange}
								checked={parent.checked}
								className={css(styleSheet.checkbox)}
							>
								<div className={css(styleSheet.checkboxText)}>{parent.text}</div>
							</Checkbox>
							{parent?.children?.map((child: ISelectOption<FieldKey>, childIndex) => {
								return (
									<Checkbox
										key={childIndex}
										id={child.id}
										type='large'
										onChange={handleCheckboxChange}
										checked={parent.checked || child.checked}
										className={css([styleSheet.checkbox, styleSheet.childCheckbox])}
									>
										<div className={css(styleSheet.checkboxText)}>{child.label}</div>
									</Checkbox>
								);
							})}
						</Fragment>
					);
				})}
			</Group>
		</>
	);

	if (isInline) {
		return renderOutcomeList;
	}

	return isMulti ? (
		<Dropdown
			anchor={
				<span className={css(styleSheet.selectAnchor)} title={counterLabel}>
					{counterLabel} <DisclosureIcon className={css(styleSheet.selectAnchorIcon)} />
				</span>
			}
			className={`outcome-select ${css(styleSheet.multiSelect)}`}
			contentPositionY='bottom'
			isOpen={openDropdown}
			onOpenChanged={setOpenDropdown}
			openOnClick={true}
			contentInnerStyle={[styleSheet.selectDropdown, styleSheet.multiSelectDropdown]}
			contentInnerClassName='multiselect-dropdown'
		>
			{renderOutcomeList}
		</Dropdown>
	) : (
		<Select
			dropdownStyles={[styleSheet.selectDropdown]}
			onOptionClick={onOutcomeClick}
			options={outcomeOptions}
			disabled={disabled}
			selectedOption={outcomeOptions.find(x => x.dataContext === selectedOutcome)}
			styles={[styleSheet.select, disabled ? styleSheet.selectDisabled : null]}
		/>
	);
};
