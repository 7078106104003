import { useCallback } from 'react';
import { IContact } from '../../../extViewmodels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useErrorMessages, useToaster } from '../../hooks';
import { useQueue } from '../../hooks/queue';
import { AidaContactViewModel } from '../../viewModels/contacts';

export const useSaveContact = () => {
	const queue = useQueue();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();

	const onClickToSave = useCallback(
		async (contact: IContact, original?: IContact) => {
			const body = {
				...contact,
				company: queue.lead.company.toJs(),
				companyId: queue.lead.company.id,
			};

			let modifiedContact: AidaContactViewModel = null;

			const toggleDM = () => {
				const isDM = contact.isDecisionMaker;

				const isDecisionMaker = queue.lead?.isDecisionMakerById(modifiedContact?.id);
				if ((isDM && !isDecisionMaker) || (!isDM && isDecisionMaker)) {
					queue.lead?.toggleDecisionMaker(modifiedContact);
				}
			};

			if (original) {
				try {
					const contactVm = new AidaContactViewModel(userSession, original);
					const modifiedContactInterface = await contactVm.update(body);

					modifiedContact = new AidaContactViewModel(userSession, modifiedContactInterface);

					queue.lead.resetContacts();
					await queue.lead?.getContacts();

					toaster.push({
						message: 'Contact was updated successfully.',
						type: 'successMessage',
					});
					toggleDM();
					return contactVm;
				} catch (err) {
					errorMessages.pushApiError(err);
				}
			} else {
				try {
					modifiedContact = await AidaContactViewModel.Create(userSession, body);
					queue.lead.resetContacts();
					await queue.lead?.getContacts();

					toaster.push({
						message: 'Contact was created successfully.',
						type: 'successMessage',
					});
					toggleDM();
					return modifiedContact;
				} catch (err) {
					errorMessages.pushApiError(err);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[queue.lead, userSession]
	);

	return onClickToSave;
};
