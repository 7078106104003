import { css } from 'aphrodite';
import * as React from 'react';
import { ChangeEvent, useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { IPhoneNumber } from '../../../extViewmodels';
import { PhoneNumberLabels } from '../../../models';
import { getPartialFormattedPhoneNumber } from '../../../models/UiUtils';
import { useModal } from '../../../models/hooks/useModal';
import { Button } from '../../../web/components/Button';
import { DefaultDeleteConfirmationOptions } from '../../../web/components/ConfirmationDialog';
import { ISelectOption, Select } from '../../../web/components/Select';
import { TextInput } from '../../../web/components/TextInput';
import { TrashIcon } from '../../../web/components/svgs/icons/TrashIcon';
import { WarningIcon } from '../../../web/components/svgs/icons/WarningIcon';
import { grayIconFill } from '../../styles/colors';
import { BlueConfirmationDialog } from '../BlueConfirmationDialog';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';

interface IProps {
	allowDeleting?: boolean;
	className?: string;
	includeLabels?: boolean;
	inputsContainerClassName?: string;
	onCancel?: () => void;
	onChange?: (phoneNumber: IPhoneNumber) => void;
	onSave?: (phoneNumber: IPhoneNumber) => void;
	onDelete?: (phoneNumber: IPhoneNumber) => void;
	phoneNumber?: IPhoneNumber;
}

const getPhoneNumberLabels = (phoneNumber?: IPhoneNumber): ISelectOption<string>[] => {
	const options = [...PhoneNumberLabels].map(p => ({
		dataContext: p.value,
		id: p.value,

		text: p.value[0].toUpperCase() + p.value.slice(1),
	}));

	if (phoneNumber?.label && !options.find(o => o.dataContext === phoneNumber.label)) {
		const { label } = phoneNumber;
		options.unshift({
			dataContext: label,
			id: label,
			text: label,
		});
	}

	return options;
};
const regex = /\d+/g;

export const PhoneNumberEditor: React.FC<IProps> = ({
	allowDeleting,
	className = '',
	includeLabels = false,
	inputsContainerClassName = '',
	onCancel,
	onChange,
	onSave,
	onDelete,
	phoneNumber,
}) => {
	const labelOptions = useRef(getPhoneNumberLabels(phoneNumber)).current;
	const id = useRef(uuid()).current;
	const deleteConfirmationModal = useModal(
		false,
		() => {
			onDelete?.(phoneNumber);
		},
		[onDelete, phoneNumber]
	);

	const onLabelOptionClick = useCallback(
		(option: ISelectOption<string>) => {
			onChange({
				...phoneNumber,
				label: option.dataContext,
			});
		},
		[phoneNumber, onChange]
	);

	const onPhoneNumberChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			let value = e.target.value;

			// If the user is adding an international number, leave the value alone.
			if (value.startsWith('+')) {
				onChange({
					...phoneNumber,
					value,
				});
			}

			// user has pressed Backspace intending to delete the last digit of the area code
			// need to overwrite default functionality in order to remove the last digit
			// since we are manually adding the `)` which is the actual character the
			// user's keypress deleted.
			if (value.length <= 4 && value.includes('(') && !value.includes(')')) {
				value = value.slice(0, value.length - 1);
			}

			const parsed = value.match(regex)?.join('') ?? '';

			onChange({
				...phoneNumber,
				value: getPartialFormattedPhoneNumber(parsed),
			});
		},
		[phoneNumber, onChange]
	);

	const onSaveClick = () => {
		onSave({
			...phoneNumber,

			value: getPartialFormattedPhoneNumber(phoneNumber.value),
		});
	};

	const renderCtas = () => {
		if (!onSave && !onCancel) {
			return null;
		}

		return (
			<div className={css(styleSheet.ctaContainer)}>
				{!!onSave && (
					<Button
						className={css(styleSheet.save)}
						disabled={!phoneNumber?.value?.match(regex)?.[0]}
						label='Save'
						onClick={onSaveClick}
					/>
				)}
				{!!onCancel && <Button kind='reverse' label='Cancel' onClick={onCancel} />}
			</div>
		);
	};

	return (
		<div className={`${css(styleSheet.phoneNumberEditorContainer)} ${className}`}>
			<div className={`${css(styleSheet.inputsContainer)} ${inputsContainerClassName}`}>
				<div>
					{includeLabels && <label>Phone</label>}
					<TextInput
						inputId={id}
						onChange={onPhoneNumberChange}
						type='text'
						value={getPartialFormattedPhoneNumber(phoneNumber?.value)}
					/>
				</div>
				<div>
					{includeLabels && <label>Label</label>}
					<Select
						onOptionClick={onLabelOptionClick}
						options={labelOptions}
						selectedOption={
							labelOptions.find(o => o.dataContext === phoneNumber?.label) ??
							labelOptions.find(o => o.dataContext === phoneNumber?.label) ??
							labelOptions[0]
						}
						styles={[styleSheet.select]}
					/>
				</div>
				{!!onDelete && (
					<>
						{allowDeleting && (
							<TransparentButton
								className={css(styleSheet.iconContainer)}
								onClick={deleteConfirmationModal.setIsOpen(true)}
							>
								<TrashIcon fillColor={grayIconFill} />
							</TransparentButton>
						)}
						<BlueConfirmationDialog
							icon={<WarningIcon />}
							modalProps={deleteConfirmationModal}
							options={DefaultDeleteConfirmationOptions}
							title={`Delete phone number ${getPartialFormattedPhoneNumber(phoneNumber?.value)}?`}
						/>
					</>
				)}
			</div>
			{renderCtas()}
		</div>
	);
};
