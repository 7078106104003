import { css } from 'aphrodite';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { FetchStatus } from '../../entities/utils/useStatesOnFetch';
import { useFuzzySearch } from '../../hooks/useFuzzySearch';
import { IIntegrationPropertyItem } from '../../viewModels/leads/interfaces';
import { ContentBox } from '../generics/ContentBox';
import { DraggableList } from '../generics/DraggableList';
import { useIntegrationProperties } from './IntegrationPropertiesContext';
import { EmptyState, EmptyStateVariations } from './components/EmptyState';
import { LeadGeneralSettings } from './components/LeadGeneralSettings';
import { LeadPropertyCard } from './components/LeadPropertyCard';
import { PropertiesHeader } from './components/PropertiesHeader';
import { PropertyItemRow } from './components/PropertyItemRow';
import { styleSheet } from './styles';

interface IProps {
	isAdding?: boolean;
}

export const LeadProperties: React.FC<IProps> = () => {
	const [search, setSearch] = useState('');

	const [settingsFetchStatus, setSettingsFetchStatus] = useState<FetchStatus>(null);

	const { config, status, update } = useIntegrationProperties();

	const handleSearch = (keyword: string) => {
		setSearch(keyword);
	};

	const filteredData = config?.properties?.filter(i => i.addAsTag);
	const restData = config?.properties?.filter(i => !i.addAsTag);

	const handleRemoveItem = (item: IIntegrationPropertyItem) => () => {
		const properties = [...filteredData].map(i => {
			if (i.name === item.name) {
				i.addAsTag = false;
			}
			return i;
		});

		update({ ...config, properties: [...properties, ...restData] });
	};

	const fuzzySearch = useFuzzySearch<IIntegrationPropertyItem>(filteredData, ['label']);

	const items = useMemo(() => {
		return search ? fuzzySearch.search(search).map(item => item) : filteredData;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, filteredData]) as IIntegrationPropertyItem[];

	const isLoading = status !== FetchStatus.Done;

	const isEmpty = filteredData && filteredData?.length > 0 ? false : true;

	const renderEmpty = (
		<div className={css(styleSheet.emptyState)}>
			<EmptyState
				description='Click on "Add Additional Properties" above to start'
				variation={EmptyStateVariations.Tags}
			/>
		</div>
	);

	const updateCheckboxOnSelected =
		(item: IIntegrationPropertyItem) =>
		({ target }: React.ChangeEvent<HTMLInputElement>) => {
			const properties = [...(config?.properties ?? [])].map(i => {
				if (i.name === item.name) {
					i.addToForm = target.checked;
				}
				return i;
			});
			update({ ...config, properties });
		};

	const handleReordering = (properties: IIntegrationPropertyItem[]) => {
		update({ ...config, properties: [...properties, ...restData] });
	};

	const getKey = (i: IIntegrationPropertyItem) => i.name;

	const labelPlaceholder = '▋▋ ▋▋▋▋ ▋▋▋';

	const isNotDone = isLoading || settingsFetchStatus !== FetchStatus.Done;

	return (
		<>
			<LeadGeneralSettings onFetchStatusChange={setSettingsFetchStatus} />
			<ContentBox
				className={css(styleSheet.contentBox)}
				isBlocked={isNotDone}
				maxWidth={1024}
				title='Existing lead properties in Coffee (drag and drop to rearrange):'
			>
				<PropertiesHeader onSearchChange={handleSearch} pathname='lead' />
				{isEmpty ? (
					renderEmpty
				) : (
					<DraggableList<IIntegrationPropertyItem>
						className={css(styleSheet.draggableItemRow)}
						getKey={getKey}
						items={items}
						onChange={handleReordering}
					>
						{({ item, isDragging }) => {
							const draggableItemIsDragging = isDragging ? styleSheet.draggableItemIsDragging : null;
							return (
								<div className={css(styleSheet.draggableItem, draggableItemIsDragging)}>
									<PropertyItemRow
										isDraggable={true}
										canRemove={!item.isRequired}
										onClickToRemove={handleRemoveItem(item)}
										renderLeft={
											<LeadPropertyCard
												checked={item.addToForm}
												onCheckboxChange={updateCheckboxOnSelected(item)}
												tagLabel={isNotDone ? labelPlaceholder : item.label}
											/>
										}
									/>
								</div>
							);
						}}
					</DraggableList>
				)}
			</ContentBox>
		</>
	);
};
