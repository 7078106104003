import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import FollowUps from '../../../assets/followUps.svg';
import Leads from '../../../assets/leads.svg';
import MeetingConfig from '../../../assets/meetingConfig.svg';
import ProfileImages from '../../../assets/profileImages.svg';
import TwilioConfig from '../../../assets/twilioConfig.svg';
import { useToaster } from '../../../hooks';
import { BasicPage } from '../../BasicPage';
import { UtilityCard } from './components/UtilityCard';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

export const Utilities: React.FC<IProps> = observer(({ className = '' }) => {
	const [spinners, setSpinners] = useState({
		FollowUps: false,
		Leads: false,
		ProfileImages: false,
		TeamMeeting: false,
		TwilioNumbers: false,
	});

	const userSession = useUserSession();
	const toaster = useToaster();
	const inputRef = useRef<HTMLInputElement>(null);

	const [fileUploadType, setFileUploadType] = useState<'TeamMeeting' | 'TwilioNumbers'>(null);

	const loadPics = async () => {
		setSpinners({ ...spinners, ProfileImages: true });
		const result = await userSession.webServiceHelper.callWebServiceAsync('aida/loadProfilePicsFromGoogle', 'PUT');
		setSpinners({ ...spinners, ProfileImages: false });

		toaster.push({
			message: result.success ? 'Loaded pictures for all connected users' : result.systemMessage,
			type: result.success ? 'successMessage' : 'errorMessage',
		});
	};

	const downloadFile = async () => {
		setSpinners({ ...spinners, FollowUps: true });
		await userSession.webServiceHelper.callWebServiceRawResponse(
			'lead/followUps/reportNow',
			'GET',
			null,
			response => {
				response.blob().then(blob => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = `COFFEE-FUs.csv`;
					document.body.appendChild(a);
					a.click();
					a.remove();
					setSpinners({ ...spinners, FollowUps: false });
				});
			},
			error => {
				setSpinners({ ...spinners, FollowUps: false });

				toaster.push({ message: error.systemMessage, type: 'errorMessage' });
			}
		);
	};

	const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setSpinners({ ...spinners, [fileUploadType]: true });

		const file = e.currentTarget.files[0];
		if (!file) {
			return; // cancelled the file picker
		}

		inputRef.current.value = '';
		const formData = new FormData();
		formData.append('files', file);
		// make request

		const uploadRoute = fileUploadType === 'TeamMeeting' ? 'aida/teamMeetingConfigs' : 'aida/telephonyConfigs';
		const successMessage =
			fileUploadType === 'TeamMeeting' ? 'Uploaded meeting configuration' : 'Uploaded twilio configuration';
		const result = await userSession.webServiceHelper.callWebServiceAsync(uploadRoute, 'PUT', formData);
		setSpinners({ ...spinners, [fileUploadType]: false });

		toaster.push({
			message: result.success ? successMessage : result.systemMessage,
			type: result.success ? 'successMessage' : 'errorMessage',
		});
	};

	const onUploadMeetingConfig = () => {
		setFileUploadType('TeamMeeting');
		inputRef.current?.click();
	};

	const onUploadTwilioConfig = () => {
		setFileUploadType('TwilioNumbers');
		inputRef.current?.click();
	};

	const exportLeads = async () => {
		setSpinners({ ...spinners, Leads: true });
		const result = await userSession.webServiceHelper.callWebServiceAsync('lead/export', 'POST');
		setSpinners({ ...spinners, Leads: false });
		if (result.success) {
			toaster.push({
				message: 'You will receive an email when your export is ready to be downloaded.',
				type: 'successMessage',
			});
		} else {
			toaster.push({ message: result.systemMessage, type: 'errorMessage' });
		}
	};

	const allowExport = userSession.account.features.aida?.allowExport;

	return (
		<BasicPage className={`${css(styleSheet.utilities)} ${className}`} title='Utilities'>
			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.grid)}>
					<UtilityCard
						buttonLabel='Load All Profile Pictures from Google'
						imageSrc={ProfileImages}
						isLoading={spinners.ProfileImages}
						onClick={loadPics}
					/>

					<UtilityCard
						buttonLabel='Download Follow Ups'
						imageSrc={FollowUps}
						isLoading={spinners.FollowUps}
						onClick={downloadFile}
					/>

					<UtilityCard
						buttonLabel='Upload Meeting Configuration'
						imageSrc={MeetingConfig}
						isLoading={spinners.TeamMeeting}
						onClick={onUploadMeetingConfig}
					/>

					<UtilityCard
						buttonLabel='Upload Twilio Configuration'
						imageSrc={TwilioConfig}
						isLoading={spinners.TwilioNumbers}
						onClick={onUploadTwilioConfig}
					/>

					{allowExport && (
						<UtilityCard buttonLabel='Export Leads' imageSrc={Leads} isLoading={spinners.Leads} onClick={exportLeads} />
					)}
					<input
						type='file'
						className={css(styleSheet.fileInput)}
						onChange={onFileChange}
						ref={inputRef}
						accept='.xlsx,.csv'
					/>
				</div>
			</div>
		</BasicPage>
	);
});
