import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { parse } from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router';
import { ISelectOption, Select } from '../../../../web/components/Select';
import { TransparentButton } from '../../../components/TransparentButton';
import { TwilioContextProvider } from '../../../components/ai/context';
import {
	invalidateGetScorecardTemplates,
	useCreateScorecardFromTemplateMutation,
	useGetScorecardTemplatesQuery,
	useUpdatePhoneCallActiveScorecardMutation,
} from '../../../queries';
import { BasicPage } from '../../BasicPage';
import { PhoneCallTester } from './PhoneCallTester';
import { styleSheet } from './styles';

export const SalesAiTester = () => {
	const location = useLocation();
	const queryParams = parse(location.search);
	const [phoneCallId, setPhoneCallId] = React.useState<string>(null);
	const [selectedTemplate, setSelectedTemplate] = React.useState<Api.IScorecardTemplate>(null);
	const createScorecardFromTemplateMutation = useCreateScorecardFromTemplateMutation({});
	const scorecardTemplatesQuery = useGetScorecardTemplatesQuery({});
	const updatePhoneCallActiveScorecard = useUpdatePhoneCallActiveScorecardMutation({});

	// @ts-ignore
	const scorecardTemplates: Api.IScorecardTemplate[] = scorecardTemplatesQuery.data?.values ?? [];
	const scorecardTemplateOptions = scorecardTemplates.map(template => {
		return {
			dataContext: template,
			id: template.id,
			text: `${template.persona}|${template.leadStage}|${template.vertical}`,
		} as ISelectOption;
	});

	React.useEffect(() => {
		if (queryParams.phoneCallId) {
			setPhoneCallId(queryParams.phoneCallId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Need to be able to create scoreboard for a previous phone call that did not get scored
	const createScorecardFromTemplate = async () => {
		const result = await createScorecardFromTemplateMutation.mutateAsync({
			templateId: selectedTemplate.id,
			phoneCallId,
		});
		await updatePhoneCallActiveScorecard.mutateAsync({ phoneCallId: result.phoneCallId, activeScorecardId: result.id });
		setPhoneCallId(result.phoneCallId);
		invalidateGetScorecardTemplates();
	};

	const onTemplateClicked = (option: ISelectOption) => {
		setSelectedTemplate(option.dataContext);
	};

	if (!phoneCallId) {
		return null;
	}

	return (
		<BasicPage className={css(styleSheet.container)} title='Sales AI Settings'>
			<TwilioContextProvider>
				<PhoneCallTester phoneCallId={phoneCallId} />

				<Select
					options={scorecardTemplateOptions}
					onOptionClick={onTemplateClicked}
					selectedOption={scorecardTemplateOptions.find(x => x.id === selectedTemplate?.id)}
				/>

				<TransparentButton onClick={() => createScorecardFromTemplate()}>
					Create Scorecard from latest version
				</TransparentButton>
			</TwilioContextProvider>
		</BasicPage>
	);
};
