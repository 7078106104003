import * as Api from '@ViewModels';
import escapeRegExp from 'lodash.escaperegexp';
import { action, computed, observable, runInAction } from 'mobx';
import { VmUtils } from '../../../extViewmodels';

export type AutoCompleteResultFilter<T> = (result: T, query: string) => boolean;

export interface IAutoCompleteOptions<T> {
	apiParams?: Api.IDictionary;
	apiPath?: string;
	inputDelay?: number;
	localFilter?: AutoCompleteResultFilter<T>;
	pageSize?: number;
	searchParamName?: string;
	leadId: string;
	fieldId: string;
}

export type ResourceAutoCompleteModel = Api.IUser | Api.IContact;

export enum ResourceAutoCompleteViewModelType {
	Contact = 'contact',
	User = 'user',
}

export interface IResourceAutoCompleteOptions<T extends ResourceAutoCompleteModel = ResourceAutoCompleteModel>
	extends IAutoCompleteOptions<T> {
	/**
	 * This filter is always applied to the entire collection of search results, not just after local filtering is
	 * triggered.
	 */
	filter?: AutoCompleteResultFilter<T>;
	type: ResourceAutoCompleteViewModelType;
}

/** Encapsulates logic needed to do autocomplete searches. */
export class AutoCompleteViewModel<T = any> extends Api.ImpersonationBroker {
	@observable protected mSearchQuery: string;
	@observable.ref private mTimeoutHandle: any;

	@observable.ref protected mSearchPromise: Promise<Api.IPagedCollection<T>>;

	@observable.ref protected mSearchResults: T[];

	@observable protected mTotalCount: number;

	@observable protected mHasMorePages: boolean;
	protected mOptions: IAutoCompleteOptions<T>;
	protected mUserSession: Api.UserSessionContext;
	protected mDefaultApiParams: Api.IDictionary<any>;

	/**
	 * @param dataSource Provides the specific models
	 * @param filter Allows for a final filter before updating
	 */
	constructor(userSession: Api.UserSessionContext, options: IAutoCompleteOptions<T>) {
		super();
		this.mGetResultsWithQuery = this.mGetResultsWithQuery.bind(this);
		this.mGetOpResult = this.mGetOpResult.bind(this);
		this.mReset = this.mReset.bind(this);
		this.mOptions = options;
		this.mUserSession = userSession;
		this.mDefaultApiParams = {
			enforcePageSize: true,
			...(options.apiParams || {}),

			pageSize: options.pageSize > 0 ? options.pageSize : 5,
		};
		this.mReset();
	}

	@computed
	public get totalCount() {
		return this.mTotalCount || 0;
	}

	@computed
	public get searchResults() {
		const results = this.mSearchResults || [];
		if (this.canFilterLocally) {
			return results.filter(x => {
				return this.mOptions.localFilter(x, this.mSearchQuery);
			});
		}
		return results;
	}

	@computed
	public get isFetchingResults() {
		return !!this.mSearchPromise;
	}

	@computed
	public get isBusy() {
		return !!this.isFetchingResults || !!this.mTimeoutHandle;
	}

	protected get canFilterLocally() {
		return !this.mHasMorePages && !!this.mOptions.localFilter && !!this.mSearchQuery;
	}

	@action
	public reset = () => {
		this.mReset();
	};

	@action
	public addParam = (param: Api.IDictionary) => {
		this.mDefaultApiParams = {
			...this.mDefaultApiParams,
			...param,
		};
	};

	@action
	public setSearchQuery = (query: string, delay = this.mOptions.inputDelay >= 0 ? this.mOptions.inputDelay : 0) => {
		const trimmedQuery = (query || '').trim();
		if (trimmedQuery) {
			// check to see if we can filter locally
			if (!this.mTimeoutHandle && !this.mSearchPromise && !!this.canFilterLocally) {
				// Is this a continuation?
				const beginsWithRegExp = new RegExp(`^${escapeRegExp(this.mSearchQuery)}`, 'i');
				if (beginsWithRegExp.test(trimmedQuery)) {
					// just set mSearchQuery and let the computed getter filter locally
					this.mSearchQuery = trimmedQuery;
					return;
				}
			}

			this.mClearTimeoutHandle();
			this.mCancelCurrentSearch();

			const performSearch = () => {
				this.mGetResultsWithQuery(trimmedQuery);
			};

			if (delay > 0) {
				this.mTimeoutHandle = setTimeout(() => {
					runInAction(() => {
						this.mClearTimeoutHandle();
						performSearch();
					});
				}, delay);
			} else {
				performSearch();
			}
		} else {
			this.mReset();
		}
	};

	protected mCancelCurrentSearch = () => {
		if (this.mSearchPromise) {
			this.mSearchPromise = null;
		}
	};

	protected mClearTimeoutHandle = () => {
		if (this.mTimeoutHandle) {
			clearTimeout(this.mTimeoutHandle);
			this.mTimeoutHandle = null;
		}
	};

	protected mGetResultsWithQuery(query: string) {
		const promise = new Promise<Api.IPagedCollection<T>>((resolve, reject) => {
			const computedParams = {
				...this.mDefaultApiParams,

				[this.mOptions.searchParamName]: query || '',
			};
			VmUtils.removeEmptyKvpFromObject(computedParams);
			this.mGetOpResult(computedParams)
				.then(value => {
					runInAction(() => {
						this.mSearchPromise = null;
						this.mClearTimeoutHandle();
						this.mSearchQuery = query;

						this.mHasMorePages = !!value.pageToken;

						this.mTotalCount = value.totalCount;

						this.mSearchResults = value.values;

						resolve(value);
					});
				})
				.catch(err => {
					runInAction(() => {
						this.mReset();
						reject(err);
					});
				});
		});

		this.mSearchPromise = promise;
		return promise;
	}

	protected mGetOpResult(computedParams?: Api.IDictionary<any>) {
		return this.mUserSession.webServiceHelper.callAsync<Api.IPagedCollection<T>>(
			this.composeApiUrl({ queryParams: computedParams, urlPath: this.mOptions.apiPath }),
			'GET'
		);
	}

	protected mReset() {
		this.mCancelCurrentSearch();
		this.mClearTimeoutHandle();

		this.mSearchQuery = null;
		this.mTotalCount = 0;
	}
}

export class ResourceAutoCompleteViewModel<
	T extends ResourceAutoCompleteModel = ResourceAutoCompleteModel,
> extends AutoCompleteViewModel<T> {
	private mExtendedOptions: IResourceAutoCompleteOptions<T>;
	constructor(
		userSession: Api.UserSessionContext,
		options: IResourceAutoCompleteOptions,
		type: 'CreateDeal' | 'UpdateDeal'
	) {
		const apiPath = `lead/${options.leadId}/${type}/field/${options.fieldId}/${options.type}`;

		const extendedOptions: IResourceAutoCompleteOptions = { ...options };

		const autocompleteOptions: IResourceAutoCompleteOptions<T> = {
			...options,
			apiPath,

			inputDelay: extendedOptions.inputDelay >= 0 ? extendedOptions.inputDelay : 150,
			localFilter: extendedOptions.localFilter,
			searchParamName: 'search',
		};
		super(userSession, autocompleteOptions);
		this.mExtendedOptions = extendedOptions;
		this.mGetOpResult = this.mGetOpResult.bind(this);
		this.mReset = this.mReset.bind(this);
	}

	@computed
	public get searchResults() {
		const results = this.mSearchResults || [];
		if (this.mExtendedOptions.filter || !!this.canFilterLocally) {
			return results.filter(x => {
				let include = this.mExtendedOptions.filter ? this.mExtendedOptions.filter(x, this.mSearchQuery) : true;
				if (!!include && !!this.canFilterLocally) {
					include = this.mExtendedOptions.localFilter(x, this.mSearchQuery);
				}
				return include;
			});
		}
		return results;
	}

	protected mGetOpResult(computedParams?: Api.IDictionary<any>) {
		return super.mGetOpResult(computedParams);
	}

	protected mReset() {
		super.mReset();
	}
}
