import { css } from 'aphrodite';
import * as React from 'react';
import FlipMove from 'react-flip-move';
import { useHistory } from 'react-router';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { ISelectOption } from '../../../../web/components/Select';
import { Toggle } from '../../../../web/components/Toggle';
import { LeadRule } from '../../../components/LeadRule';
import { Fill } from '../../../components/generics/Fill';
import { Group } from '../../../components/generics/Group';
import { useBlitzes } from '../../../queries/Blitzes';
import { grayIconFill } from '../../../styles/colors';
import { FieldKey } from '../../../viewModels/form';
import { LeadRuleViewModel } from '../../../viewModels/leadrules';
import { BasicPage } from '../../BasicPage';
import { VerticalSelect } from '../Blitzes/components/VerticalSelect';
import { styleSheet } from './styles';

export const StartABlitz: React.FC = () => {
	const [selectedVertical, setVertical] = React.useState<FieldKey>(FieldKey.Unknown);

	const [showInactive, setShowInactive] = React.useState(false);

	const blitzesQuery = useBlitzes({
		onlyForUser: true,
	});

	const history = useHistory();

	const handleSelect = (selected: ISelectOption<FieldKey>) => {
		setVertical(selected.dataContext);
	};

	const rules = blitzesQuery?.data?.values || [];

	const listOfUniqueVerticals = rules
		?.filter(item => item?.verticals?.length > 0)
		.map(item => [...(item?.verticals ?? [])])
		.reduce((acc, item) => acc.concat(item), []);

	const verticalOptions: ISelectOption[] = listOfUniqueVerticals
		?.filter((item, index) => listOfUniqueVerticals.indexOf?.(item) === index)
		.map(item => ({
			dataContext: item,
			id: item,
			text: item,
		}));

	verticalOptions.unshift({
		dataContext: FieldKey.Unknown,
		id: 'all',
		text: 'All Lead Pools',
	});

	const filterRulesByVertical = (rule: LeadRuleViewModel) => {
		if (selectedVertical === FieldKey.Unknown) {
			return rule;
		}
		if (rule.verticals.indexOf(selectedVertical) !== -1) {
			return rule;
		}
	};

	const handleToggle = () => {
		setShowInactive(!showInactive);
	};

	const handleClickToPlayBlitz = (ruleId: string) => () => {
		history.push(`/queue?ruleId=${ruleId}`);
	};

	return (
		<BasicPage className={css(styleSheet.blitzAndFiltersContainer)} title='start a blitz'>
			<div className={css(styleSheet.leadRulesContainer)}>
				<div className={css(styleSheet.listHeader)}>
					<Group>
						<VerticalSelect options={verticalOptions} disabled={verticalOptions?.length <= 1} onChange={handleSelect} />
						<Fill />
						<Toggle
							id='show-inactive-lead-rules'
							text='Show Inactive'
							isOn={showInactive}
							onToggleCheckChanged={handleToggle}
							className={css(styleSheet.toggle)}
							uncheckedColor={grayIconFill}
						/>
					</Group>
				</div>
				{blitzesQuery.isLoading ? (
					<LoadingSpinner type='large' />
				) : (
					<FlipMove
						enterAnimation={false}
						leaveAnimation={false}
						staggerDelayBy={50}
						staggerDurationBy={10}
						appearAnimation={true}
					>
						{rules?.filter(filterRulesByVertical)?.map(rule => {
							if (!showInactive && !rule.isEnabled) {
								return null;
							}
							return (
								<div key={rule.id}>
									<LeadRule
										isPlayable
										isReadonly={rule.type !== 'CustomLeadRule'}
										leadRule={rule}
										leadRules={rules}
										onClickToPlayBlitz={handleClickToPlayBlitz(rule.id)}
									/>
								</div>
							);
						})}
					</FlipMove>
				)}
			</div>
		</BasicPage>
	);
};
