import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, useLocation } from 'react-router';
import { Noop } from '../../../extViewmodels/Utils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useModal } from '../../../models/hooks/useModal';
import { LoginViewModel } from '../../../viewmodels/AppViewModels';
import { DeprecatedPopover, PopoverType } from '../../../web/components/DeprecatedPopover';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { Login as LevitateLogin } from '../../../web/containers/Login';
import { ForgotPasswordModal } from '../../../web/containers/Login/ForgotPasswordModal';
import { ILoginContext, LoginContext } from '../../../web/containers/Login/context';
import { CoffeeLogo } from '../../components/svgs/CoffeeLogo';
import { LightningGraphic1, LightningGraphic2, LightningType } from '../../components/svgs/LightningGraphic';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const Login: React.FC<IProps> = observer(props => {
	const { className, styles = [] } = props;
	const userSession = useUserSession();
	const currentLocation = useLocation<{ from?: Location; search?: string }>();

	const [shouldRedirect, setShouldRedirect] = React.useState<boolean>(userSession.isAuthenticated);

	const loginContext = React.useRef<ILoginContext>({
		loginViewModel: new LoginViewModel(userSession, () => setShouldRedirect(true)),
	}).current;
	const { loginViewModel } = loginContext;

	const onSubmitSignInForm = React.useCallback(() => {
		loginViewModel.submit();
	}, [loginViewModel]);

	const onLastFieldKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter') {
				onSubmitSignInForm();
			}
		},
		[onSubmitSignInForm]
	);

	const forgotPasswordModal = useModal(false, Noop, [], true, true);

	const showing2FA = loginViewModel.needsPhone || loginViewModel.challenging;

	if (shouldRedirect) {
		const fromLocation = currentLocation.state?.from;
		let pathname = fromLocation?.pathname || '/queue';
		let search: string;
		if (fromLocation) {
			pathname = fromLocation.pathname;
			if (fromLocation.search) {
				search = fromLocation.search;
			}
		}
		return (
			<LoginContext.Provider value={loginContext}>
				<Redirect
					to={{
						pathname,

						search: search || currentLocation.state?.search,
						state: { from: currentLocation },
					}}
				/>
			</LoginContext.Provider>
		);
	}

	const renderLoginForm = () => {
		return (
			<div className={css(styleSheet.login)}>
				<div className={css(styleSheet.loginForm)}>
					<LevitateLogin.FormFieldErrorMessagePopover fieldName='email'>
						<LevitateLogin.EmailField
							inputFieldClassName='coffee-login-input-field'
							labelStyles={[styleSheet.formFieldLabel]}
						/>
					</LevitateLogin.FormFieldErrorMessagePopover>

					<LevitateLogin.FormFieldErrorMessagePopover fieldName='password'>
						<LevitateLogin.PasswordField
							inputFieldClassName='coffee-login-input-field'
							labelStyles={[styleSheet.formFieldLabel]}
							onKeyDown={!showing2FA ? onLastFieldKeyDown : undefined}
						/>
					</LevitateLogin.FormFieldErrorMessagePopover>

					<LevitateLogin.TwoFactorFields
						inputFieldClassName='coffee-login-input-field'
						labelStyles={[styleSheet.formFieldLabel]}
						onKeyDown={onLastFieldKeyDown}
					/>
					<DeprecatedPopover
						anchor={
							<button className={css(styleSheet.submitButton)} onClick={onSubmitSignInForm}>
								{loginViewModel.needsPhone ? 'Next' : loginViewModel.challenging ? 'Verify' : 'Sign In'}
							</button>
						}
						isOpen={loginViewModel?.hasErrorMessage('global')}
						type={PopoverType.error}
						contentClassName='login-error-popover'
						preferredPlacement='right'
					>
						<span>{loginViewModel?.getErrorMessage('global')}</span>
					</DeprecatedPopover>
					<div className={css(styleSheet.footer)}>
						{!loginViewModel.challenging && !loginViewModel.needsPhone && (
							<span onClick={forgotPasswordModal.setIsOpen(true)} className={css(styleSheet.forgotPasswordLink)}>
								Forgot Password
							</span>
						)}
					</div>
				</div>
				<ForgotPasswordModal
					modalProps={{
						isOpen: forgotPasswordModal.isOpen,
						onRequestClose: forgotPasswordModal.onRequestClose,
					}}
					userSession={userSession}
				/>
			</div>
		);
	};

	return (
		<LoginContext.Provider value={loginContext}>
			<div className={`${css(styleSheet.container, ...styles)} coffee-login-container ${className || ''}`}>
				<LightningGraphic1 className={css(styleSheet.lightningGraphic1)} type={LightningType.LightningDouble} />
				<LightningGraphic2 className={css(styleSheet.lightningGraphic2)} type={LightningType.LightningDouble} />
				<div className={css(styleSheet.centeredContent)}>
					<div>
						{!loginViewModel.isBusy && <CoffeeLogo className={css(styleSheet.logo)} />}

						{loginViewModel.isBusy ? <LoadingSpinner type='login' /> : renderLoginForm()}
					</div>
				</div>
			</div>
		</LoginContext.Provider>
	);
});
