import * as React from 'react';
import { DemoStatusFilter } from '../../../../../extViewmodels';
import { ISelectOption, Select } from '../../../../../web/components/Select';
import { styleSheet } from './styles';

interface IProps {
	disabled?: boolean;
	onChange?: (item: ISelectOption<DemoStatusFilter>) => void;
	selected?: ISelectOption<string>;
}

export const demoStatusSelectOptions: ISelectOption<DemoStatusFilter>[] = [
	{
		dataContext: DemoStatusFilter.DemosToConfirm,
		id: DemoStatusFilter.DemosToConfirm,
		text: 'Demos to Confirm',
	},
	{
		dataContext: DemoStatusFilter.DemosToReschedule,
		id: DemoStatusFilter.DemosToReschedule,
		text: 'Demos to Reschedule',
	},
	{
		dataContext: DemoStatusFilter.UpcomingDemos,
		id: DemoStatusFilter.UpcomingDemos,
		text: 'Upcoming Demos',
	},
];

export const DemoStatusSelect: React.FC<IProps> = ({ disabled, onChange, selected }) => {
	const onClick = (option: ISelectOption<DemoStatusFilter>) => {
		onChange(option);
	};

	const selectedOption =
		demoStatusSelectOptions.find(x => x.dataContext === selected?.dataContext ?? null) || demoStatusSelectOptions[0];

	return (
		<Select
			dropdownStyles={[styleSheet.selectDropdown]}
			onOptionClick={onClick}
			options={demoStatusSelectOptions}
			disabled={disabled}
			selectedOption={selectedOption}
			styles={[styleSheet.select]}
		/>
	);
};
