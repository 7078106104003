import { useState } from 'react';

export enum FetchStatus {
	Done = 'done',
	Waiting = 'waiting',
	Working = 'working',
}

export interface IFetchState<T> {
	data?: null | T;
	empty?: boolean;
	error?: null | any;
	status: FetchStatus;
}

const initialState: IFetchState<null> = {
	data: null,
	empty: true,
	error: null,
	status: FetchStatus.Waiting,
};

export const useStatesOnFetch = <T>() => {
	const [state, setState] = useState<IFetchState<T>>(initialState);

	const handleWorking = (shouldNotTriggerLoading?: boolean) => {
		if (!shouldNotTriggerLoading) {
			setState({ data: null, error: null, status: FetchStatus.Working });
		}
	};

	const handleResponse = (response: any, transformData?: (data: T) => typeof data) => {
		if (!response) {
			setState({
				data: null,
				error: 'Response not found!',
				status: FetchStatus.Done,
			});
			return 'Response not found!';
		}
		if ((response?.status_code || response?.systemCode) === 400) {
			setState({ error: response?.message, status: FetchStatus.Done });
			throw response;
		}

		const isValue = typeof response?.value === 'object';
		const isValues = typeof response?.values === 'object';
		const isValuesInValue = typeof response?.value?.values === 'object';

		let data = response?.value;

		if (isValue && !isValuesInValue) {
			data = response?.value;
		}

		if (isValue && isValuesInValue) {
			data = response?.value?.values;
		}

		if (isValues) {
			data = response?.values;
		}

		setState({ data: transformData?.(data) || data, status: FetchStatus.Done });
		return data;
	};

	const reset = () => {
		setState(initialState);
	};

	return {
		handleResponse,
		handleWorking,
		reset,
		state,
	};
};
