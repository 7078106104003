import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { IRange } from '../../../../../extViewmodels';
import { Button } from '../../../../../web/components/Button';
import { DateRangePickerModal } from '../../../../../web/components/DateRangePicker';
import { DayPicker } from '../../../../../web/components/DayPicker';
import { asModalComponent } from '../../../../../web/components/Modal';
import { ClearButton } from '../ClearButton';
import { styleSheet } from './styles';

const formatDate = (range?: IRange<Date>) => {
	const f = 'MM/DD/YY';
	if (!range) {
		return null;
	}
	return `${moment(range?.start).format(f)} to ${moment(range?.end).format(f)}`;
};

interface IProps {
	dateRangeValue?: IRange<Date>;
	disableRange?: boolean;
	initialDate?: Date;
	initialRange?: IRange<Date>;
	onChange: (range?: IRange<Date> | Date, cancelled?: boolean) => void;
}

const DatePickerModal = asModalComponent(DayPicker);

export const DatePicker: React.FC<IProps> = ({ dateRangeValue, disableRange, initialDate, initialRange, onChange }) => {
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [selectedDates, setSelectedDates] = useState(formatDate(initialRange));
	const [selectedDate, setSelectedDate] = useState(initialDate);

	useEffect(() => {
		setSelectedDates(formatDate(dateRangeValue || initialRange));
	}, [dateRangeValue, initialRange]);

	const handleDatePickerChange = (range?: IRange<Date>, cancelled?: boolean) => {
		onChange(range, cancelled);
		setSelectedDates(formatDate(range));
		setShowDatePicker(false);
	};

	const handleDatePickerClear = () => {
		onChange(null, true);
		if (disableRange) {
			setSelectedDate(null);
		} else {
			setSelectedDates(null);
		}
		setShowDatePicker(false);
	};

	const handleDateChange = (date: Date) => {
		onChange(date, false);
		setSelectedDate(date);
		setShowDatePicker(false);
	};

	const openDatePicker = () => setShowDatePicker(true);

	const placeholder = 'Filter by Date';

	const datePickerLabel = selectedDate ? 'Date: ' + moment(selectedDate).format('MM/DD/YYYY') : placeholder;

	const dateRangePickerLabel = selectedDates ? 'Date: ' + selectedDates : placeholder;

	const label = disableRange ? datePickerLabel : dateRangePickerLabel;

	return (
		<div className={css(styleSheet.wrapper)}>
			<Button
				styles={[styleSheet.customButton]}
				className='datepicker-select'
				label={label}
				onClick={openDatePicker}
				kind='secondary'
			/>
			{(selectedDates || selectedDate) && <ClearButton onClick={handleDatePickerClear} />}

			{disableRange ? (
				<DatePickerModal
					canChangeToNextMonth={true}
					canChangeToPreviousMonth={true}
					onDayClick={handleDateChange}
					selectedDays={selectedDate}
					showOverflowDates={false}
					modalProps={{
						isOpen: showDatePicker,

						onRequestClose: null,
					}}
				/>
			) : (
				<DateRangePickerModal
					showClearButton={true}
					allowPastDates={true}
					modalProps={{
						isOpen: showDatePicker,
						onRequestClose: handleDatePickerChange,
					}}
				/>
			)}
		</div>
	);
};
