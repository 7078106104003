import * as Api from '@ViewModels';
import { animated, to, useSpring } from '@react-spring/web';
import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal';
import * as React from 'react';
import Measure, { ContentRect } from 'react-measure';
import { SlotMachineSymbol } from '../SlotMachineSymbol';
import { styleSheet } from './styles';

interface ILegendPageProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

const LegendPage: React.FC<ILegendPageProps> = props => {
	const { className, styles = [], children } = props;
	return (
		<animated.div className={`${css(styleSheet.legendPage, ...styles)} slot-machine-legend-pager ${className || ''}`}>
			{children}
		</animated.div>
	);
};

interface ILegendPagerProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const LegendPager: React.FC<ILegendPagerProps> = props => {
	const { className, styles = [], children } = props;

	const [contentRect, setContentRect] = React.useState<ContentRect>(null);
	const [pageIndex, setPageIndex] = React.useState<number>(0);
	const setPageIndexRef = React.useRef<(index: number) => void>(setPageIndex);
	const onResize = React.useCallback(
		(rect: ContentRect) => {
			if (!equal(contentRect, rect)) {
				setContentRect(rect);
			}
		},
		[contentRect]
	);

	const [pagesProps, pagesAnimationController] = useSpring(() => {
		return {
			blur: 0,
			scale: 1,
			transform: 'translate(0, 0)',
		};
	});

	const switcherTimeOutHandleRef = React.useRef<any>(null);
	const clearAnimationTimeOut = React.useCallback(() => {
		if (switcherTimeOutHandleRef.current !== null) {
			clearTimeout(switcherTimeOutHandleRef.current);
			switcherTimeOutHandleRef.current = null;
		}
	}, []);

	const advanceToNextPage = React.useCallback(
		(targetIndex?: number) => {
			const next = !isNaN(targetIndex) ? targetIndex : pageIndex + 1;
			const count = React.Children.count(children);

			if (next <= count) {
				// reset blur to 0 w/o animating
				pagesAnimationController.set({ blur: 0 });
				pagesAnimationController.start((_, controller) => ({
					// if translating >> distance, add blur

					blur: Math.abs(next - pageIndex) > 2 ? 1 : 0,
					onResolve: () => {
						// use ref to setter instead of setter in case comp is unmounted during animation

						setPageIndexRef.current?.(next);
					},
					scale: controller.get().scale > 0 ? 0 : 1,

					transform: `translate(-${(next - 1) * contentRect.client.width}px, 0)`,
				}));
			} else {
				// animated back to first page
				pagesAnimationController.start((_, controller) => ({
					blur: 1,
					onResolve: () => {
						// use ref to setter instead of setter in case comp is unmounted during animation
						setPageIndexRef.current?.(0);
					},
					scale: controller.get().scale > 0 ? 0 : 1,
					transform: 'translate(0, 0)',
				}));
			}
		},
		[pagesAnimationController, children, contentRect, pageIndex]
	);

	React.useEffect(() => {
		setPageIndexRef.current?.(0);
		pagesAnimationController.set({
			blur: 0,
			scale: 1,
			transform: 'translate(0, 0)',
		});
		clearAnimationTimeOut();
	}, [children, pagesAnimationController, clearAnimationTimeOut]);

	React.useEffect(() => {
		// we use a ref instead of `setPageIndex` such that if the animation callbacks fire after unmount, we don't get
		// warning/error
		setPageIndexRef.current = setPageIndex;
		/**
		 * Note: at the end of committing an animation update via advanceToNextPage, it changes the value of pageIndex,
		 * which causes advanceToNextPage to reevaluate and causes this effect to run again. This created an inf loop
		 * timeouts until the component is unmounted. This is intentional (allows for the animation to complete before
		 * calling setTimeout again).
		 */
		if (React.Children.count(children) > 1) {
			switcherTimeOutHandleRef.current = setTimeout(advanceToNextPage, 3000);
		}
		return () => {
			setPageIndexRef.current = null;
			clearAnimationTimeOut();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advanceToNextPage, children]);

	const goToIndex = React.useCallback(
		(index: number) => () => {
			clearAnimationTimeOut();
			advanceToNextPage(index);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[advanceToNextPage]
	);

	const renderPageIndicators = () => {
		const count = React.Children.count(children);
		if (count < 2) {
			return null;
		}
		const indicators: JSX.Element[] = [];
		for (let i = 0; i < count; i++) {
			const button = (
				<button className={css(styleSheet.legendPagerPageIndicatorButton)} key={i} onClick={goToIndex(i + 1)}>
					<span>{'\u2B24'}</span>
				</button>
			);
			indicators.push(button);
		}
		return <div className={css(styleSheet.legendPagerPageIndicator)}>{indicators}</div>;
	};
	return (
		<Measure client={true} onResize={onResize}>
			{({ measureRef }) => (
				<div
					className={`${css(styleSheet.legendPager, ...styles)} slot-machine-legend-pager ${className || ''}`}
					ref={measureRef}
				>
					<animated.div
						className={css(styleSheet.legendPagerPages)}
						style={{
							filter: pagesProps.blur.to({ output: [0, 1, 0], range: [0, 0.5, 1] }).to(b => `blur(${b}px)`),
							transform: to(
								[pagesProps.transform, pagesProps.scale.to({ output: [1, 0.9, 1], range: [0, 0.5, 1] })],
								(t, s) => `scale(${s}, ${s}) ${t}`
							),
						}}
					>
						{React.Children.map(children, (child, index) => {
							return <LegendPage key={`child-${index}`}>{child}</LegendPage>;
						})}
					</animated.div>
					{renderPageIndicators()}
				</div>
			)}
		</Measure>
	);
};

interface IPrizeRowProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	symbols: Api.SlotMachineSymbol[];
}

export const PrizeRow: React.FC<IPrizeRowProps> = props => {
	const { className, styles = [], symbols, children } = props;
	const symbolStyle = React.useRef<React.CSSProperties>({
		height: 26,
		width: 'auto',
	}).current;
	return (
		<div className={`${css(styleSheet.prizeRow, ...styles)} ${className || ''}`}>
			<div className={css(styleSheet.prizeRowSymbols)}>
				{symbols.map((x, i) => {
					return <SlotMachineSymbol key={`${x}-${i}`} style={symbolStyle} type={x} />;
				})}
			</div>
			<div className={css(styleSheet.prizeRowText)}>{children}</div>
		</div>
	);
};
