import { QueueViewModel } from '../viewModels/queue';

export interface IQueueState {
	queue: QueueViewModel;
}

export const QueueViewModelKey = 'queue';

export const QueueState: IQueueState = {
	[QueueViewModelKey]: null,
};
